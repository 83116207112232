import React from 'react'
import ReactDOM from 'react-dom'
import 'react-smartbanner/dist/main.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

import { DEV } from './utils/constants'

DEV && console.info('Started in Development Mode')

const tagManagerArgs = {
  gtmId: 'GTM-TG8M7WS',
}
!DEV && TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

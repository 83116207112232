import React from 'react'

function ArrowIcon({ width = 40, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.657 27.071l-6.364-6.364a1 1 0 010-1.414l6.364-6.364a1 1 0 011.414 1.414L8.414 19H34a1 1 0 110 2H8.414l4.657 4.657a1 1 0 01-1.414 1.414z"
      />
    </svg>
  )
}

export default ArrowIcon

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

const CaptchaWrapper = styled.div`
  margin: 30px 0;
`;

// FIXME
const siteKey = process.env.REACT_APP_CAPTCHA_KEY;

//  TODO Yandex CAPTCHA

// export const InvisibleCaptcha = ({ handleVerify }) => {
//   const [visible, setVisible] = useState(false);
//
//   const handleChallengeHidden = useCallback(() => setVisible(false), []);
//
//   const handleButtonClick = () => setVisible(true);
//
//   return (
//     <Captcha>
//       <button onClick={handleButtonClick}>Validate</button>
//       <InvisibleSmartCaptcha
//         sitekey={siteKey}
//         onSuccess={handleVerify}
//         onChallengeHidden={handleChallengeHidden}
//         visible={visible}
//       />
//     </Captcha>
//   );
// };



const Captcha = React.forwardRef((props, ref ) => {
  return (
    <CaptchaWrapper>
      <ReCAPTCHA
        sitekey={siteKey}
        onChange={props.handleVerify}
        ref={ref}
        size={'compact'}
      />
    </CaptchaWrapper>
  )
});

export default Captcha;
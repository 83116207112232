import React from 'react'
import styled, { css } from 'styled-components'

const boxStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  pointer-events: none;
`

const StyledBox = styled.span`
  ${boxStyle}
  display: block;
  background: var(--border-color);
  border-radius: 4px;
  transition: background 0.2s;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--main-background-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
`

const disabledStyle = css`
  opacity: 0.7;
  cursor: not-allowed;
`

const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding-left: calc(20px + 0.75rem);
  cursor: pointer;

  ${({ disabled }) => disabled && disabledStyle}

  input {
    ${boxStyle}
    opacity: 0;
    appearance: none;
  }

  input:checked ~ ${StyledBox} {
    background-color: var(--main-accent-color);

    &::after {
      opacity: 1;
    }
  }

  span {
    user-select: none;
  }
`

function Checkbox({ label, inputName, onChange, checked, disabled }) {
  return (
    <Label disabled={disabled}>
      <input
        type="checkbox"
        name={inputName}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <StyledBox />
      <div style={{ width: '100%' }}>
        <span>{label}</span>
      </div>
    </Label>
  )
}

export default Checkbox

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Portal from './Portal'
import ModalOverlay from './ModalOverlay'
import CrossIcon from './CrossIcon'

const Container = styled.div`
  margin-top: auto;
  background-color: var(--main-background-color);
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 20px
    ${({ isShown }) => (isShown ? 'var(--shadow-color)' : 'transparent')};
  transform: translateY(${({ isShown }) => (isShown ? '0' : '120%')});
  transition: transform 0.3s, box-shadow 0.2s;
`

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--border-color);
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background-color: var(--main-background-color);
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;

  svg {
    fill: currentColor;
  }
`

function InfoModal({ title, isShown, onCloseClick, children }) {
  const targetRef = useRef()
  let [targetElement, setTargetElement] = useState(null)

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isShown) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isShown, targetElement])

  return (
    <Portal>
      <ModalOverlay isShown={isShown} onClick={onCloseClick}>
        <Container isShown={isShown} ref={targetRef}>
          <Header>
            <strong>{title}</strong>
            <CloseButton
              type="button"
              aria-label="Закрыть окно"
              onClick={onCloseClick}
            >
              <CrossIcon />
            </CloseButton>
          </Header>
          <div>{children}</div>
        </Container>
      </ModalOverlay>
    </Portal>
  )
}

export default InfoModal

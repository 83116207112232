import React from 'react'

import StickyHeader, { Title } from '../shared/StickyHeader'
import GoBackButton from '../shared/GoBackButton'

function onHeaderClick() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

function Header() {
  return (
    <StickyHeader>
      <GoBackButton backPath="/" />
      <Title onClick={onHeaderClick}>
        <strong>Мой профиль</strong>
      </Title>
    </StickyHeader>
  )
}

export default Header

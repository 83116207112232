import React from 'react'

function DiscountIcon({ width = 32, height = 32, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.002 20.915l-2.4 1.273a2.772 2.772 0 00-1.44 1.982l-.47 2.677a.94.94 0 01-1.062.771l-2.69-.38a2.77 2.77 0 00-2.33.757l-1.954 1.89a.94.94 0 01-1.312 0l-1.953-1.89a2.769 2.769 0 00-2.33-.757l-2.691.38a.94.94 0 01-1.062-.77l-.47-2.678a2.771 2.771 0 00-1.44-1.982l-2.4-1.273a.94.94 0 01-.406-1.248l1.194-2.442c.38-.779.38-1.671 0-2.45l-1.194-2.441a.94.94 0 01.406-1.248l2.4-1.274a2.772 2.772 0 001.44-1.982l.47-2.677a.94.94 0 011.062-.771l2.69.38a2.772 2.772 0 002.33-.757l1.954-1.89a.94.94 0 011.312 0l1.953 1.89a2.771 2.771 0 002.33.757l2.691-.38a.94.94 0 011.062.77l.47 2.678c.149.853.674 1.576 1.44 1.982l2.4 1.274a.94.94 0 01.405 1.248l-1.193 2.441a2.772 2.772 0 000 2.45l1.194 2.441a.94.94 0 01-.406 1.249z"
        fill="#20be43"
      />
      <path
        d="M29.872 16.415a.938.938 0 010-.83l1.194-2.441a2.778 2.778 0 00-1.199-3.689l-2.4-1.274a.938.938 0 01-.488-.67l-.47-2.677a2.778 2.778 0 00-3.137-2.28l-2.691.38a.937.937 0 01-.788-.255L17.939.789a2.778 2.778 0 00-3.878 0l-1.954 1.89a.938.938 0 01-.788.256l-2.69-.38a2.778 2.778 0 00-3.139 2.28L5.02 7.51a.938.938 0 01-.486.67L2.133 9.455a2.778 2.778 0 00-1.199 3.69l1.194 2.44a.938.938 0 010 .83L.934 18.856a2.778 2.778 0 001.199 3.689l2.4 1.274a.937.937 0 01.488.67l.47 2.677a2.776 2.776 0 003.137 2.28l2.691-.38a.938.938 0 01.788.256l1.954 1.889a2.78 2.78 0 003.878 0l1.954-1.89a.938.938 0 01.788-.256l2.69.381a2.778 2.778 0 003.139-2.28l.47-2.677a.938.938 0 01.486-.67l2.401-1.274a2.778 2.778 0 001.199-3.689l-1.194-2.442zm-.87 4.5l-2.4 1.273a2.772 2.772 0 00-1.44 1.982l-.47 2.677a.94.94 0 01-1.062.771l-2.69-.38a2.77 2.77 0 00-2.33.757l-1.954 1.89a.94.94 0 01-1.312 0l-1.953-1.89a2.769 2.769 0 00-2.33-.757l-2.691.38a.94.94 0 01-1.062-.77l-.47-2.678a2.771 2.771 0 00-1.44-1.982l-2.4-1.273a.94.94 0 01-.406-1.248l1.194-2.442c.38-.779.38-1.671 0-2.45l-1.194-2.441a.94.94 0 01.406-1.248l2.4-1.274a2.772 2.772 0 001.44-1.982l.47-2.677a.94.94 0 011.062-.771l2.69.38a2.772 2.772 0 002.33-.757l1.954-1.89a.94.94 0 011.312 0l1.953 1.89a2.771 2.771 0 002.33.757l2.691-.38a.94.94 0 011.062.77l.47 2.678c.149.853.674 1.576 1.44 1.982l2.4 1.274a.94.94 0 01.405 1.248l-1.193 2.441a2.772 2.772 0 000 2.45l1.194 2.441a.94.94 0 01-.406 1.249z"
        fill="#fff"
      />
      <path
        d="M29.872 16.415a.938.938 0 010-.83l1.194-2.441a2.778 2.778 0 00-1.199-3.689l-2.4-1.274a.938.938 0 01-.488-.67l-.47-2.677a2.778 2.778 0 00-3.137-2.28l-2.691.38a.937.937 0 01-.788-.255L17.939.789a2.778 2.778 0 00-3.878 0l-1.954 1.89a.938.938 0 01-.788.256l-2.69-.38a2.778 2.778 0 00-3.139 2.28L5.02 7.51a.938.938 0 01-.486.67L2.133 9.455a2.778 2.778 0 00-1.199 3.69l1.194 2.44a.938.938 0 010 .83L.934 18.856a2.778 2.778 0 001.199 3.689l2.4 1.274a.937.937 0 01.488.67l.47 2.677a2.776 2.776 0 003.137 2.28l2.691-.38a.938.938 0 01.788.256l1.954 1.889a2.78 2.78 0 003.878 0l1.954-1.89a.938.938 0 01.788-.256l2.69.381a2.778 2.778 0 003.139-2.28l.47-2.677a.938.938 0 01.486-.67l2.401-1.274a2.778 2.778 0 001.199-3.689l-1.194-2.442zm-.87 4.5l-2.4 1.273a2.772 2.772 0 00-1.44 1.982l-.47 2.677a.94.94 0 01-1.062.771l-2.69-.38a2.77 2.77 0 00-2.33.757l-1.954 1.89a.94.94 0 01-1.312 0l-1.953-1.89a2.769 2.769 0 00-2.33-.757l-2.691.38a.94.94 0 01-1.062-.77l-.47-2.678a2.771 2.771 0 00-1.44-1.982l-2.4-1.273a.94.94 0 01-.406-1.248l1.194-2.442c.38-.779.38-1.671 0-2.45l-1.194-2.441a.94.94 0 01.406-1.248l2.4-1.274a2.772 2.772 0 001.44-1.982l.47-2.677a.94.94 0 011.062-.771l2.69.38a2.772 2.772 0 002.33-.757l1.954-1.89a.94.94 0 011.312 0l1.953 1.89a2.771 2.771 0 002.33.757l2.691-.38a.94.94 0 011.062.77l.47 2.678c.149.853.674 1.576 1.44 1.982l2.4 1.274a.94.94 0 01.405 1.248l-1.193 2.441a2.772 2.772 0 000 2.45l1.194 2.441a.94.94 0 01-.406 1.249z"
        fill="#fff"
      />
      <path
        d="M29.872 16.415a.938.938 0 010-.83l1.194-2.441a2.778 2.778 0 00-1.199-3.689l-2.4-1.274a.938.938 0 01-.488-.67l-.47-2.677a2.778 2.778 0 00-3.137-2.28l-2.691.38a.937.937 0 01-.788-.255L17.939.789a2.778 2.778 0 00-3.878 0l-1.954 1.89a.938.938 0 01-.788.256l-2.69-.38a2.778 2.778 0 00-3.139 2.28L5.02 7.51a.938.938 0 01-.486.67L2.133 9.455a2.778 2.778 0 00-1.199 3.69l1.194 2.44a.938.938 0 010 .83L.934 18.856a2.778 2.778 0 001.199 3.689l2.4 1.274a.937.937 0 01.488.67l.47 2.677a2.776 2.776 0 003.137 2.28l2.691-.38a.938.938 0 01.788.256l1.954 1.889a2.78 2.78 0 003.878 0l1.954-1.89a.938.938 0 01.788-.256l2.69.381a2.778 2.778 0 003.139-2.28l.47-2.677a.938.938 0 01.486-.67l2.401-1.274a2.778 2.778 0 001.199-3.689l-1.194-2.442zm-.87 4.5l-2.4 1.273a2.772 2.772 0 00-1.44 1.982l-.47 2.677a.94.94 0 01-1.062.771l-2.69-.38a2.77 2.77 0 00-2.33.757l-1.954 1.89a.94.94 0 01-1.312 0l-1.953-1.89a2.769 2.769 0 00-2.33-.757l-2.691.38a.94.94 0 01-1.062-.77l-.47-2.678a2.771 2.771 0 00-1.44-1.982l-2.4-1.273a.94.94 0 01-.406-1.248l1.194-2.442c.38-.779.38-1.671 0-2.45l-1.194-2.441a.94.94 0 01.406-1.248l2.4-1.274a2.772 2.772 0 001.44-1.982l.47-2.677a.94.94 0 011.062-.771l2.69.38a2.772 2.772 0 002.33-.757l1.954-1.89a.94.94 0 011.312 0l1.953 1.89a2.771 2.771 0 002.33.757l2.691-.38a.94.94 0 011.062.77l.47 2.678c.149.853.674 1.576 1.44 1.982l2.4 1.274a.94.94 0 01.405 1.248l-1.193 2.441a2.772 2.772 0 000 2.45l1.194 2.441a.94.94 0 01-.406 1.249z"
        fill="#fff"
      />
      <path
        d="M22.743 9.257a.923.923 0 00-1.305 0l-12.18 12.18a.923.923 0 101.304 1.306l12.18-12.18a.923.923 0 000-1.306zM12.309 7.695a3.388 3.388 0 00-3.384 3.383 3.387 3.387 0 003.384 3.384 3.387 3.387 0 003.383-3.384 3.388 3.388 0 00-3.383-3.383zm0 4.921a1.54 1.54 0 01-1.538-1.538c0-.848.69-1.538 1.538-1.538.848 0 1.538.69 1.538 1.538a1.54 1.54 0 01-1.538 1.538zm7.382 4.922a3.388 3.388 0 00-3.383 3.384 3.388 3.388 0 003.383 3.383 3.388 3.388 0 003.384-3.383 3.388 3.388 0 00-3.384-3.384zm0 4.922a1.54 1.54 0 01-1.538-1.538c0-.848.69-1.538 1.538-1.538.848 0 1.538.69 1.538 1.538a1.54 1.54 0 01-1.538 1.538z"
        fill="#fff"
      />
    </svg>
  )
}

export default DiscountIcon

import { createEffect } from 'effector'
import axios from 'axios'

import { $allPaymentTypes } from './index'

export const loadPaymentTypes = createEffect({
  handler: async () => {
    const res = await axios.get('/api/paymenttypes')
    return res.data.data
  },
})

$allPaymentTypes.on(loadPaymentTypes.done, (_, { result }) => result)

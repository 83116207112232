import React, { useEffect, useState, useRef } from 'react'
import { useParams } from '@reach/router'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import PageLoader from './shared/PageLoader'
import Layout from './shared/Layout'
import Header from './Restaurant/Header'
import Info from './Restaurant/Info'
import RestaurantMenu from './Restaurant/RestaurantMenu'
import DeliveryMode from './Restaurant/DeliveryMode'
import InfoModal from './shared/InfoModal'
import Button from './shared/Button'
import FixedFooter from './shared/FixedFooter'
import FlowButton from './shared/FlowButton'
import ItemModal from './shared/ItemModal'
import Tag from './shared/Tag'
import { ReactComponent as InfoIcon } from '../img/InfoIcon.svg'
// import PhoneIcon from './shared/PhoneIcon'
import StarIcon from './shared/StarIcon'
import GiftIcon from './shared/GiftIcon'
import TimeSelect from './shared/TimeSelect'
import { $cart } from '../store'
import {
  $cartTotalPrice,
  $deliveryFee,
  cartClick,
  $shopDeliveryDistanceFeesObjectStore,
  clearCart,
} from '../store/cart'
import {
  $currentRestaurant,
  $enreachedRestaurantItems,
  openRestaurantEvent,
} from '../store/restaurants'
import { $selectedTime, timeSelected } from '../store/timeIntervals'
import {
  $isYmapsLoaded,
  calculateDistanceFx,
  $currentDistance,
  $currentAddress,
  $deliveryAvailabilityModal,
  setDeliveryAvailabilityModal,
  $isDeliveryAvailable,
} from '../store/addresses'

const RestLayout = styled(Layout)`
  grid-template-rows: repeat(2, auto) 1fr auto;
  position: relative;
`

const DeliveryInfo = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  padding: 1rem 1rem 2rem;
`

const ModalText = styled.span`
  color: var(--secondary-text-color);
  text-align: ${({ centered }) => centered && 'center'};
`

const ModalTable = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem 1rem;
`

const ModalList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;

    &::before {
      content: '•';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const FooterMessage = styled.small`
  display: block;
  width: 100%;
  padding: 0 0.25rem 0.5rem;
  text-align: center;
  background-color: var(--main-background-color);
`

function Restaurant() {
  const infoBlockRef = useRef()
  const [infoBlock, setInfoBlock] = useState(null)
  const [deliveryInfo, setDeliveryInfo] = useState(false)
  const [ratingModal, setRatingModal] = useState(false)
  const [specialsInfo, setSpecialsInfo] = useState(false)
  const [timeWarning, setTimeWarning] = useState(false)
  const [addressWarning, setAddressWarning] = useState(false)
  const [addressSelect, setAddressSelect] = useState(false)
  const [restInfo, setRestInfo] = useState(false)

  const { restaurantId } = useParams()
  const cart = useStore($cart)
  const cartTotalPrice = useStore($cartTotalPrice)
  const currentRestaurant = useStore($currentRestaurant)
  const deliveryFee = useStore($deliveryFee)
  const enreachedRestaurantItems = useStore($enreachedRestaurantItems)
  const deliveryAvailabilityModal = useStore($deliveryAvailabilityModal)
  const calculateDistanceFxPending = useStore(calculateDistanceFx.pending)
  const shopDeliveryDistanceFeesObjectStore = useStore(
    $shopDeliveryDistanceFeesObjectStore,
  )
  const currentAddress = useStore($currentAddress)
  const currentDistance = useStore($currentDistance)
  const isYmapsLoaded = useStore($isYmapsLoaded)
  const { value } = useStore($selectedTime)
  const isDeliveryAvailable = useStore($isDeliveryAvailable)

  const rating =
    currentRestaurant && currentRestaurant.rating_auto
      ? 'Мало оценок'
      : currentRestaurant.rating_score

  const minOrderPrice =
    shopDeliveryDistanceFeesObjectStore &&
    Math.min(...Object.keys(shopDeliveryDistanceFeesObjectStore))

  useEffect(() => {
    setInfoBlock(infoBlockRef.current)
  }, [])

  useEffect(() => {
    openRestaurantEvent(restaurantId)
    window.scrollTo(0, 0)
  }, [restaurantId])

  const handleTimeSelectChange = e => {
    timeSelected(+e.target.value)
  }

  return (
    <RestLayout>
      <Header
        title={currentRestaurant ? currentRestaurant.title : 'Загрузка…'}
      />
      <div ref={infoBlockRef}>
        <Info title={currentRestaurant ? currentRestaurant.title : 'Загрузка…'}>
          {currentRestaurant && (
            <>
              {currentRestaurant.rating_score && (
                <Tag
                  type="secondary"
                  icon={<StarIcon />}
                  text={rating}
                  onClick={() => setRatingModal(true)}
                />
              )}
              {currentRestaurant.deliveryPrices && (
                <Tag
                  type="secondary"
                  text={
                    cart.length > 0 &&
                    currentRestaurant.id === cart[0].shop_id &&
                    typeof deliveryFee === 'number'
                      ? `Доставка ${deliveryFee} ₽`
                      : currentRestaurant.deliveryFeeRangeMessage
                  }
                  onClick={() => {
                    if (isDeliveryAvailable) {
                      setDeliveryInfo(true)
                    } else {
                      setDeliveryAvailabilityModal(true)
                    }
                  }}
                />
              )}
              <TimeSelect
                width="auto"
                value={value}
                options={currentRestaurant.threeDaysTimeIntervals}
                onChange={handleTimeSelectChange}
              >
                <Tag
                  text={currentRestaurant.deliveryToMessage}
                  type="secondary"
                />
              </TimeSelect>
              {currentRestaurant &&
                currentRestaurant.specialGift &&
                Object.keys(currentRestaurant.specialGift).length > 0 && (
                  <Tag
                    icon={<GiftIcon width={12} height={12} />}
                    text="Акции"
                    type="secondary"
                    onClick={() => setSpecialsInfo(true)}
                  />
                )}
              {currentRestaurant && currentRestaurant.bonusPercent > 0 && (
                <Tag
                  text={`Кешбэк +${currentRestaurant.bonusPercent}%`}
                  type="secondary"
                />
              )}
              {
                <Tag
                  icon={<InfoIcon />}
                  type="secondary"
                  onClick={() => setRestInfo(true)}
                />
              }
              {/* <Tag text="Доставит курьер ресторана" type="secondary" /> */}
            </>
          )}
        </Info>
        {currentRestaurant.takeaway && (
          <div style={{ padding: '0.5rem 1rem 1.5rem' }}>
            <DeliveryMode />
          </div>
        )}
      </div>
      <RestaurantMenu
        infoBlock={infoBlock}
        active={currentRestaurant.available}
        timeWarning={timeWarning}
        setTimeWarning={setTimeWarning}
        addressWarning={addressWarning}
        setAddressWarning={setAddressWarning}
        addressSelect={addressSelect}
        setAddressSelect={setAddressSelect}
      />
      {currentRestaurant.available && (
        <FixedFooter
          isShown={cart.length > 0 && Number(restaurantId) === cart[0].shop_id}
        >
          {cartTotalPrice !== 0 && cartTotalPrice < minOrderPrice && (
            <FooterMessage>
              До минимального заказа {minOrderPrice - cartTotalPrice}&nbsp;₽
            </FooterMessage>
          )}
          <FlowButton
            onClick={() => {
              cartClick({
                showAddressModal: () => setAddressWarning(true),
                showTimeModal: () => setTimeWarning(true),
              })
            }}
            text={'К корзине'}
            right={`${cartTotalPrice} ₽`}
          />
        </FixedFooter>
      )}
      <InfoModal
        title="Условия доставки"
        isShown={deliveryInfo}
        onCloseClick={() => setDeliveryInfo(false)}
      >
        <DeliveryInfo>
          {currentAddress.line && currentDistance >= 0 ? (
            <>
              <ModalText>
                Расстояние до ресторана: {currentDistance} м
              </ModalText>
              <ModalTable>
                {shopDeliveryDistanceFeesObjectStore &&
                  Object.keys(shopDeliveryDistanceFeesObjectStore).map(key => {
                    const deliveryFee = shopDeliveryDistanceFeesObjectStore[key]
                    return (
                      <React.Fragment key={key}>
                        <strong>{deliveryFee} ₽</strong>
                        <span>при заказе от {key} ₽</span>
                      </React.Fragment>
                    )
                  })}
              </ModalTable>
            </>
          ) : (
            <>
              <ModalText centered>
                Укажите адрес, чтобы мы могли расчитать стоимость доставки
              </ModalText>
              <Button
                type="button"
                onClick={() => {
                  setDeliveryInfo(false)
                  setAddressSelect(true)
                }}
              >
                Указать адрес
              </Button>
            </>
          )}
        </DeliveryInfo>
      </InfoModal>
      {deliveryAvailabilityModal && (
        <InfoModal
          title="Доставка недоступна"
          isShown={true}
          onCloseClick={() => {
            if (cart.length > 0 && currentRestaurant.id === cart[0].shop_id) {
              clearCart()
            }
            setDeliveryAvailabilityModal(false)
          }}
        >
          <div
            style={{
              display: 'grid',
              gridGap: '1rem',
              padding: '1rem 1rem 2rem',
            }}
          >
            <ModalText centered>Ваш адрес ещё не обслуживается</ModalText>
            <Button
              type="button"
              onClick={() => {
                setDeliveryAvailabilityModal(false)
                setAddressSelect(true)
              }}
            >
              Изменить адрес
            </Button>
          </div>
        </InfoModal>
      )}
      {currentRestaurant.rating_score && (
        <InfoModal
          isShown={ratingModal}
          title="Рейтинг ресторана"
          onCloseClick={() => setRatingModal(false)}
        >
          <div
            style={{
              display: 'grid',
              gridGap: '1rem',
              padding: '1rem 1rem 2rem',
            }}
          >
            <strong>
              <StarIcon /> {rating}
            </strong>
            <small style={{ color: 'var(--secondary-text-color)' }}>
              На&nbsp;основе оценок за&nbsp;последние 3&nbsp;месяца
            </small>
          </div>
        </InfoModal>
      )}
      <InfoModal
        title="Акции"
        isShown={specialsInfo}
        onCloseClick={() => setSpecialsInfo(false)}
      >
        {currentRestaurant && (
          <div
            style={{
              display: 'grid',
              gridGap: '1rem',
              padding: '1rem 1rem 2rem',
              fontSize: '0.9rem',
            }}
          >
            <strong>Товары в подарок</strong>
            <ModalTable style={{ gridGap: '2rem 1rem' }}>
              {currentRestaurant &&
                currentRestaurant.specialGift &&
                enreachedRestaurantItems &&
                Object.keys(currentRestaurant.specialGift).map(key => {
                  const gifts = enreachedRestaurantItems
                    .reduce((acc, group) => {
                      acc = [...acc, ...group.items]
                      return acc
                    }, [])
                    .filter(item =>
                      currentRestaurant.specialGift[key].includes(item.id),
                    )
                    .sort((a, b) => Number(a.price) - Number(b.price))
                  return (
                    <React.Fragment key={key}>
                      <ModalText>При заказе от {key} ₽</ModalText>
                      <ModalList>
                        {gifts.map(gift => (
                          <li>{gift.title}</li>
                        ))}
                      </ModalList>
                    </React.Fragment>
                  )
                })}
            </ModalTable>
          </div>
        )}
      </InfoModal>
      <InfoModal
        isShown={restInfo}
        title={currentRestaurant.title}
        onCloseClick={() => setRestInfo(false)}
      >
        <div
          style={{
            display: 'grid',
            gridGap: '1rem',
            padding: '1rem 1rem 2rem',
            fontSize: '0.9rem',
          }}
        >
          <span>
            Адреса:{' '}
            {currentRestaurant
              ? currentRestaurant.shopAddresses?.map(
                  ({ address }, index, arr) => (
                    <span key={address}>
                      {address.trim()}
                      {index + 1 < arr.length ? '; ' : ''}
                    </span>
                  ),
                )
              : ''}
          </span>
          <span>
            Исполнитель (Продавец): {currentRestaurant.legal_name}
            {currentRestaurant.legal_address
              ? `, ${currentRestaurant.legal_address}`
              : ''}
            {currentRestaurant.inn ? `, ИНН: ${currentRestaurant.inn}` : ''}
            {currentRestaurant.ogrn ? `, ОГРН: ${currentRestaurant.ogrn}` : ''}
          </span>
          <span>{currentRestaurant?.workTimeMessage}</span>
        </div>
      </InfoModal>
      <ItemModal />
      <PageLoader loading={!isYmapsLoaded || calculateDistanceFxPending} />
    </RestLayout>
  )
}

export default Restaurant

import React from 'react'

function LogoutIcon({ width = 30, height = 30 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M.371 15.718l3.21 2.263a.88.88 0 001.386-.718V15.88h15.412a.879.879 0 000-1.758H4.967v-1.384a.88.88 0 00-1.386-.718l-3.21 2.263a.88.88 0 000 1.436z" />
      <path d="M4.777 9.356a.88.88 0 001.201-.32A11.928 11.928 0 0116.312 3.07c6.578 0 11.93 5.352 11.93 11.93 0 6.578-5.352 11.93-11.93 11.93-4.413 0-8.27-2.399-10.334-5.966a.88.88 0 00-1.522.88 13.686 13.686 0 0011.856 6.844C23.878 28.688 30 22.566 30 15c0-7.565-6.122-13.688-13.688-13.688-5.048 0-9.482 2.74-11.856 6.843a.88.88 0 00.321 1.201z" />
    </svg>
  )
}

export default LogoutIcon

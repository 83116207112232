import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from '@reach/router'

import StarIcon from '../../shared/StarIcon'
import { textOverflow } from '../../shared/styleMixins'
import GiftIconColored from '../../shared/GiftIconColored'

const unavailable = css`
  opacity: 0.5;
`

const disabledStyle = css`
  pointer-events: none;
  cursor: default;
`

const Container = styled.div`
  ${({ disabled }) => disabled && disabledStyle}
`

const LinkContainer = styled(Link)`
  display: block;
  overflow: hidden;
  border-radius: 20px;
  color: var(--text-color);
  text-decoration: none;
  box-shadow: 0 0 20px var(--shadow-color);
`

const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: ${({ image }) => (image ? '200px' : 'auto')};
  background-image: url('${({ image }) => image && image}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ available }) => !available && unavailable}
`

const GiftBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: var(--main-accent-color);
  border: 2px solid var(--main-background-color);
  border-radius: 50%;
`

const Description = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 1rem 1.5rem;
`

const Title = styled.h3`
  margin: 0;
  font-size: 1.375rem;
  font-weight: 700;

  ${textOverflow}
`

const Info = styled.span`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-left: -0.5rem;
  font-size: 1rem;
  ${textOverflow}

  &>* {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const CashbackTag = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: block;
  padding: 0.3rem 0.6rem;
  color: var(--main-background-color);
  font-size: 0.9rem;
  font-weight: 700;
  background: var(--main-accent-color);
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 2px var(--shadow-color);
`

function RestaurantCard({ restaurant }) {
  const {
    id,
    title,
    image,
    isOpen,
    available,
    justOpened,
    workTimeMessage,
    deliveryToMessage,
    rating_score,
    rating_auto,
    specialGift,
    bonusPercent,
  } = restaurant

  return (
    <Container available={isOpen && available}>
      <LinkContainer to={`/restaurant/${id}`}>
        <Cover image={image} available={isOpen && available}>
          {specialGift && Object.keys(specialGift).length > 0 && (
            <GiftBadge>
              <GiftIconColored />
            </GiftBadge>
          )}
          {bonusPercent > 0 && (
            <CashbackTag>повышенный кешбэк +{bonusPercent}%</CashbackTag>
          )}
        </Cover>
        <Description>
          <Title>{title}</Title>
          <Info>
            <span>
              <StarIcon />{' '}
              {justOpened
                ? 'Новый'
                : rating_auto
                ? 'Мало оценок'
                : rating_score && rating_score.slice(0, 3)}
            </span>

            <span style={{ color: 'gray' }}>
              {!available
                ? 'Временно недоступен'
                : !isOpen
                ? workTimeMessage
                : deliveryToMessage}
            </span>
          </Info>
        </Description>
      </LinkContainer>
    </Container>
  )
}

export default RestaurantCard

import React from 'react'

const Logo = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.34 0c.675 0 1.23.554 1.23 1.23v25.847c0 .032-.008.06-.017.09a.444.444 0 00-.015.064V32l-6.153-3.692H2.23c-.678 0-1.231-.554-1.231-1.231V1.23C1 .554 1.553 0 2.23 0h27.11zm-5.11 22.536c0 .355-.717.644-1.072.644-.356 0-1.074-.288-1.074-.644l.43-7.083c-.132-.293-.325-.594-.538-.927-.665-1.034-1.533-2.386-1.388-4.845.125-2.13 1.628-3.707 2.098-4.093a.671.671 0 01.166-.128l.04-.027v.006a.644.644 0 01.91.585l.429 16.512zm-5.677-10.29c.594-.362 1.042-1.159 1.042-1.855V6.064a.644.644 0 00-1.288 0v3.898h-.358V6.064a.644.644 0 00-1.288 0v3.898h-.358V6.064a.644.644 0 00-1.288 0v3.898h-.357v-3.76a.644.644 0 00-1.287 0v4.261c0 .716.483 1.499 1.123 1.819l1.112.555c.126.063.266.11.412.144l-.43 9.66c0 .297.718.539 1.074.539.355 0 1.073-.241 1.073-.54l-.43-9.708c.152-.048.297-.106.425-.184l.823-.502zM9.455 5.42c2.862 0 2.914 4.956 2.914 4.956.09 1.753-1.667 2.372-1.667 2.372a1.899 1.899 0 01-.424.184l.43 9.708c0 .299-.719.54-1.074.54-.356 0-1.073-.242-1.073-.54l.43-9.659a1.887 1.887 0 01-.413-.144s-2.04-.565-2.04-2.371c0 0 .038-5.046 2.917-5.046z"
    />
  </svg>
)

export default Logo

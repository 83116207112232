import React from 'react'
import styled, { css } from 'styled-components'

import { textOverflow } from '../shared/styleMixins'

const SecondaryStyle = css`
  background: var(--alt-background-color);
  box-shadow: 0 0 4px var(--shadow-color);
  border: 1px solid var(--border-color);
`

const Container = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0.5rem 0.75rem;
  color: var(--stext-color);
  font-size: inherit;
  line-height: 100%;
  text-decoration: none;
  background: var(--main-background-color);
  border-radius: 14px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${textOverflow}
  ${({ type }) => type === 'secondary' && SecondaryStyle}

  svg {
    margin-right: ${({ withText }) => (withText ? '0.5rem' : '')};
    fill: ${({ danger, safe }) =>
      danger
        ? 'var(--danger-color)'
        : safe
        ? 'var(--main-accent-color)'
        : 'currentColor'};
  }
`

function Tag({ icon, text, type, onClick, ...props }) {
  return (
    <Container type={type} {...props} onClick={onClick} withText={!!text}>
      {icon} {text}
    </Container>
  )
}

export default Tag

import React, { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { useStore } from 'effector-react'
import { useForm } from 'react-hook-form'

import Button from '../Button'
import Captcha from '../../Captcha'
import { $phoneConfirmError, loginWithPhoneFx } from '../../../store/auth'
import { IS_CAPTCHA_ENABLED } from '../../../utils/constants'


const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`

const ErrorMessage = styled.span`
  display: block;
  width: 100%;
  height: 1.25rem;
  margin-bottom: 10px;
  color: var(--danger-color);
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
`

const PhoneMask = styled(InputMask)`
  height: 50px;
  margin-bottom: 10px;
  padding: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  background-color: var(--main-background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: none;
  appearance: none;

  &:focus {
    border-color: var(--main-accent-color);
  }

  &::placeholder {
    color: var(--secondary-text-color);
    transition: color 0.2s;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

const InstructionText = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
`

const StyledButton = styled(Button)`
  height: 50px;
  font-size: 1.125rem;
  font-weight: bold;
`

const Warning = styled.p`
  margin: 0;
  margin-top: 30px;
  padding: 0 1rem;
  color: var(--secondary-text-color);
  font-size: 0.75rem;
  text-align: center;
`

const PHONE_MASK = '+7 (999) 999-99-99'
const PHONE_MASK_LENGTH = PHONE_MASK.replace(/\D+/g, '').length

function PhoneForm({ phone, setPhone }) {
  const recaptchaRef = useRef()
  const phoneConfirmError = useStore($phoneConfirmError)
  const { register, handleSubmit, errors } = useForm()
  const [verified, setVerified] = useState(false);
  const handlePhoneChange = useCallback((e) => {
    const value = e.target.value
    if (!value) return

    const isFilled = !value.includes('_')
    if(IS_CAPTCHA_ENABLED) {
      if (isFilled && !verified) {
        recaptchaRef.current.reset()
      }
    }
  }, [verified])
  const handleVerify = useCallback(() => setVerified(true), [setVerified]);

  return (
    <form
      onSubmit={handleSubmit(({ phone }) => {
        const pureNumber = phone.replace(/\D+/g, '');
        let recaptchaValue;

        if(IS_CAPTCHA_ENABLED) {
          if(!verified) return;
          recaptchaValue = recaptchaRef.current.getValue();
          window.recaptchaValue = recaptchaValue;
        }

        setPhone(pureNumber)
        return loginWithPhoneFx({phone: pureNumber, code: recaptchaValue});
      })}
    >
      <FormContainer>
        <InstructionText>Введите номер телефона</InstructionText>
        <ErrorMessage>
          {phoneConfirmError || (errors.phone && errors.phone.message)}
        </ErrorMessage>
        <PhoneMask
          ref={register({
            validate: value =>
              value.replace(/\D+/g, '').length === PHONE_MASK_LENGTH ||
              'Введён некорректный номер',
          })}
          name="phone"
          type="tel"
          defaultValue={phone && phone.slice(1)}
          mask={PHONE_MASK}
          placeholder="Введите номер телефона"
          onChange={handlePhoneChange}
        />

        {
          IS_CAPTCHA_ENABLED
          ?
              <>
                <Captcha handleVerify={handleVerify} ref={recaptchaRef}/>
                <StyledButton type="submit" disabled={!verified || !loginWithPhoneFx.pending}>
                  Далее
                </StyledButton>
              </>
          :
            <StyledButton type="submit" disabled={!loginWithPhoneFx.pending}>
              Далее
            </StyledButton>
        }


        <Warning>
          Нажимая кнопку &laquo;Далее&raquo;, вы&nbsp;принимаете условия{' '}
          <a href="https://edaelista.ru/oferta">пользовательского соглашения</a>
        </Warning>
      </FormContainer>
    </form>
  )
}

export default PhoneForm

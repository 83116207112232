import React from 'react'

function TrashIcon({ width = 40, height = 40 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M29.744 13.364l-.53-1.588a1.478 1.478 0 00-1.403-1.011h-4.454v-1.45c0-.725-.59-1.315-1.314-1.315h-4.078c-.725 0-1.314.59-1.314 1.315v1.45h-4.454c-.637 0-1.201.406-1.403 1.011l-.53 1.588a1.192 1.192 0 001.128 1.566h.554l1.219 15.075A2.18 2.18 0 0015.326 32h9.603a2.18 2.18 0 002.161-1.995l1.22-15.075h.306c.381 0 .742-.185.965-.494.223-.31.284-.71.163-1.072zM18.057 9.406h3.894v1.358h-3.894V9.406zm7.632 20.486a.767.767 0 01-.76.702h-9.603a.767.767 0 01-.76-.703l-1.21-14.961H26.9l-1.21 14.962zM11.693 13.524l.435-1.303c.01-.03.038-.05.07-.05H27.81c.031 0 .06.02.07.05l.434 1.303H11.693z" />
      <path d="M23.446 29.842a.703.703 0 00.739-.666l.66-12.675a.703.703 0 00-1.404-.073l-.66 12.675a.703.703 0 00.665.739zM15.855 29.178a.703.703 0 001.404-.077l-.692-12.675a.703.703 0 10-1.404.077l.692 12.675zM20.012 29.843a.703.703 0 00.703-.703V16.465a.703.703 0 10-1.406 0V29.14c0 .388.314.703.703.703z" />
    </svg>
  )
}

export default TrashIcon

import { createEffect, createEvent, createStore } from 'effector'

export const applyWithdrawalFx = createEffect()

export const withdrawalModalSet = createEvent('withdrawal modal was set')
export const $withdrawalModal = createStore(false)

export const withdrawalConfirmed = createEvent('withdrawal was confirmed')
export const wthdrwlConfirmClosed = createEvent(
  'withdrawal confirm modal was closed',
)
export const $withdrawalConfirmedModal = createStore(false)

export const withdrawalTextSet = createEvent('withdrawal text was set')
export const $withdrawalText = createStore('')

export const withdrawalSubmitted = createEvent('withdrawal was submitted')

export const withdrawalErrorSet = createEvent('withdrawal error was set')
export const $withdrawalError = createStore('')

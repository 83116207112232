import {
  createEffect,
  createEvent,
  createStore,
  sample,
  combine,
} from 'effector'
import axios from 'axios'

import { loginCheckFx, checkSession } from './auth'
import {
  $token,
  $takeawayMode,
  takeawayModeToggled,
  takeawayModeSettedFromLocalStorage,
  takeawayModeKey,
  $takeawayAddress,
  takeawayAddressSetted,
} from './index'
import { $currentRestaurant } from './restaurants'

export const addAddressFx = createEffect({
  handler: async address => {
    const res = await axios.post('/api/customer/address', address, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const updateAddressFx = createEffect({
  handler: async address => {
    const res = await axios.put('/api/customer/address', address, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const deleteAddressFx = createEffect({
  handler: async id => {
    await axios.delete(`/api/customer/address/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return id
  },
})

export const calculateDistanceFx = createEffect({
  handler: async ({ customerAddress, shopAddresses }) => {
    const distances = await Promise.all(
      shopAddresses.map(({ address }) =>
        window.ymaps.route([address, customerAddress]),
      ),
    )

    setDeliveryAvailabilityModal(false)
    return Math.min(...distances.map(route => Math.round(route.getLength())))
  },
})

export const loadAddressesFx = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/address', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })

    return res.data.data
  },
})

export const selectAddress = createEvent('select address for edit')
export const setCurrentAddress = createEvent('set current address')
export const changeCurrentAddress = createEvent('change current address')
export const setCurrentAddressFromLocalStorage = createEvent(
  'set current address from local storage',
)
export const setAddressEditModal = createEvent('set address edit modal')
export const checkDistance = createEvent('check distance')
export const ymapsReady = createEvent('ymaps ready')
export const setDeliveryAvailabilityModal = createEvent(
  'set delivery availability modal',
)
export const checkIfDeliveryAvailable = createEvent(
  'check if delivery available',
)
export const setAddressSuggestions = createEvent('set address suggestion')
export const setCurrentAddressFromOrder = createEvent('set address from order')

export const $customerAddresses = createStore([])
export const $addressSuggestions = $customerAddresses.map(
  addresses => addresses,
)
export const $selectedAddress = createStore({})
export const $currentAddress = createStore({})
export const $currentDistance = createStore(null)
export const $isYmapsLoaded = createStore(false)
export const $isDeliveryAvailable = createStore(true)
export const $deliveryAvailabilityModal = createStore(false)
export const $addressEditModal = createStore(false)
export const $currentAddressLine = $currentAddress.map(
  ({ line }) => line || null,
)
export const $currentRestaurantId = createStore(0)

sample({
  source: $currentRestaurant,
  fn: restaurant => (restaurant ? restaurant.id : 0),
  target: $currentRestaurantId,
})

$takeawayMode
  .on(takeawayModeToggled, prevValue => !prevValue)
  .on(takeawayModeSettedFromLocalStorage, (_, value) =>
    value !== null ? value : false,
  )
  .on($currentRestaurant, (_, restaurant) => {
    if (Object.keys(restaurant).length > 0 && !restaurant.takeaway) {
      return restaurant.takeaway
    }
  })

$takeawayMode.updates.watch(value =>
  localStorage.setItem(takeawayModeKey, JSON.stringify(value)),
)

$takeawayAddress.on(takeawayAddressSetted, (_, address) => address)

$addressEditModal.on(setAddressEditModal, (_, value) => value)
deleteAddressFx.done.watch(() => setAddressEditModal(false))
$addressSuggestions.on(setAddressSuggestions, (_, suggestions) => suggestions)

window.ymaps.ready(ymapsReady)

$isYmapsLoaded.on(ymapsReady, () => true)

$isYmapsLoaded.updates.watch(checkDistance)

sample({
  source: combine({
    currentAddress: $currentAddress,
    currentRestaurant: $currentRestaurant,
    isYmapsLoaded: $isYmapsLoaded,
  }),
  clock: checkDistance,
  fn: ({ currentAddress, currentRestaurant, isYmapsLoaded }) => {
    const { distances, line } = currentAddress
    const { id: shopId, shopAddresses } = currentRestaurant
    if (line && shopId) {
      if (distances) {
        const distanceObject = distances.find(item => item.shop_id === shopId)
        if (distanceObject) {
          return distanceObject.distance
        }
      }
      if (isYmapsLoaded) {
        calculateDistanceFx({ customerAddress: line, shopAddresses })
      }
    }
  },
  target: $currentDistance,
})

sample({
  source: combine({
    distance: $currentDistance,
    currentRestaurant: $currentRestaurant,
  }),
  clock: calculateDistanceFx.done,
  fn: ({ distance, currentRestaurant }) => {
    const { deliveryPrices } = currentRestaurant
    const shopDeliveryKeys = Object.keys(deliveryPrices).map(el => +el)
    const deliveryDistanceKey = shopDeliveryKeys.find(
      distanceKey => +distance < distanceKey,
    )
    if (deliveryDistanceKey === undefined) return false
    return true
  },
  target: $isDeliveryAvailable,
})

$deliveryAvailabilityModal.on(setDeliveryAvailabilityModal, (_, value) => value)

sample({
  source: $isDeliveryAvailable,
  clock: checkIfDeliveryAvailable,
  fn: isDeliveryAvailable => !isDeliveryAvailable,
  target: $deliveryAvailabilityModal,
})

calculateDistanceFx.done.watch(checkIfDeliveryAvailable)

$currentAddressLine.updates.watch(() => {
  checkDistance()
})
$currentRestaurant.updates.watch(() => {
  checkDistance()
})

$currentDistance.on(calculateDistanceFx.done, (_, { result }) => result)

export const selectedAddressKey = 'selected_address'

$customerAddresses
  .on(loginCheckFx.done, (_, { result }) => result.addresses)
  .on(checkSession.done, (_, { result }) => result.addresses)
  .on(addAddressFx.done, (prev, { result }) => [...prev, result])
  .on(updateAddressFx.done, (prev, { result }) =>
    prev.map(item => (item.id === result.id ? result : item)),
  )
  .on(deleteAddressFx.done, (prev, { result }) =>
    prev.filter(({ id }) => id !== result),
  )
  .on(loadAddressesFx.done, (_, { result }) => result)

$currentAddress
  .on(setCurrentAddress, (_, address) => address)
  .on(changeCurrentAddress, (prev, { key, value }) => {
    const newAddress = { ...prev }
    newAddress[key] = value
    return newAddress
  })
  .on(setCurrentAddressFromLocalStorage, (_, address) =>
    address !== null ? address : {},
  )
  .on(setCurrentAddressFromOrder, (_, address) => address)

$currentAddress.updates.watch(address => {
  localStorage.setItem(selectedAddressKey, JSON.stringify(address))
})

sample({
  source: $customerAddresses,
  clock: selectAddress,
  fn: (customerAddresses, address) =>
    customerAddresses.find(({ line }) => line === address.line) || address,
  target: $selectedAddress,
})

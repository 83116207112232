import React from 'react'
import styled, { css } from 'styled-components'
import Loader from 'react-loader-spinner'

const loadingStyle = css`
  position: fixed;
  top: 0;
  z-index: 100;
`

const loadedStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
`

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--max-body-width);
  height: 100vh;
  margin: 0 auto;
  background: var(--main-background-color);
  ${({ isLoading }) => (isLoading ? loadingStyle : loadedStyle)}
`

function PageLoader({ loading }) {
  return (
    <Page isLoading={loading}>
      <Loader type="ThreeDots" color="#11ae33" height={80} width={80} />
    </Page>
  )
}

export default PageLoader

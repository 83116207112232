import { createEffect, createEvent, createStoreObject } from 'effector'
import axios from 'axios'

import {
  $token,
  $selectedOrder,
  $feedbackFormScore,
  $feedbackFormComment,
  $feedbackFormAnon,
} from './index'
import { loadOrdersEffect } from './orders'

export const selectOrder = createEvent('select order')
export const changeFeedbackScore = createEvent('change feedback score')
export const changeFeedbackComment = createEvent('change feedback comment')
export const changeFeedbackAnon = createEvent('change feedback anonymous')
export const feedbackConfirm = createEvent('confirm feedback')

export const sendFeedbackFx = createEffect({
  handler: async feedback => {
    const res = await axios.post(
      `/api/customer/rating/${feedback.order.id}/`,
      { ...feedback },
      {
        headers: {
          Authorization: `Bearer ${$token.getState()}`,
        },
      },
    )
    return res.data.data
  },
})

sendFeedbackFx.done.watch(() => loadOrdersEffect())

$selectedOrder.on(selectOrder, (_, order) => order).reset(sendFeedbackFx.done)
$feedbackFormScore
  .on(changeFeedbackScore, (_, score) => score)
  .reset([selectOrder, sendFeedbackFx.done])
$feedbackFormComment
  .on(changeFeedbackComment, (_, comment) => comment)
  .reset([selectOrder, sendFeedbackFx.done])
$feedbackFormAnon
  .on(changeFeedbackAnon, (_, isAnonymous) => isAnonymous)
  .reset([selectOrder, sendFeedbackFx.done])

const $feedback = createStoreObject({
  order: $selectedOrder,
  score: $feedbackFormScore,
  comment: $feedbackFormComment,
  anonymous: $feedbackFormAnon,
})

$feedback.on(feedbackConfirm, feedback => sendFeedbackFx(feedback))

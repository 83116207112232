import { Link } from '@reach/router'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import DirectoryLayout from './Directory/DirectoryLayout'
import Filters from './Directory/Filters'
import Header from './Directory/Header'
import List from './Directory/List'
import MainMenu from './Directory/MainMenu'
import Stories from './Directory/Stories'
import FixedFooter from './shared/FixedFooter'
import FlowButton from './shared/FlowButton'
import Portal from './shared/Portal'

import { $restaurantCategories, $takeawayMode } from '../store'
import { $cartShop, $cartTotalPrice } from '../store/cart'
import { loadOrdersEffect } from '../store/orders'
import { loadRestaurantCategories } from '../store/restaurantCategories'
import {
  $enreachedRestaurants,
  exitRestaurantEvent,
} from '../store/restaurants'

function Directory() {
  const [isMenuShown, setIsMenuShown] = useState(false)
  const [category, setCategory] = useState('')

  const restaurantCategories = useStore($restaurantCategories)
  const restaurants = useStore($enreachedRestaurants)
  const cartShop = useStore($cartShop)
  const cartTotalPrice = useStore($cartTotalPrice)
  const takeawayMode = useStore($takeawayMode)

  useEffect(() => {
    loadRestaurantCategories()
    exitRestaurantEvent()
    loadOrdersEffect()

    window.scrollTo(0, 0)

    //let isSubscribed = true
    //loadRestaurantCategories({ isSubscribed })
    //loadOrdersEffect({ isSubscribed })
    //return () => {
    //isSubscribed = false
    //exitRestaurantEvent()
    //}
  }, [])

  const filteredRestaurants = restaurants
    .filter(({ confirmed }) => confirmed)
    .filter(({ dishcategories }) =>
      dishcategories.some(({ translit }) =>
        category ? translit === category : true,
      ),
    )
    .filter(({ takeaway }) => (takeawayMode ? takeaway : true))

  return (
    <DirectoryLayout>
      <Header onMenuClick={() => setIsMenuShown(true)} />
      <Stories />
      <Filters
        restaurantCategories={restaurantCategories}
        category={category}
        onChange={setCategory}
      />
      <StyledFoodsterBanner>
        <a href="https://foodster.pro/edaelistabanner">
          <img src="./banner-july24.png" />
        </a>
      </StyledFoodsterBanner>
      <List
        restaurants={filteredRestaurants}
        isFiltered={takeawayMode || category}
      />
      <FixedFooter isShown={cartShop}>
        {cartShop && (
          <FlowButton
            as={Link}
            to={`/restaurant/${cartShop.id}`}
            text={`${cartShop.title}`}
            right={`${cartTotalPrice} ₽`}
          />
        )}
      </FixedFooter>
      <Portal>
        <MainMenu isShown={isMenuShown} showingSetter={setIsMenuShown} />
      </Portal>
    </DirectoryLayout>
  )
}
export default Directory

const StyledFoodsterBanner = styled.div`
  margin: 16px 0;
`

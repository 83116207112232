import React from 'react'
import styled, { css } from 'styled-components'

const box = css`
  width: 40px;
  height: 40px;
  padding: 0.5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: var(--main-background-color);
`

const Button = styled.button`
  ${box}
  position: relative;
  background: var(--alt-background-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1rem;
    height: 2px;
    background-color: currentColor;
    transform: translate(-50%, -50%);
    transition: background 0.2s;
  }

  &:first-of-type::after {
    display: none;
  }

  &:last-of-type::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &:disabled {
    color: var(--secondary-text-color);
    cursor: not-allowed;
  }
`

const Count = styled.span`
  ${box}
  width: auto;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function Counter({
  onDecrease,
  onIncrease,
  number = 0,
  decDisabled,
  incDisabled,
}) {
  return (
    <Container>
      <Button
        type="button"
        aria-label="Уменьшить количество"
        onClick={onDecrease}
        disabled={decDisabled}
      />
      <Count>{number}</Count>
      <Button
        type="button"
        aria-label="Увеличить количество"
        onClick={onIncrease}
        disabled={incDisabled}
      />
    </Container>
  )
}

export default Counter

import React from 'react'
import styled, { css } from 'styled-components'

const sticky = css`
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const Container = styled.div`
  ${({ unstick }) => !unstick && sticky}
  width: 100%;
  margin-top: auto;
  overflow: ${({ isShown }) => !isShown && 'hidden'};
  pointer-events: ${({ isShown }) => !isShown && 'none'};
`

const Footer = styled.footer`
  width: 100%;
  padding: 0.5rem 1rem 1.5rem;
  background-color: var(--main-background-color);
  box-shadow: ${({ unstick }) => !unstick && '0 0 10px var(--shadow-color)'};
  transform: ${({ isShown }) =>
    isShown ? 'translateY(0)' : 'translateY(120%)'};
  transition: transform 0.2s;
`

function FixedFooter({ isShown, unstick, children }) {
  return (
    <Container isShown={isShown} unstick={unstick}>
      <Footer isShown={isShown} unstick={unstick}>
        {children}
      </Footer>
    </Container>
  )
}

export default FixedFooter

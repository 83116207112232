import React from 'react'
import styled, { css } from 'styled-components'

import Tag from '../../shared/Tag'
import DiscountIcon from '../../shared/DiscountIcon'

const unavailable = css`
  opacity: 0.7;
  cursor: not-allowed;
`

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  background-color: var(--main-background-color);
  border-radius: 10px;
  box-shadow: 0 0 20px var(--shadow-color);
  cursor: pointer;
  ${({ available }) => !available && unavailable}
`

const Cover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  height: 160px;
  padding: 1rem;
  font-size: 0.75rem;
  background-image: url("${({ image }) => image}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const RightTopBadge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

const Title = styled.h3`
  margin: 0.75rem 0;
  padding: 0 0.75rem;
  font-family: var(--general-font);
  font-size: 0.9rem;
  font-weight: 400;
  word-break: break-word;
`

const Description = styled.p`
  margin: 0.75rem 0;
  padding: 0 0.75rem;
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  line-height: 125%;
  word-break: break-word;
`

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 1rem;
  align-items: center;
  min-height: 1.5rem;
  margin-top: auto;
  padding: 0.75rem;
  font-size: 0.9rem;
`

const Counter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--inverted-text-color);
  background: var(--main-accent-color);
  border-radius: 50%;
`

const OldPrice = styled.span`
  position: relative;
  margin-right: 0.5rem;
  line-height: 1.5rem;
  color: var(--tertiary-text-color);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--tertiary-text-color);
    transform: translate(-50%, -50%);
  }
`

function ItemCard({ item, active, onClick }) {
  const {
    image,
    title,
    description,
    price,
    finalPrice,
    count,
    out_of_stock,
    discount,
  } = item

  return (
    <Container
      available={!out_of_stock && active}
      onClick={!out_of_stock && active && onClick}
    >
      {!!image && (
        <Cover image={image}>
          {discount > 0 && (
            <RightTopBadge>
              <DiscountIcon />
            </RightTopBadge>
          )}
          {out_of_stock && <Tag text="Нет в наличии" />}
        </Cover>
      )}
      <Title>{title}</Title>
      {description && !image && <Description>{description}</Description>}
      <Footer>
        <span>
          {discount > 0 && <OldPrice>{price}</OldPrice>}
          <span>{finalPrice}&nbsp;₽</span>
        </span>
        {count && active && <Counter>{count}</Counter>}
      </Footer>
    </Container>
  )
}

export default ItemCard

import React from 'react'
import styled from 'styled-components'

const Display = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--main-background-color);
  pointer-events: none;
`

const Container = styled.label`
  position: relative;
  display: flex;
  width: ${({ width }) => width};
  height: auto;

  select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    appearance: none;
    cursor: pointer;
  }

  select:disabled {
    background-color: var(--main-background-color);
    cursor: default;
  }

  option {
    line-height: 32px;
  }
`

function TimeSelect({
  id,
  name,
  options = [],
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  children,
  width = '100%',
}) {
  return (
    <Container width={width}>
      <Display>{children}</Display>
      <select
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      >
        {options.map(({ value, title }) => (
          <option key={`option-${value}`} value={value}>
            {title}
          </option>
        ))}
      </select>
    </Container>
  )
}

export default TimeSelect

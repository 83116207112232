import React from 'react'

function BurgerIcon({ width = 40, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 27a1 1 0 011-1h28a1 1 0 110 2H6a1 1 0 01-1-1zM5 20a1 1 0 011-1h28a1 1 0 110 2H6a1 1 0 01-1-1zM5 13a1 1 0 011-1h28a1 1 0 110 2H6a1 1 0 01-1-1z" />
    </svg>
  )
}

export default BurgerIcon

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  padding: 1.5rem 1rem 4rem;
  font-weight: 700;

  span {
    display: block;
  }
`

function Total({ number }) {
  return (
    <Container>
      <span>Итого</span>
      <span>{number} ₽</span>
    </Container>
  )
}

export default Total

import {
  getISODay,
  format,
  addHours,
  addMinutes,
  startOfDay,
  setHours,
  setMinutes,
  isEqual,
  startOfYesterday,
  startOfToday,
  startOfTomorrow,
  isAfter,
  isWithinInterval,
  formatDistanceStrict,
  getHours,
  getMinutes,
} from 'date-fns'

export const getTimeMessage = threeDaysWorkTime => {
  let now = addHours(new Date(), 0)
  now = addMinutes(now, 0)

  const openedClosedIntervals = threeDaysWorkTime.reduce(
    (acc, { from, to }, index) => {
      acc.push({ from, to, isOpen: true })
      const nextItem = threeDaysWorkTime[index + 1]
      if (nextItem) {
        acc.push({
          from: to,
          to: nextItem.from,
          isOpen: false,
        })
      }
      return acc
    },
    [],
  )

  const currentInterval = openedClosedIntervals.find(({ from, to }) => {
    try {
      return isWithinInterval(now, { start: from, end: to })
    } catch (e) {
      return false
    }
  })

  let message = ''
  const { isOpen } = currentInterval

  if (currentInterval) {
    const { from, to } = currentInterval
    if (
      getHours(from) === getHours(to) &&
      getMinutes(from) === getMinutes(to)
    ) {
      message = 'Открыто'
    } else {
      const minutesLeft = formatDistanceStrict(now, to, {
        unit: 'minute',
      })
      const timeText = format(to, 'H:mm')
      if (isOpen) {
        message = `Открыто до ${timeText}`
        if (minutesLeft < 60) {
          message = `Закроется через ${minutesLeft} мин`
        }
      } else {
        message = `Откроется в ${timeText}`
        if (minutesLeft < 60) {
          message = `Откроется через ${minutesLeft} мин`
        }
      }
    }
  }
  return { message, isOpen }
}

export const getThreeDaysWorkTime = workTimeWeek => {
  const today = startOfToday()
  const yesterday = startOfYesterday()
  const tomorrow = startOfTomorrow()

  const getHoursMinutes = timeText => {
    const [hours, minutes] = timeText.split(':').map(x => Number(x))
    return [+hours, +minutes]
  }

  return [yesterday, today, tomorrow].map(day => {
    const dayOfWeek = getISODay(day) - 1
    return [workTimeWeek[dayOfWeek].from, workTimeWeek[dayOfWeek].to].reduce(
      (res, timeText, i) => {
        const [hours, minutes] = getHoursMinutes(timeText)
        if (i === 1) {
          res.to = setHours(setMinutes(startOfDay(day), minutes), hours)
          if (isAfter(res.from, res.to) || isEqual(res.from, res.to)) {
            res.to = addHours(res.to, 24)
          }
          return res
        }
        res.from = setHours(setMinutes(startOfDay(day), minutes), hours)
        return res
      },
      {},
    )
  })
}

export const getThreeDaysTimeIntervals = (
  threeDaysWorkTime,
  timeIntervals,
  avg_delivery_time,
) =>
  threeDaysWorkTime.reduce((res, { from, to }) => {
    const fromPlusDelivery = addMinutes(from, avg_delivery_time)
    const toPlusDelivery = addMinutes(to, avg_delivery_time)
    const nowPlusDelivery = addMinutes(new Date(), avg_delivery_time)
    return res.concat(
      timeIntervals.filter(({ time, value }) => {
        let timeToCompare = value === -1 ? nowPlusDelivery : time
        try {
          return (
            isWithinInterval(timeToCompare, {
              start: fromPlusDelivery,
              end: toPlusDelivery,
            }) && !isAfter(nowPlusDelivery, timeToCompare)
          )
        } catch (e) {
          return false
        }
      }),
    )
  }, [])

export function getDeliveryTimeMessageValue(
  isOpen,
  deliveryTime,
  selectedTime,
  threeDaysTimeIntervals,
) {
  if (selectedTime.value === -1) {
    if (isOpen) {
      const hours = Math.trunc(deliveryTime / 60)
      const minutes = deliveryTime % 60
      const hoursString = hours !== 0 ? `${hours} ч ` : ''
      const minutesString = minutes !== 0 ? `${minutes} мин` : ''
      return {
        deliveryToMessage: `~${hoursString}${minutesString}`,
        deliveryToValue: -1,
      }
    }
    const { title, value } = threeDaysTimeIntervals[0] || {
      title: '',
      value: 0,
    }
    return { deliveryToMessage: title, deliveryToValue: value }
  }
  const { title, value } = threeDaysTimeIntervals.find(
    ({ value }) => value >= +selectedTime.value,
  ) ||
    threeDaysTimeIntervals[threeDaysTimeIntervals.length - 1] || {
      title: '',
      value: 0,
    }
  return { deliveryToMessage: title, deliveryToValue: value }
}

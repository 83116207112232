import React from 'react'
import styled from 'styled-components'

import RatingIcon from '../RatingIcon'

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 32px);
  grid-gap: 1rem;
  align-items: center;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    appearance: none;
  }

  svg {
    fill: ${({ isSelected }) =>
      isSelected ? 'var(--rating-star-color)' : 'var(--border-color)'};
  }
`

function RatingRadioButton({
  inputName = 'rating_score',
  currentValue,
  onChange,
  value,
}) {
  return (
    <Container isSelected={currentValue >= value}>
      <input
        type="radio"
        name={inputName}
        checked={currentValue === value}
        onChange={() => onChange(value)}
        value={value}
      />
      <RatingIcon width={32} height={32} />
    </Container>
  )
}

export default RatingRadioButton

import React from 'react'

function CrossIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x=".222"
        y="14.364"
        width="20"
        height="2"
        rx="1"
        transform="rotate(-45 .222 14.364)"
      />
      <rect
        x="1.636"
        y=".222"
        width="20"
        height="2"
        rx="1"
        transform="rotate(45 1.636 .222)"
      />
    </svg>
  )
}

export default CrossIcon

import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import StickyHeader from '../shared/StickyHeader'
import MenuButton from './Header/MenuButton'
import AddressButton from './Header/AddressButton'
import TimeIcon from '../shared/TimeIcon'
import TimeSelect from '../shared/TimeSelect'
import AddressModal from '../shared/AddressModal'
import {
  $timeIntervals,
  $selectedTime,
  timeSelected,
} from '../../store/timeIntervals'
import { $currentAddress } from '../../store/addresses'

const StyledHeader = styled(StickyHeader)`
  grid-template-columns: 72px 1fr 72px;
  grid-column-gap: 0;
`

const TimeIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 24px;
    height: 24px;
    background-color: ${({ dirty }) =>
      dirty ? 'var(--main-accent-color)' : 'var(--main-background-color)'};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.2s;
  }

  svg {
    z-index: 1;
  }
`

function Header({ onMenuClick, badge }) {
  const options = useStore($timeIntervals)
  const { value } = useStore($selectedTime)
  const currentAddress = useStore($currentAddress)
  const [addressSelect, setAddressSelect] = useState(false)

  const handleTimeSelectChange = e => {
    timeSelected(+e.target.value)
  }
  return (
    <StyledHeader>
      <MenuButton onClick={onMenuClick} />
      <AddressButton
        text={(currentAddress && currentAddress.line) || 'Укажите адрес'}
        onClick={() => setAddressSelect(true)}
      />
      <TimeSelect
        options={options}
        value={value}
        onChange={handleTimeSelectChange}
      >
        <TimeIconContainer dirty={value !== -1}>
          <TimeIcon width={24} height={24} />
        </TimeIconContainer>
      </TimeSelect>
      {addressSelect && (
        <AddressModal
          isShown={true}
          closeHandler={() => setAddressSelect(false)}
        />
      )}
    </StyledHeader>
  )
}

export default Header

import { css, keyframes } from 'styled-components'

const fadeOutAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
`

export const fadeOut = css`
  animation-name: ${fadeOutAnimation};
  animation-duration: 0.2s;
  animation-iteration-count: 1;
`

export const textOverflow = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const listReset = css`
  margin: 0;
  padding: 0;
  list-style: none;
`

const glowingAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.2);
  }

  10% {
    transform: scale(1.1);
  }

  15% {
    transform: scale(1.2);
  }

  20% {
    transform: scale(1);
  }
`

export const badgeElement = css`
  &::after {
    content: '';
    position: absolute;
    top: 0.825rem;
    left: 1rem;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    background: var(--danger-color);
    border: 3px solid var(--main-background-color);
    border-radius: 50%;
    animation-name: ${glowingAnimation};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
`

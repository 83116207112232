import axios from 'axios'
import {
  createEffect,
  createEvent,
  combine,
  sample,
  createStore,
} from 'effector'
import {
  getTimeMessage,
  getThreeDaysWorkTime,
  getThreeDaysTimeIntervals,
  getDeliveryTimeMessageValue,
} from '../utils/workTime'
import { differenceInDays } from 'date-fns'
import {
  $restaurants,
  $currentRestaurantId,
  $restaurantItems,
  $cart,
  $token,
  takeawayAddressSetted,
} from './index'
import {
  $timeIntervals,
  $selectedTime,
  initTimeIntervals,
} from './timeIntervals'
import { checkSession } from './auth'
import { navigate } from '@reach/router'

export const loadRestaurants = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/shops', {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const loadItemsEffect = createEffect({
  handler: async restaurantId => {
    const res = await axios.get(`/api/customer/shops/${restaurantId}/`)
    return res.data.data
  },
})

export const openRestaurantEvent = createEvent('open restaurant event')
export const exitRestaurantEvent = createEvent('exit restaurant event')

$restaurants.on(loadRestaurants.done, (_, { result }) => result)

$currentRestaurantId
  .on(openRestaurantEvent, (_, restaurantId) => Number(restaurantId))
  .reset(exitRestaurantEvent)

$currentRestaurantId.updates.watch(initTimeIntervals)

$restaurantItems
  .on(loadItemsEffect.done, (_, { result }) => result)
  .reset(exitRestaurantEvent)

export const $enreachedRestaurantItems = combine(
  $restaurantItems,
  $cart,
  (items, cart) => {
    const cartDict = cart.reduce((acc, item) => {
      acc[item.id] = acc[item.id] ? acc[item.id] + item.count : item.count
      return acc
    }, {})

    const discountedItems = items.reduce((acc, group) => {
      const items = group.items.filter(({ discount }) => discount > 0)
      acc = [...acc, ...items]
      return acc
    }, [])

    const discountedGroup = discountedItems.length > 0 && {
      id: 0,
      title: 'Блюда со скидкой',
      position: -1,
      items: discountedItems,
    }

    const enreachedItems = discountedGroup ? [discountedGroup, ...items] : items

    return enreachedItems.map(group => {
      const groupTitle = group.title
      const newItems = group.items.map(item => {
        const count = cartDict[item.id]
        const queryString = `${item.title} ${item.description} ${groupTitle}`.toLowerCase()
        return { ...item, count, queryString }
      })

      return { ...group, items: newItems }
    })
  },
)

export const $enreachedRestaurants = combine(
  $restaurants,
  $timeIntervals,
  $selectedTime,
  (restaurants, timeIntervals, selectedTime) => {
    const restaurantsArray = restaurants.map(restaurant => {
      const { work_time, avg_delivery_time, launchedAt } = restaurant
      const threeDaysWorkTime = getThreeDaysWorkTime(work_time)
      const { message: workTimeMessage, isOpen } = getTimeMessage(
        threeDaysWorkTime,
      )
      const threeDaysTimeIntervals = getThreeDaysTimeIntervals(
        threeDaysWorkTime,
        timeIntervals,
        avg_delivery_time,
      )
      const {
        deliveryToMessage,
        deliveryToValue,
      } = getDeliveryTimeMessageValue(
        isOpen,
        avg_delivery_time,
        selectedTime,
        threeDaysTimeIntervals,
      )
      const justOpened =
        differenceInDays(new Date(), new Date(launchedAt)) <= 15

      return {
        ...restaurant,
        workTimeMessage,
        deliveryToMessage,
        deliveryToValue,
        threeDaysWorkTime,
        threeDaysTimeIntervals,
        isOpen,
        justOpened,
      }
    })

    const newRestaurants = restaurantsArray.filter(
      ({ justOpened, isOpen, available }) => justOpened && isOpen && available,
    )
    const openRestaurants = restaurantsArray.filter(
      ({ justOpened, isOpen, available }) => !justOpened && isOpen && available,
    )
    const closedRestaurants = restaurantsArray.filter(
      ({ isOpen, available }) => !isOpen && available,
    )
    const unavailableRestaurants = restaurantsArray.filter(
      ({ isOpen, available }) => isOpen && !available,
    )

    function sortByRating(arr) {
      const recents = arr
        .filter(({ recent }) => recent)
        .sort((a, b) => Number(b.rating_score) - Number(a.rating_score))
      const rest = arr
        .filter(({ recent }) => !recent)
        .sort((a, b) => Number(b.rating_score) - Number(a.rating_score))
      return [...recents, ...rest]
    }

    return [
      ...sortByRating(newRestaurants),
      ...sortByRating(openRestaurants),
      ...sortByRating(closedRestaurants),
      ...sortByRating(unavailableRestaurants),
    ]
  },
)

export const $currentRestaurant = createStore({})

sample({
  source: combine({
    list: $enreachedRestaurants,
    id: $currentRestaurantId,
  }),
  fn: ({ list, id }) => {
    const restaurant = list.find(item => item.id === id)

    if (restaurant) {
      const { deliveryPrices, shopAddresses } = restaurant
      const deliveryFees = Object.keys(deliveryPrices).reduce(
        (acc, rangeKey) => {
          const price = Object.keys(deliveryPrices[rangeKey]).map(
            priceKey => deliveryPrices[rangeKey][priceKey],
          )
          return [...acc, ...price]
        },
        [],
      )
      const deliveryFeeRangeMessage = `Доставка ${Math.min(
        ...deliveryFees,
      )}-${Math.max(...deliveryFees)} ₽`

      const firstAddress = shopAddresses[0].address
      takeawayAddressSetted(firstAddress)

      return { ...restaurant, deliveryFeeRangeMessage }
    }

    if (id && !restaurant && list.length > 0) {
      navigate('/')
    }

    return {}
  },
  target: $currentRestaurant,
})

openRestaurantEvent.watch(restaurantId => loadItemsEffect(restaurantId))

export const searchQueryChanged = createEvent('search query changed')
export const searchQueryReseted = createEvent('search query reseted')
export const $searchQuery = createStore('')
  .on(searchQueryChanged, (_, value) => value.toLowerCase())
  .reset([searchQueryReseted, $currentRestaurant])
export const $filteredItems = createStore([])

sample({
  source: combine({
    items: $enreachedRestaurantItems,
    query: $searchQuery,
  }),
  fn: ({ items, query }) => {
    if (query) {
      const filteredItems = items.reduce((acc, group) => {
        const filteredGroupItems = group.items.filter(({ queryString }) =>
          queryString.includes(query),
        )

        if (filteredGroupItems.length > 0)
          acc = [...acc, { ...group, items: filteredGroupItems }]

        return acc
      }, [])

      return filteredItems
    } else {
      return items
    }
  },
  target: $filteredItems,
})

$token.updates.watch(loadRestaurants)
checkSession.fail.watch(loadRestaurants)

import React from 'react'

const InstagramIcon = () => (
  <svg viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.81 9.408c-.075-1.7-.35-2.87-.744-3.882a7.809 7.809 0 00-1.85-2.838A7.876 7.876 0 0027.384.844C26.365.45 25.203.175 23.502.1 21.79.02 21.245 0 16.901 0c-4.345 0-4.889.019-6.595.094-1.7.075-2.87.35-3.882.744a7.808 7.808 0 00-2.838 1.85A7.879 7.879 0 001.742 5.52C1.348 6.539 1.072 7.7.998 9.402c-.082 1.713-.1 2.257-.1 6.601 0 4.345.018 4.889.093 6.595.075 1.7.35 2.87.744 3.882a7.89 7.89 0 001.85 2.838c.8.813 1.77 1.444 2.833 1.844 1.019.394 2.181.67 3.882.744 1.706.075 2.25.094 6.595.094 4.344 0 4.888-.019 6.595-.094 1.7-.075 2.869-.35 3.882-.744a8.185 8.185 0 004.682-4.682c.394-1.019.669-2.181.744-3.882.075-1.706.093-2.25.093-6.595 0-4.344-.006-4.888-.08-6.595zm-2.882 13.065c-.068 1.563-.33 2.407-.55 2.97a5.308 5.308 0 01-3.038 3.038c-.562.218-1.412.481-2.97.55-1.687.075-2.193.093-6.463.093s-4.782-.018-6.464-.093c-1.563-.069-2.406-.331-2.97-.55a4.925 4.925 0 01-1.837-1.194 4.976 4.976 0 01-1.194-1.838c-.219-.563-.481-1.413-.55-2.97-.075-1.687-.094-2.194-.094-6.463 0-4.27.019-4.783.094-6.464.069-1.563.331-2.407.55-2.97a4.864 4.864 0 011.2-1.837A4.968 4.968 0 017.48 3.55c.563-.219 1.413-.482 2.97-.55 1.687-.075 2.194-.094 6.463-.094 4.276 0 4.782.019 6.464.094 1.563.068 2.407.331 2.97.55a4.92 4.92 0 011.837 1.194 4.974 4.974 0 011.194 1.838c.22.562.482 1.412.55 2.969.075 1.688.094 2.194.094 6.464 0 4.27-.018 4.77-.093 6.457z" />
    <path d="M16.9 7.783a8.222 8.222 0 00-8.22 8.22 8.222 8.222 0 008.22 8.22 8.222 8.222 0 008.221-8.22 8.222 8.222 0 00-8.22-8.22zm0 13.553a5.333 5.333 0 11.003-10.667 5.333 5.333 0 01-.002 10.667zM27.366 7.458a1.92 1.92 0 11-3.84 0 1.92 1.92 0 013.84 0z" />
  </svg>
)

export default InstagramIcon

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import { Redirect, Link, navigate } from '@reach/router'
import '../third-party/widgetStyles.css'

import Layout from './shared/Layout'
import CreditCardIcon from './Checkout/CreditCardIcon'
import PhoneIcon from './Checkout/PhoneIcon'
import TerminalIcon from './Checkout/TerminalIcon'
import CashIcon from './Checkout/CashIcon'
import Header from './Checkout/Header'
import Input from './shared/Input'
import TextArea from './shared/TextArea'
import TimeSelect from './shared/TimeSelect'
import PaymentMethodSelect from './Checkout/PaymentMethodSelect'
import FixedFooter from './shared/FixedFooter'
import FlowButton from './shared/FlowButton'
import InfoModal from './shared/InfoModal'
import DialogModal from './shared/DialogModal'
import Button from './shared/Button'
// import Checkbox from './shared/Checkbox'

import {
  $orderPaymentType,
  $allPaymentTypes,
  $isOrderPending,
  $isOrderFailed,
  $token,
  $takeawayMode,
  // $bonuses,
} from '../store'
import { updateBonusesFx } from '../store/auth'
import { $currentRestaurant } from '../store/restaurants'
import { $selectedTime, timeSelected } from '../store/timeIntervals'
import { onlyDigitsRegex } from '../utils/constants'
import {
  onOrderConfirmClick,
  changeOrderChange,
  changeOrderPaymentType,
  failReset,
  $checkoutTimeWarning,
  closeCheckoutTimeWarning,
  // $promoCodeValue,
  // $confirmedPromoCode,
  // setPromoCodeValue,
  // $isPromoModalShown,
  // setPromoModalShown,
  // $promoErrorText,
  // checkPromoFx,
  $finalCheckPrice,
  // resetPromoCode,
  // $bonusMode,
  // bonusModeToggled,
} from '../store/order'
import { $currentAddress, changeCurrentAddress } from '../store/addresses'
import { $cartTotalPrice } from '../store/cart'
import { initTimeIntervals } from '../store/timeIntervals'
// import CrossIcon from './shared/CrossIcon'
import TakeawayAddressSelect from './shared/TakeawayAddressSelect'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
`

const FormSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 0 1rem;

  h2 {
    margin: 0;
    font-size: 1.25rem;
  }

  & > .full-width {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @media (max-width: 340px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const TimeSelectLabel = styled.div`
  display: grid;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1rem;
  background-color: var(--main-background-color);
  border-bottom: 1px solid var(--border-color);

  span:first-child {
    color: var(--secondary-text-color);
    font-size: 0.75rem;
  }
`

const Error = styled.strong`
  min-height: 1rem;
  color: var(--danger-color);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
`

const Notification = styled.span`
  display: block;
  min-height: 1rem;
  margin-bottom: 0.5rem;
  color: ${({ isShown }) => (isShown ? 'var(--danger-color)' : 'transparent')};
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  transition: color 0.2s;
`

const TimeWarning = styled.div`
  padding: 1rem;

  p {
    margin: 0 0 1rem;
    color: var(--secondary-text-color);
    text-align: center;
  }

  footer {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem 0;
  }
`

// const PromoButton = styled.button`
//   position: relative;
//   appearance: none;
//   display: flex;
//   margin: 0 1rem 1rem;
//   padding: 0.75rem 0 1.25rem;
//   color: var(--secondary-text-color);
//   font-size: 1rem;
//   text-align: left;
//   background: var(--main-background-color);
//   border: 0;
//   border-bottom: 1px solid var(--border-color);
//   border-bottom-color: ${({ confirmed }) =>
//     confirmed && 'var(--main-accent-color)'};
//   cursor: pointer;

//   & > span:first-child {
//     margin-right: auto;
//   }

//   &:not(:disabled) {
//     color: ${({ confirmed }) =>
//       confirmed ? 'var(--main-accent-color)' : 'var(--text-color)'};
//   }

//   &:disabled {
//     cursor: not-allowed;
//   }

//   &:disabled::after {
//     content: 'Доступен при оплате картой онлайн';
//     position: absolute;
//     bottom: 0.25rem;
//     left: 0;
//     font-size: 0.8rem;
//   }
// `

// const PromoContainer = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 2.5rem;
// `

// const ResetPromoButton = styled.button`
//   appearance: none;
//   display: block;
//   width: 2.5rem;
//   margin: 0;
//   padding: 0;
//   background: none;
//   border: none;
//   cursor: pointer;
// `

// const BonusesLabel = styled.span`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-items: center;

//   & > *:not(:last-child) {
//     margin-right: 1rem;
//   }

//   i {
//     color: var(--secondary-text-color);
//     font-style: normal;
//   }
// `

function Checkout({ mobileApp }) {
  const [addressInfo, setAddressInfo] = useState(false)
  const { register, handleSubmit, errors } = useForm()
  const token = useStore($token)
  const currentAddress = useStore($currentAddress)
  const orderPaymentType = useStore($orderPaymentType)
  const allPaymentTypes = useStore($allPaymentTypes)
  const currentRestaurant = useStore($currentRestaurant)
  const cartTotalPrice = useStore($cartTotalPrice)
  const finalCheckPrice = useStore($finalCheckPrice)
  const isOrderPending = useStore($isOrderPending)
  const isOrderFailed = useStore($isOrderFailed)
  const { value } = useStore($selectedTime)
  const checkoutTimeWarning = useStore($checkoutTimeWarning)
  // const promoCodeValue = useStore($promoCodeValue)
  // const confirmedPromoCode = useStore($confirmedPromoCode)
  // const isPromoModalShown = useStore($isPromoModalShown)
  // const isPromoPending = useStore(checkPromoFx.pending)
  // const promoErrorText = useStore($promoErrorText)
  const takeawayMode = useStore($takeawayMode)
  // const bonuses = useStore($bonuses)
  // const bonusMode = useStore($bonusMode)
  const minOrderCost = currentRestaurant && +currentRestaurant.min_order_cost
  const isNotMinOrderCost =
    currentRestaurant &&
    currentRestaurant.delivery_type === 1 &&
    cartTotalPrice < minOrderCost

  function submitHandler() {
    if (token) {
      initTimeIntervals()
      onOrderConfirmClick()
    }
  }

  const handleTimeSelectChange = e => {
    timeSelected(+e.target.value)
  }

  const paymentMethods = {
    terminal: <TerminalIcon />,
    mobile: <PhoneIcon />,
    cash: <CashIcon />,
    online: <CreditCardIcon />,
  }

  if (
    Object.keys(currentRestaurant).length > 0 &&
    !currentRestaurant.payment_type.includes(orderPaymentType)
  ) {
    changeOrderPaymentType(currentRestaurant.payment_type[0])
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    updateBonusesFx()
    return () => {
      failReset()
    }
  }, [currentRestaurant])

  return Object.keys(currentRestaurant).length > 0 ? (
    <Layout>
      <Header />
      <Form onSubmit={handleSubmit(submitHandler)}>
        <FormSection>
          <h2 className="full-width">
            {takeawayMode ? 'Самовывоз по адресу' : 'Адрес доставки'}
          </h2>
          {takeawayMode ? (
            <TakeawayAddressSelect className="full-width" />
          ) : (
            <>
              <div className="full-width">
                <div onClick={() => setAddressInfo(true)}>
                  <Input
                    label="Адрес доставки"
                    type="text"
                    value={currentAddress.line}
                    disabled
                  />
                </div>
              </div>
              <Input
                name="office"
                label="Кв./офис"
                maxLength={5}
                value={currentAddress.office}
                onChange={value =>
                  changeCurrentAddress({ key: 'office', value })
                }
                inputRef={register()}
              />
              <Input
                name="doorcode"
                id="doorcode"
                label="Домофон"
                maxLength={20}
                value={currentAddress.doorcode}
                onChange={value =>
                  changeCurrentAddress({ key: 'doorcode', value })
                }
                inputRef={register()}
              />
              <div>
                <Input
                  name="entrance"
                  id="entrance"
                  label="Подъезд"
                  type="tel"
                  maxLength={20}
                  value={currentAddress.entrance}
                  onChange={value =>
                    changeCurrentAddress({ key: 'entrance', value })
                  }
                  inputRef={register({
                    validate: entrance =>
                      entrance.match(onlyDigitsRegex) ||
                      'Введите номер подъезда',
                  })}
                />
                <Error>{errors.entrance && errors.entrance.message}</Error>
              </div>
              <div>
                <Input
                  name="floor"
                  id="floor"
                  label="Этаж"
                  type="tel"
                  maxLength={5}
                  value={currentAddress.floor}
                  onChange={value =>
                    changeCurrentAddress({ key: 'floor', value })
                  }
                  inputRef={register({
                    validate: floor =>
                      floor.match(onlyDigitsRegex) || 'Введите номер этажа',
                  })}
                />
                <Error>{errors.floor && errors.floor.message}</Error>
              </div>
            </>
          )}
          <div className="full-width">
            <TextArea
              inputRef={register()}
              name="comment"
              id="comment"
              placeholder="Комментарий к заказу"
              value={currentAddress ? currentAddress.comment : ''}
              onChange={value =>
                changeCurrentAddress({ key: 'comment', value })
              }
            />
          </div>
          <div className="full-width">
            <TimeSelect
              name="deliveryTime"
              id="delivery-time"
              value={value}
              options={currentRestaurant.threeDaysTimeIntervals}
              onChange={handleTimeSelectChange}
            >
              <TimeSelectLabel>
                <span>Время доставки</span>
                <span>{currentRestaurant.deliveryToMessage}</span>
              </TimeSelectLabel>
            </TimeSelect>
          </div>
        </FormSection>
        <FormSection>
          <h2 className="full-width">Способ оплаты</h2>
          {/* {currentRestaurant.payment_type.includes(1) && (
            <PromoContainer className="full-width">
              <PromoButton
                type="button"
                onClick={() => setPromoModalShown(true)}
                confirmed={confirmedPromoCode.code}
                disabled={orderPaymentType !== 1}
              >
                <span>
                  Промокод
                  {confirmedPromoCode.code &&
                    orderPaymentType === 1 &&
                    `: ${confirmedPromoCode.code.toUpperCase()} (${
                      confirmedPromoCode.discount &&
                      orderPaymentType === 1 &&
                      `-${confirmedPromoCode.discount} ₽`
                    })`}
                </span>
              </PromoButton>
              {confirmedPromoCode.code && (
                <ResetPromoButton type="button" onClick={resetPromoCode}>
                  <CrossIcon />
                </ResetPromoButton>
              )}
            </PromoContainer>
          )} */}
          {/* <div className="full-width" style={{ padding: '1rem' }}>
            <Checkbox
              label={
                <BonusesLabel>
                  <span>Использовать бонусы</span>
                  <i>({bonuses})</i>
                </BonusesLabel>
              }
              inputName="bonuses"
              checked={bonusMode}
              onChange={bonusModeToggled}
              disabled={bonuses < 1 || orderPaymentType !== 1}
            />
          </div> */}
          {allPaymentTypes
            .filter(({ id }) => currentRestaurant.payment_type.includes(id))
            .map(item => {
              const { caption, id, name: typeName } = item
              return (
                <div className="full-width" key={`payment-method-${id}`}>
                  <PaymentMethodSelect
                    title={caption}
                    value={id}
                    icon={paymentMethods[typeName]}
                    isSelected={id === orderPaymentType}
                    inputName="paymentMethod"
                    onChange={changeOrderPaymentType}
                    inputRef={register({ required: 'Выберите способ оплаты' })}
                  >
                    {typeName === 'cash' && (
                      <Input
                        name="cashChange"
                        id="cashChange"
                        label="Сдача с суммы"
                        type="tel"
                        autoComplete="off"
                        onChange={changeOrderChange}
                        inputRef={register({
                          min: {
                            value: 0,
                            message: 'С какой суммы менять ?',
                          },
                        })}
                      />
                    )}
                  </PaymentMethodSelect>
                  <Error>
                    {errors.paymentMethod && errors.paymentMethod.message}
                  </Error>
                </div>
              )
            })}
        </FormSection>
        <FixedFooter isShown={true} unstick>
          <Notification isShown={true}>{isOrderFailed}</Notification>
          <FlowButton
            disabled={isNotMinOrderCost}
            loading={isOrderPending}
            type="submit"
            text={
              isOrderPending
                ? 'Отправка...'
                : isNotMinOrderCost
                ? `Мин. сумма заказа ${minOrderCost} ₽`
                : 'Оформить заказ'
            }
            right={`${finalCheckPrice} ₽`}
          />
        </FixedFooter>
      </Form>
      {/* <InfoModal
        isShown={isPromoModalShown}
        onCloseClick={() => setPromoModalShown(false)}
        title="Промокод"
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            checkPromoFx({ code: promoCodeValue, shopId: currentRestaurant.id })
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem 1rem 2rem',
            }}
          >
            <Input
              name="promo"
              id="promo"
              label="Введите промокод"
              type="text"
              autoComplete="off"
              value={promoCodeValue}
              onChange={setPromoCodeValue}
              disabled={isPromoPending}
            />
            <Error>{promoErrorText}</Error>
            <Button
              type="submit"
              style={{ marginTop: '1rem' }}
              disabled={isPromoPending || promoCodeValue === ''}
            >
              Активировать
            </Button>
          </div>
        </form>
      </InfoModal> */}

      <InfoModal
        title="Доставка недоступна"
        isShown={checkoutTimeWarning}
        onCloseClick={closeCheckoutTimeWarning}
      >
        <TimeWarning>
          <p>
            К&nbsp;сожалению, доставка к&nbsp;указанному времени недоступна.
            Ближайшее время&nbsp;&mdash; {currentRestaurant.deliveryToMessage}
          </p>
          {currentRestaurant && (
            <footer>
              <Button
                type="button"
                onClick={() => {
                  timeSelected(currentRestaurant.deliveryToValue)
                  closeCheckoutTimeWarning()
                }}
              >
                Доставить в ближайшее время
              </Button>
              <Button as={Link} to="/" type="button" skeletal="true">
                Посмотреть доступные места
              </Button>
            </footer>
          )}
        </TimeWarning>
      </InfoModal>
      <DialogModal
        isShown={addressInfo}
        dialog="Адрес доставки можно изменить на главной странице"
        okText="На главную"
        cancelText="Отмена"
        onOk={() => navigate('/')}
        onCancel={() => setAddressInfo(false)}
      ></DialogModal>
    </Layout>
  ) : (
    <Redirect to="/" noThrow />
  )
}

export default Checkout

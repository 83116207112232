import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { $takeawayAddress, takeawayAddressSetted } from '../../store'
import { $currentRestaurant } from '../../store/restaurants'

const Container = styled.div`
  position: relative;
  border-bottom: 1px solid var(--border-color);
`

const Cover = styled.span`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'text discount' 'text discount' 'note note';
  align-items: end;
  padding-bottom: 0.5rem;
  line-height: 1.25rem;
  background: var(--main-background-color);
  pointer-events: none;

  & > span:first-child {
    grid-area: text;
  }

  & > span:nth-child(2) {
    grid-area: discount;
  }

  strong {
    color: var(--main-accent-color);
  }
`

const Select = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
`

const Note = styled.small`
  position: relative;
  grid-area: note;
  color: var(--secondary-text-color);
  font-size: 0.8rem;
`

function TakeawayAddressSelect({
  showCaption,
  showDiscount,
  className,
  style,
}) {
  const takeawayAddress = useStore($takeawayAddress)
  const { shopAddresses, takeawayDiscount } = useStore($currentRestaurant)

  return shopAddresses ? (
    <Container className={className}>
      <Cover style={style}>
        <span>
          {showCaption && (
            <>
              Самовывоз по адресу:
              <br />
            </>
          )}
          <strong>{takeawayAddress}</strong>
        </span>
        {showDiscount && (
          <span>
            {takeawayDiscount > 0 && <strong>-{takeawayDiscount}%</strong>}
          </span>
        )}
        {shopAddresses.length > 1 && (
          <Note>Нажмите, чтобы выбрать другое место</Note>
        )}
      </Cover>
      {
        <Select
          onChange={e => takeawayAddressSetted(e.target.value)}
          disabled={shopAddresses.length < 2}
        >
          {shopAddresses.map(({ id, address }) => {
            return <option key={id}>{address}</option>
          })}
        </Select>
      }
    </Container>
  ) : (
    ''
  )
}

export default TakeawayAddressSelect

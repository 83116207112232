import React from 'react'
import styled from 'styled-components'

import StickyHeader from '../shared/StickyHeader'
import GoBackButton from '../shared/GoBackButton'
import { textOverflow } from '../shared/styleMixins'

const StyledHeader = styled(StickyHeader)`
  position: relative;
  box-shadow: none;
`

const Title = styled.div`
  ${textOverflow}
  margin: 0 auto;
`

function Header() {
  return (
    <StyledHeader>
      <GoBackButton backPath="/cart" />
      <Title>
        <strong>Оформление заказа</strong>
      </Title>
    </StyledHeader>
  )
}

export default Header

import React from 'react'

function RatingIcon({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 511 511"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M509.668 196.017a27.105 27.105 0 00-23.38-18.674L338.799 163.95 280.478 27.444c-4.301-10.004-14.095-16.478-24.975-16.478-10.881 0-20.676 6.475-24.975 16.502l-58.322 136.483-147.512 13.392a27.16 27.16 0 00-23.357 18.674c-3.364 10.348-.257 21.697 7.942 28.851l111.485 97.771L87.889 467.45c-2.405 10.647 1.727 21.654 10.562 28.039 4.748 3.432 10.304 5.178 15.906 5.178 4.831 0 9.622-1.302 13.923-3.875l127.223-76.037 127.176 76.037c9.306 5.599 21.037 5.087 29.852-1.303a27.17 27.17 0 0010.562-28.039l-32.874-144.811 111.484-97.751a27.174 27.174 0 007.965-28.871z" />
    </svg>
  )
}

export default RatingIcon

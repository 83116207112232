import React from 'react'
import styled from 'styled-components'

import StickyHeader from '../shared/StickyHeader'
import GoBackButton from '../shared/GoBackButton'
import { textOverflow } from '../shared/styleMixins'

const Title = styled.div`
  ${textOverflow}
  margin: 0 auto;
`

function Header() {
  return (
    <StickyHeader>
      <GoBackButton backPath="/" />
      <Title>
        <strong>Пользовательское соглашение</strong>
      </Title>
    </StickyHeader>
  )
}

export default Header

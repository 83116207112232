import React from 'react'
import styled, { css } from 'styled-components'
import throttle from 'lodash.throttle'
import { useStore } from 'effector-react'

import { $takeawayMode, takeawayModeToggled } from '../../store'

const Container = styled.div`
  position: relative;
  display: flex;
  background: var(--alt-background-color);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    display: block;
    width: calc(50% + 2px);
    height: calc(100% + 2px);
    background: var(--main-background-color);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.25);
    left: ${({ toggled }) => (toggled ? '50%' : '-1px')};
    transition: left 0.2s;
  }
`

const selectedStyle = css`
  color: var(--text-color);
`

const ModeButton = styled.button`
  position: relative;
  appearance: none;
  display: block;
  width: 50%;
  padding: 0.25rem 0.5rem;
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  ${({ selected }) => selected && selectedStyle};
`

function DeliveryMode() {
  const takeawayMode = useStore($takeawayMode)

  return (
    <Container toggled={takeawayMode}>
      <ModeButton
        selected={!takeawayMode}
        onClick={throttle(() => takeawayModeToggled(), 200)}
      >
        Доставка
      </ModeButton>
      <ModeButton
        selected={takeawayMode}
        onClick={throttle(() => takeawayModeToggled(), 200)}
      >
        Самовывоз
      </ModeButton>
    </Container>
  )
}

export default DeliveryMode

import React from 'react'

function ProfileIcon({ width = 30, height = 30 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 .452 0 .902-.02 1.35-.06.417-.038.83-.095 1.24-.167l.157-.027c7.616-1.422 12.897-8.41 12.185-16.125C29.219 5.906 22.748.002 15 0zm-1.205 28.947h-.036a13.832 13.832 0 01-1.157-.153l-.065-.01c-.374-.067-.745-.15-1.111-.247l-.089-.023c-.357-.097-.71-.21-1.059-.335l-.109-.037a13.744 13.744 0 01-1.007-.419c-.04-.018-.082-.035-.123-.055-.324-.15-.642-.319-.953-.497l-.132-.074c-.307-.179-.607-.37-.9-.574-.045-.03-.09-.06-.134-.092-.289-.204-.57-.422-.845-.65L6 25.716V21.5a5.507 5.507 0 015.5-5.5h7a5.507 5.507 0 015.5 5.5v4.216l-.066.055c-.28.234-.567.455-.861.663-.04.027-.08.053-.118.08-.297.207-.602.401-.914.584-.039.023-.08.044-.118.067-.316.18-.638.35-.965.503a3.257 3.257 0 01-.114.05c-.333.153-.671.294-1.015.42l-.1.037a14.1 14.1 0 01-1.065.336l-.086.021c-.367.097-.737.18-1.112.248l-.064.01c-.383.067-.77.119-1.16.154h-.035a14.026 14.026 0 01-2.412.003zM25 24.789V21.5a6.508 6.508 0 00-6.5-6.5h-7A6.508 6.508 0 005 21.5v3.289C-.385 19.292-.325 10.48 5.134 5.057c5.46-5.422 14.272-5.422 19.732 0 5.46 5.423 5.52 14.235.134 19.732z" />
      <path d="M15 4a5 5 0 100 10 5 5 0 000-10zm0 9a4 4 0 110-8 4 4 0 010 8z" />
    </svg>
  )
}

export default ProfileIcon

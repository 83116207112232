import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
// import scrollIntoView from 'scroll-into-view'
import { useStore } from 'effector-react'

// import MenuNav, { Anchor } from './RestaurantMenu/MenuNav'
import ItemCard from './RestaurantMenu/ItemCard'
import InfoModal from '../shared/InfoModal'
import Button from '../shared/Button'
import AddressModal from '../shared/AddressModal'

// import { $restaurantItems } from '../../store'
import {
  $currentRestaurant,
  $filteredItems,
  $searchQuery,
  searchQueryChanged,
  searchQueryReseted,
} from '../../store/restaurants'
import { itemClick, $callback } from '../../store/cart'
import { timeSelected } from '../../store/timeIntervals'

const Container = styled.div`
  width: 100%;
`

const List = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  padding-bottom: 2rem;
`

const GroupTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding-top: 1rem;

  & > ${GroupTitle} {
    grid-column-start: 1;
    grid-column-end: -1;
  }
`

const TimeWarning = styled.div`
  padding: 1rem;

  p {
    margin: 0 0 1rem;
    color: var(--secondary-text-color);
    text-align: center;
  }

  footer {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem 0;
  }
`

const Search = styled.div`
  position: sticky;
  top: 55px;
  left: 0;
  z-index: 1;
  padding: 0.5rem 1rem 1rem;
  background-color: var(--main-background-color);
  box-shadow: 0 5px 10px -5px var(--shadow-color);
`

const SearchFormContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
`

const SearchInput = styled.input`
  display: block;
  margin: 0;
  padding: 0.75rem;
  padding-right: 3rem;
  font: inherit;
  background-color: var(--alt-background-color);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  appearance: none;

  &:focus {
    outline: none;
    border-color: var(--main-accent-color);
  }

  &::placeholder {
    color: var(--secondary-text-color);
    opacity: 1;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    border-color: var(--alt-background-color);
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    opacity: 0.5;
  }
`

const SearchResetButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 100%;
  margin: 0;
  padding: 0;
  color: var(--main-background-color);
  font: inherit;
  background: none;
  border: 0;
  cursor: pointer;

  &::before {
    content: '✕';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    color: var(--main-background-color);
    line-height: 100%;
    background: var(--main-accent-color);
    border-radius: 50%;
  }

  &:disabled::before {
    display: none;
  }
`

function RestaurantMenu({
  active,
  timeWarning,
  setTimeWarning,
  addressWarning,
  setAddressWarning,
  addressSelect,
  setAddressSelect,
  infoBlock,
}) {
  // const navNode = useRef()

  const currentRestaurant = useStore($currentRestaurant)
  const filteredItems = useStore($filteredItems)
  const searchQuery = useStore($searchQuery)
  const callback = useStore($callback)

  // function onAnchorClick(e) {
  //   e.preventDefault()

  //   const target = e.target
  //   const hash = target.hash
  //   const address = document.querySelector(hash)
  //   const scrollTarget = navNode.current.offsetHeight + 56 // 56 is a magic number (height of the page header)

  //   if (address) {
  //     scrollIntoView(target, { time: 300 })
  //     scrollIntoView(address, {
  //       time: 300,
  //       align: { top: 0, topOffset: scrollTarget },
  //     })
  //   }
  // }

  function onSearch(e) {
    searchQueryChanged(e.target.value)
    window.scrollTo(0, infoBlock.offsetHeight)
  }

  return (
    <Container>
      {/* <MenuNav reference={navNode}>
        {useList($restaurantItems, ({ id, title }) => (
          <Anchor
            key={`anchor-${id}`}
            hash={`#category-${id}`}
            onClick={onAnchorClick}
            title={title}
          />
        ))}
      </MenuNav> */}
      <Search>
        <form onSubmit={e => e.preventDefault()}>
          <SearchFormContainer>
            <SearchInput
              placeholder="Поиск"
              type="text"
              value={searchQuery}
              onChange={onSearch}
            />
            <SearchResetButton
              type="button"
              disabled={!searchQuery}
              aria-label="Сбросить поисковой параметр"
              onClick={searchQueryReseted}
            />
          </SearchFormContainer>
        </form>
      </Search>
      <List>
        {filteredItems.map(({ id, title, items }) => (
          <Section id={`category-${id}`} key={`section-${id}`}>
            <GroupTitle>{title}</GroupTitle>
            {items.map(item => {
              const { id, category_id } = item
              return (
                <ItemCard
                  key={`${category_id}-item-${id}`}
                  item={item}
                  active={active}
                  onClick={() =>
                    itemClick({
                      item,
                      showAddressModal: () => setAddressWarning(true),
                      showTimeModal: () => setTimeWarning(true),
                    })
                  }
                />
              )
            })}
          </Section>
        ))}
      </List>
      <InfoModal
        title="Доставка недоступна"
        isShown={timeWarning}
        onCloseClick={() => setTimeWarning(false)}
      >
        <TimeWarning>
          <p>
            К&nbsp;сожалению, доставка к&nbsp;указанному времени недоступна.
            Ближайшее время&nbsp;&mdash; {currentRestaurant.deliveryToMessage}
          </p>
          {currentRestaurant && (
            <footer>
              <Button
                type="button"
                onClick={() => {
                  timeSelected(currentRestaurant.deliveryToValue)
                  setTimeWarning(false)
                  callback()
                }}
              >
                Доставить в ближайшее время
              </Button>
              <Button as={Link} to="/" type="button" skeletal="true">
                Посмотреть доступные места
              </Button>
            </footer>
          )}
        </TimeWarning>
      </InfoModal>
      <InfoModal
        title="Укажите ваш адрес"
        isShown={addressWarning}
        onCloseClick={() => setAddressWarning(false)}
      >
        <TimeWarning>
          <p>Укажите адрес, чтобы мы могли расчитать стоимость доставки</p>
          <footer>
            <Button
              type="button"
              onClick={() => {
                setAddressWarning(false)
                setAddressSelect(true)
              }}
            >
              Указать адрес
            </Button>
          </footer>
        </TimeWarning>
      </InfoModal>
      {addressSelect && (
        <AddressModal
          isShown={true}
          closeHandler={() => setAddressSelect(false)}
          onAddressSelect={callback}
        />
      )}
    </Container>
  )
}

export default RestaurantMenu

import React, { useEffect } from 'react'
import { Link } from '@reach/router'
import { parse } from 'qs'
import styled from 'styled-components'

import Layout from './shared/Layout'
import SuccessIcon from './Succeed/SuccessIcon'
import Button from './shared/Button'
import VkIcon from './shared/VkIcon'
import InstagramIcon from './shared/InstagramIcon'

import { clearCart } from '../store/cart'

const SucceedLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const Icon = styled.div`
  margin: 0 auto 1rem;
  color: var(--main-accent-color);

  svg {
    fill: currentColor;
  }
`

const Text = styled.span`
  text-align: center;
  margin-bottom: 1rem;
`

const Nav = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1.5rem 0;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 32px;
  color: var(--secondary-text-color);
  font-size: 0.9rem;

  & > ${Text} {
    margin: 0;
  }
`

const Socials = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  a {
    display: block;
    width: 24px;
    height: 24px;
    color: var(--tertiary-text-color);
  }

  svg {
    fill: currentColor;
  }
`

function Succeed() {
  useEffect(() => {
    const urlParams = window.location.href.split('?')[1]
    const { clear_cart } = parse(urlParams)

    if (clear_cart) {
      clearCart()
    }

    window.scrollTo(0, 0)
  })

  return (
    <SucceedLayout>
      <Icon>
        <SuccessIcon width={64} height={64} />
      </Icon>
      <Text>
        Ваш заказ оформлен
        <br />
        Мы уже передали его ресторану
      </Text>
      <Text>Отменить заказ можно в&nbsp;течение 10&nbsp;минут</Text>
      <Text>
        Вы&nbsp;сможете оставить отзыв к&nbsp;заказу, это повлияет
        на&nbsp;рейтинг ресторана
      </Text>
      <Nav>
        <Button type="button" as={Link} to="/">
          На главную
        </Button>
        <Button type="button" as={Link} to="/orders">
          К заказам
        </Button>
      </Nav>
      <Footer>
        <Text>Подписывайтесь на&nbsp;нас в&nbsp;соц. сетях</Text>
        <Socials>
          <a
            href="https://vk.com/edaelistaru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <VkIcon />
          </a>
          <a
            href="https://www.instagram.com/edaelista.ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
        </Socials>
      </Footer>
    </SucceedLayout>
  )
}

export default Succeed

import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const pulsingOpacity = keyframes`
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 0.8;
  }
`

const loadingStyle = css`
  animation-name: ${pulsingOpacity};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
`

const disabledStyle = css`
  color: var(--secondary-text-color);
  background-color: var(--alt-background-color);
  cursor: not-allowed;
  pointer-events: none;
`

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: ${({ right }) => (right ? 'space-between' : 'center')};
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--inverted-text-color);
  font: inherit;
  text-align: ${({ right }) => !right && 'center'};
  text-decoration: none;
  background-color: var(--main-accent-color);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  opacity: 1;
  pointer-events: ${({ loading }) => loading && 'none'};
  transition: opacity 0.2s, background 0.2s;
  ${({ loading }) => loading && loadingStyle}
  ${({ disabled }) => disabled && disabledStyle}

  strong {
    margin-left: 1rem;
  }
`

function FlowButton({
  type = 'button',
  as,
  to,
  text,
  right,
  onClick,
  disabled,
  loading,
}) {
  return (
    <StyledButton
      type={type}
      as={as}
      to={to}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      right={right}
    >
      <span>{text}</span>
      {right && <strong>{right}</strong>}
    </StyledButton>
  )
}

export default FlowButton

import axios from 'axios'
import { createStore, createStoreObject, createEffect } from 'effector'

import { $token, $restaurants } from './index'

export const loadOrdersEffect = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/orders/', {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const cancelOrderFx = createEffect({
  handler: async orderId => {
    const res = await axios.get(`api/customer/cancel_order/${orderId}`, {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data
  },
})

export const $orders = createStore([])
  .on(loadOrdersEffect.done, (_, { result }) => {
    return result.sort((a, b) => b.id - a.id)
  })
  .on(cancelOrderFx.done, (prev, { result }) => {
    if (result.code === 'ok') {
      return prev.map(order => {
        if (order.id === result.data.id) {
          return result.data
        }
        return order
      })
    }
  })

$token.updates.watch(token => {
  if (token) {
    loadOrdersEffect()
  }
})

export const $enreachedOrders = createStoreObject({
  orders: $orders,
  restaurants: $restaurants,
}).map(({ orders, restaurants }) =>
  orders.map(order => ({
    ...order,
    restaurant: restaurants.find(item => item.id === order.basket[0].shop_id),
  })),
)

import React from 'react'

function CreditCardIcon({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 478.984 478.984"
      width={width}
      height={height}
    >
      <path d="M344 319.492h48v16h-48zM280 319.492h48v16h-48zM216 319.492h48v16h-48z" />
      <path d="M471.896 165.916L313.6 7.596a23.897 23.897 0 00-17.008-7.08h-.072a23.67 23.67 0 00-16.872 7.08l-167.92 167.896H24c-13.255 0-24 10.745-24 24v224c0 13.255 10.745 24 24 24h118.528l23.872 23.896a23.897 23.897 0 0017.008 7.08h.064a23.681 23.681 0 0016.88-7.08l23.92-23.896H408c13.255 0 24-10.745 24-24V239.764l39.856-39.856a23.723 23.723 0 007.128-16.912 23.907 23.907 0 00-7.088-17.08zM370.128 86.772l-88.72 88.72h-67.936L336.16 52.804l33.968 33.968zm-79.152-67.896a7.888 7.888 0 0111.288 0l22.584 22.616-134.008 134h-56.488L290.976 18.876zM189.024 460.108a7.783 7.783 0 01-5.6 2.4 8 8 0 01-5.696-2.4l-12.568-12.616h36.488l-12.624 12.616zM416 423.492a8 8 0 01-8 8H24a8 8 0 01-8-8v-112h400v112zm0-128H16v-48h400v48zm0-64H16v-32a8 8 0 018-8h384a8 8 0 018 8v32zm44.584-42.936L432 217.14v-17.648c0-13.255-10.745-24-24-24H304l77.408-77.408 79.144 79.144a7.997 7.997 0 012.4 5.72 7.843 7.843 0 01-2.368 5.608z" />
      <path d="M76.728 415.708A31.984 31.984 0 0080 412.436c10.579 12.322 28.756 14.663 42.112 5.424A32.529 32.529 0 00136 391.492c0-17.673-14.327-32-32-32a24.263 24.263 0 00-4.736.4l-.208.056h-.232A31.588 31.588 0 0080 370.572c-11.56-13.368-31.769-14.833-45.136-3.272-13.368 11.56-14.833 31.769-3.272 45.136 11.56 13.368 31.768 14.833 45.136 3.272zm25.248-40.048a9.675 9.675 0 012.024-.168c8.837 0 16 7.163 16 16a15.689 15.689 0 01-3.144 9.384c-.288.392-.512.8-.84 1.208-.056.064-.136.104-.2.168a15.937 15.937 0 01-11.816 5.24c-8.835-.151-15.876-7.435-15.725-16.27a16 16 0 0113.701-15.562zM56 375.492c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16z" />
    </svg>
  )
}

export default CreditCardIcon

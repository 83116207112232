import React from 'react'

function GiftIcon({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 223.418 223.418"
    >
      <path d="M8.209 103.668h93.984V75.876h19.031v27.792h93.984v-56h-36.567c6.932-13.724 5.877-29.438-3.911-39.226C169.287 2.998 161.729 0 153.451 0c-10.578 0-21.426 4.782-29.763 13.119-7.809 7.81-10.867 20.497-11.979 31.19-1.112-10.693-4.17-23.381-11.98-31.19C91.393 4.782 80.544 0 69.967 0c-8.278 0-15.835 2.998-21.28 8.442-6.444 6.444-9.416 15.853-8.154 25.813.582 4.59 2.017 9.13 4.198 13.413H8.209v56zm129.622-76.406c4.546-4.547 10.386-7.262 15.62-7.262 2.077 0 5.002.449 7.138 2.585 4.818 4.817 2.633 15.454-4.676 22.762-3.582 3.582-13.009 6.041-24.667 6.555.456-9.578 2.415-20.47 6.585-24.64zm-75.002-4.677C64.965 20.449 67.89 20 69.967 20c5.234 0 11.074 2.715 15.62 7.262 4.207 4.207 6.154 14.995 6.593 24.64-11.661-.513-21.093-2.972-24.675-6.555-3.935-3.935-6.534-8.893-7.131-13.606-.282-2.221-.337-6.364 2.455-9.156zm39.364 98.833v102H22.459v-102h79.734zm19.032 102v-102h79.734v102h-79.734z" />
    </svg>
  )
}

export default GiftIcon

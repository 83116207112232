import React from 'react'

import HeaderButton from '../../shared/HeaderButton'
import TrashIcon from './EraseButton/TrashIcon'

function EraseButton({ onClick }) {
  return (
    <HeaderButton type="button" onClick={onClick}>
      <TrashIcon />
    </HeaderButton>
  )
}

export default EraseButton

import React from 'react'

function SuccessIcon({ width = 96, height = 96 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 416 416"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M193.306 282.645c-4.702 0-8.882-2.09-11.494-5.224l-78.89-85.682c-5.747-6.269-5.224-16.196 1.045-21.943s16.196-5.224 21.943 1.045l67.918 73.665L381.91 56.947c6.269-6.269 16.196-6.269 21.943 0s6.269 16.196 0 21.943L204.278 277.943c-2.613 3.135-6.792 4.702-10.972 4.702z" />
      <path d="M207.935 415.869C93.518 415.869 0 322.351 0 207.935S93.518 0 207.935 0c8.882 0 15.673 6.792 15.673 15.673s-6.792 15.673-15.673 15.673c-97.176 0-176.588 79.412-176.588 176.588 0 97.176 79.412 176.588 176.588 176.588 97.176 0 176.588-79.412 176.588-176.588 0-8.882 6.792-15.673 15.673-15.673s15.673 6.792 15.673 15.673c0 114.417-93.518 207.935-207.934 207.935z" />
    </svg>
  )
}

export default SuccessIcon

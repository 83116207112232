import React from 'react'
import styled from 'styled-components'

import Logo from './Header/Logo'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  height: 57px;
  padding: 0 1rem;
  background: var(--main-background-color);
  border-bottom: 1px solid var(--border-color);

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
    fill: var(--main-accent-color);
  }
`

const Title = styled.div`
  display: grid;

  strong {
    font-size: 1.125rem;
  }

  span {
    color: var(--secondary-text-color);
    font-size: 0.75rem;
  }
`

function Header() {
  return (
    <StyledHeader>
      <Logo />
      <Title>
        <strong>EdaElista.ru</strong>
        <span>Заказ еды из ресторанов и кафе</span>
      </Title>
    </StyledHeader>
  )
}

export default Header

import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ labeled }) => labeled && '0.75rem'};
`

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  color: var(--text-color);
  font: inherit;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-color: ${({ isFocused, error }) =>
    error ? 'var(--danger-color)' : isFocused && 'var(--main-accent-color)'};
  border-radius: 0.5rem;
  outline: none;
  transition: border 0.2s;

  &:disabled {
    background-color: var(--alt-background-color);
    cursor: not-allowed;
  }
`

const Label = styled.label`
  color: ${({ error }) =>
    error ? 'var(--danger-color)' : 'var(--secondary-text-color)'};
  font-size: 0.75rem;
  transform: translateY(-110%);
  transform-origin: top left;
  pointer-events: none;
  transition: transform 0.2s;
`

function Input({
  id,
  name,
  label,
  inputRef,
  maxLength,
  type = 'text',
  autoComplete,
  value,
  initialValue,
  onChange = () => null,
  className,
  error,
  disabled,
}) {
  const [isFocused, setIsFocused] = useState(false)

  function inputHandler(e) {
    onChange(e.target.value)
  }

  function onFocus() {
    setIsFocused(true)
  }

  function onBlur() {
    setIsFocused(false)
  }

  return (
    <Container labeled={!!label} className={className}>
      {label && (
        <Label isFocused={isFocused} htmlFor={id} error={error}>
          {label}
        </Label>
      )}
      <InputField
        id={id}
        ref={inputRef}
        name={name}
        type={type}
        value={value}
        defaultValue={initialValue}
        maxLength={maxLength}
        autoComplete={autoComplete}
        onChange={inputHandler}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
        error={error}
        disabled={disabled}
      />
    </Container>
  )
}

export default Input

import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { useStore } from 'effector-react'
import { parse } from 'qs'
import { YMInitializer } from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import SmartBanner from 'react-smartbanner'

import Directory from './components/Directory'
import Profile from './components/Profile'
import Restaurant from './components/Restaurant'
import OrdersHistory from './components/OrdersHistory'
import Cart from './components/Cart'
import Checkout from './components/Checkout'
import Contacts from './components/Contacts'
import Oferta from './components/Oferta'
import MobileAppUpdateWarning from './components/MobileAppUpdateWarning'

import Succeed from './components/Succeed'
import { DEV } from './utils/constants'
import history from './utils/history'
import {
  appInited,
  takeawayModeKey,
  takeawayModeSettedFromLocalStorage,
} from './store'
import { loadLocalStorage } from './store/order'
import {
  checkSession,
  tokenKey,
  $urlParams,
  setUrlParams,
  $isMobileClient,
  mobileClientChecked,
} from './store/auth'
import { loadPaymentTypes } from './store/paymentTypes'
import { initTimeIntervals } from './store/timeIntervals'
import { loadRestaurants } from './store/restaurants'
import {
  setCurrentAddressFromLocalStorage,
  selectedAddressKey,
} from './store/addresses'
import './store/init'

function App() {
  const { utm_source } = useStore($urlParams)
  const isMobileClient = useStore($isMobileClient)

  useEffect(() => {
    appInited()
    loadPaymentTypes()
    loadLocalStorage()
    initTimeIntervals()
    takeawayModeSettedFromLocalStorage(
      JSON.parse(localStorage.getItem(takeawayModeKey)),
    )
    setCurrentAddressFromLocalStorage(
      JSON.parse(localStorage.getItem(selectedAddressKey)),
    )
    mobileClientChecked(document.location.host.includes('mobile'))
    const urlParts = window.location.href.split('?')
    const urlParams = urlParts.length > 1 ? urlParts[1] : ''
    setUrlParams(parse(urlParams))

    try {
      const token = JSON.parse(localStorage.getItem(tokenKey))
      if (!token) {
        throw new Error('no token')
      }
      checkSession(token)
    } catch (e) {
      console.error(e)
      loadRestaurants()
    }
    !DEV && ReactGA.initialize('UA-168830766-1') // google analitycs
  }, [])

  return (
    <>
      {!DEV && (
        <Helmet>
          {/* <!-- Yandex.Metrika counter --> */}
          {/* <script type="text/javascript">{`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");ym(64713364, "init", {clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});`}</script> */}
          {/* <noscript>{`<div><img src="https://mc.yandex.ru/watch/64713364" style="position:absolute; left:-9999px;" alt="" /></div>`}</noscript> */}
          {/* <!-- /Yandex.Metrika counter --> */}
          {/* vk pixel */}
          <script type="text/javascript">{`!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){VK.Retargeting.Init("VK-RTRG-496661-2bBj3"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`}</script>
          <noscript>{`<img src="https://vk.com/rtrg?p=VK-RTRG-496661-2bBj3" style="position:fixed; left:-999px;" alt="" />`}</noscript>
          {/* end vk pixel */}
          {/* {<!-- Facebook Pixel Code --> */}
          <script>{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1311952438996775');fbq('track', 'PageView');`}</script>
          <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1311952438996775&ev=PageView&noscript=1" />`}</noscript>
          {/* <!-- End Facebook Pixel Code --> */}
        </Helmet>
      )}
      {!DEV && (
        <YMInitializer
          accounts={[64713364]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
          version="2"
        />
      )}
      {!isMobileClient && (
        <SmartBanner
          title={'EdaElista'}
          button={'Установить'}
          storeText={{ ios: 'в App Store', android: 'в Google Play' }}
          price={{ ios: 'Бесплатно', android: 'Бесплатно' }}
          daysReminder={7}
          daysHidden={7}
          position={'top'}
        />
      )}
      <Router history={history}>
        <Succeed path="/succeed" />
        <Checkout mobileApp={utm_source === 'mobile'} path="/checkout" />
        <Cart path="/cart" />
        <OrdersHistory path="/orders" />
        <Profile path="/profile" />
        <Contacts path="/contacts" />
        <Oferta path="/oferta" />
        <Restaurant path="/restaurant/:restaurantId" />
        <Directory path="/" />
        <Contacts path="/contacts" />
        <Oferta path="/oferta" />
        <MobileAppUpdateWarning path="/app-update" />
        <Directory path="/" />
      </Router>
    </>
  )
}

export default App

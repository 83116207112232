import React from 'react'
import { Link } from '@reach/router'

import HeaderButton from './HeaderButton'
import ArrowIcon from './GoBackButton/ArrowIcon'

function GoBackButton({ backPath, as = Link, type, onClick }) {
  return (
    <HeaderButton as={as} to={backPath} type={type} onClick={onClick}>
      <ArrowIcon />
    </HeaderButton>
  )
}

export default GoBackButton

import React, { useEffect, useState } from 'react'
import scrollIntoView from 'scroll-into-view'

import StickyHeader, { Title } from '../shared/StickyHeader'
import GoBackButton from '../shared/GoBackButton'

function Header({ title }) {
  const [isScrolled, setIsScrolled] = useState(true)

  function getScrolledState() {
    const yPosition = window.pageYOffset

    if (yPosition > 40) {
      setIsScrolled(false)
    } else {
      setIsScrolled(true)
    }
  }

  useEffect(() => {
    getScrolledState()
    window.addEventListener('scroll', getScrolledState)

    return () => {
      window.removeEventListener('scroll', getScrolledState)
    }
  })

  function onHeaderClick() {
    scrollIntoView(document.querySelector('#root'), {
      time: 300,
      align: { top: 0 },
    })
  }

  return (
    <StickyHeader noShadow>
      <GoBackButton backPath="/" />
      <Title onClick={onHeaderClick} disabled={isScrolled}>
        <strong>{title}</strong>
      </Title>
      {/* <SearchButton /> */}
    </StickyHeader>
  )
}

export default Header

import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import ModalOverlay from '../shared/ModalOverlay'
import Header from './MainMenu/Header'
import NavList from './MainMenu/NavList'
import MenuItem from './MainMenu/MenuItem'
import ProfileIcon from '../shared/ProfileIcon'
import HistoryIcon from './MainMenu/HistoryIcon'
import Footer from './MainMenu/Footer'
import LoginIcon from './MainMenu/LoginIcon'
import LogoutIcon from './MainMenu/LogoutIcon'
import AddressIcon from './MainMenu/AddressIcon'
import AuthModal from '../shared/AuthModal'
import AddressModal from '../shared/AddressModal'

import { $token } from '../../store'
import { openLogin, logoutFx, $loginStep } from '../../store/auth'
import DialogModal from '../shared/DialogModal'

const Container = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
  align-content: start;
  width: calc(100% - 64px);
  max-width: 480px;
  height: 100vh;
  overflow: auto;
  background: var(--alt-background-color);
  box-shadow: 0 0 20px
    ${({ isShown }) => (isShown ? 'var(--shadow-color)' : 'transparent')};
  transform: translateX(${({ isShown }) => (isShown ? '0' : '-100%')});
  transition: transform 0.2s, box-shadow 0.2s;
`

function MainMenu({ isShown, showingSetter }) {
  const token = useStore($token)
  const loginStep = useStore($loginStep)

  const targetRef = useRef()
  const [targetElement, setTargetElement] = useState(null)
  const [logoutDialog, setLogoutDialog] = useState(false)
  const [addressesModal, setAddressesModal] = useState(false)

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isShown) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isShown, targetElement])

  function logoutHandler() {
    try {
      logoutFx()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ModalOverlay isShown={isShown} onClick={() => showingSetter(false)}>
      <Container isShown={isShown} ref={targetRef}>
        <Header />
        <NavList>
          {token ? (
            <>
              <MenuItem
                icon={<ProfileIcon />}
                text="Мой профиль"
                type="link"
                path="/profile"
              />
              <MenuItem
                icon={<HistoryIcon />}
                text="Мои заказы"
                type="link"
                path="/orders"
              />
              <MenuItem
                icon={<AddressIcon />}
                text="Мои адреса"
                onClick={() => setAddressesModal(true)}
              />
              <MenuItem
                icon={<LogoutIcon />}
                text="Выход"
                onClick={() => setLogoutDialog(true)}
              />
            </>
          ) : (
            <MenuItem icon={<LoginIcon />} text="Войти" onClick={openLogin} />
          )}
        </NavList>
        <NavList>
          <MenuItem text="Контакты" type="link" path="/contacts" />
          <MenuItem
            text="Пользовательское соглашение"
            type="link"
            path="/oferta"
          />
        </NavList>
        <NavList>
          <MenuItem text="Связаться с нами" type="a" href="tel:+79374646007" />
        </NavList>
        <Footer />
      </Container>
      <DialogModal
        isShown={token && logoutDialog}
        cancelText="Отмена"
        okText="Выйти"
        onOk={logoutHandler}
        onCancel={() => setLogoutDialog(false)}
        dialog="Вы действительно хотите выйти?"
      />
      <AuthModal isShown={!token && loginStep > 0} />
      {addressesModal && (
        <AddressModal
          isShown={true}
          closeHandler={() => setAddressesModal(false)}
          edit={true}
          level={2}
        />
      )}
    </ModalOverlay>
  )
}

export default MainMenu

import React from 'react'
import styled from 'styled-components'

const Container = styled.span`
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    appearance: none;
  }

  input:checked ~ span {
    background-color: var(--main-accent-color);
    border: none;
  }

  input:checked ~ span::after {
    background-color: var(--text-color);
  }

  span {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    background-color: var(--alt-background-color);
    border: 1px solid var(--border-color);
    border-radius: 50%;
    transition: background 0.2s;
    pointer-events: none;
  }

  span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.2s;
  }
`

const RadioButton = React.forwardRef((props, ref) => {
  return (
    <Container>
      <input {...props} ref={ref} />
      <span />
    </Container>
  )
})

export default RadioButton

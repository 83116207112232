import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'

import AppStoreArt from './shared/AppStoreArt'

const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  min-height: 100vh;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

function MobileAppUpdateWarning() {
  useEffect(() => {
    const host = document.location.host

    if (host.includes('mobile')) {
      navigate('/')
    }
  }, [])

  return (
    <Grid>
      <p style={{ marginBottom: 50 }}>Пожалуйста обновите приложение</p>
      <a href="https://apps.apple.com/ru/app/edaelista/id1518464600">
        <AppStoreArt />
      </a>
      <a href="https://play.google.com/store/apps/details?id=ru.EdaElista&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          alt="Доступно в Google Play"
          style={{ width: 145 }}
          src="https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png"
        />
      </a>
    </Grid>
  )
}

export default MobileAppUpdateWarning

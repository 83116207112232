import styled from 'styled-components'

import { textOverflow } from './styleMixins'

export const Title = styled.div`
  ${textOverflow}
  padding: 1rem 0;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0' : '1')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  transform: ${({ disabled }) =>
    disabled ? 'translateY(120%)' : 'translateY(0)'};
  transition: transform 0.4s, opacity 0.2s;
`

const StickyHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 72px 1fr 72px;
  grid-gap: 1rem;
  align-items: center;
  height: 56px;
  width: 100%;
  overflow-y: hidden;
  background: var(--main-background-color);
  box-shadow: ${({ noShadow }) =>
    noShadow ? 'none' : '0 0 5px var(--shadow-color)'};
`

export default StickyHeader

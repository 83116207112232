import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import debounce from 'lodash.debounce'
import { differenceInMinutes, format } from 'date-fns'

import DialogModal from '../shared/DialogModal'
import Button from '../shared/Button'

import { restCallFx } from '../../store/restCall'
import { cancelOrderFx } from '../../store/orders'
import { payOrderCalled } from '../../store/order'

const Card = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid var(--border-color);
`

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.5rem;
  align-items: start;
`

const Description = styled.p`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 0.5em;
  margin: 0;
  font-size: 0.9rem;
  cursor: pointer;

  span:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: -1;
  }
`

const DropdownContent = styled.div`
  max-height: ${({ expand }) => expand || 0}px;
  overflow: hidden;
  transition: max-height 0.2s;
`

const listStyle = css`
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  background: var(--alt-background-color);
  border-radius: 0.5rem;
`

const List = styled.ul`
  display: grid;
  grid-gap: 0.25rem;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  list-style: none;

  li {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
  }

  li > span:last-child {
    color: var(--secondary-text-color);
  }

  ${({ noStyle }) => !noStyle && listStyle}
`

const OrderItem = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
  }

  header > span:last-child {
    color: var(--secondary-text-color);
  }

  ul {
    padding: 0.5rem 0 0.5rem;
    list-style: none;
    color: var(--secondary-text-color);
    font-size: 0.8rem;
  }

  li {
    position: relative;
    padding-left: 1.2rem;
  }

  li::before {
    content: '—';
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`

const StatusBar = styled.span`
  color: var(--text-color);
  background-color: ${({ status }) =>
    status === 'hold'
      ? '#ffe5a3'
      : status === 'new'
      ? '#96cdff'
      : status === 'confirmed'
      ? '#b2ffab'
      : status === 'canceled'
      ? '#ffbebe'
      : 'transparent'};
`

const statusOptions = {
  new: 'Новый',
  confirmed: 'Подтвержден',
  paid: 'Оплачен',
  packed: 'Упакован',
  on_the_way: 'В пути',
  delivered: 'Доставлен',
  canceled: 'Отменен',
}

const TIME_TO_CALL = 2880 // Временное окно для звонка в ресторан в минутах
const TIME_TO_CANCEL = 10 // Временное окно для отмены заказа в минутах
const TIME_TO_FEEDBACK_AVAILABLE = 120 // Время после которого можно оставить отзыв в минутах
const MINUTES_IN_WEEK = 24 * 60 * 7 // Время до которого можно оставить отзыв в минутах

function OrderCard({ order, onFeedbackClick }) {
  const {
    id,
    basket,
    createdAt,
    paid_at,
    payment_type,
    meta: { deliveryCost, sum, totalSum },
    gift,
    status,
    rating,
    restaurant,
    address,
    takeaway,
    takeawayAddress,
    takeawayDiscount,
    cancelledByRole,
    deferredTo,
  } = order
  const totalPrice =
    totalSum || Number(deliveryCost) + Number(sum) - Number(takeawayDiscount)
  const orderAddress = takeaway
    ? takeawayAddress
    : `${address.line.trim()}${address.office ? `, ${address.office}` : ''}`
  const originDate = payment_type === 1 ? paid_at : createdAt
  const minutesLived = differenceInMinutes(new Date(), new Date(originDate))
  const { id: restId, title, contact } = restaurant
  const listNode = useRef()
  const [expanded, setExpanded] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  // const [minutesLived, setMinutesLived] = useState(0)

  function onRestCall() {
    restCallFx({ order_id: id, shop_id: restId, source_page: 1 })
  }

  return (
    <Card>
      <Header>
        <strong>{title}</strong>
        <strong>{totalPrice}&nbsp;₽</strong>
      </Header>
      <Description onClick={debounce(() => setExpanded(!expanded), 100)}>
        <span>
          Создан:{' '}
          <time dateTime={createdAt}>
            {format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}
          </time>
        </span>
        {payment_type === 1 && !paid_at && status === 'new' ? (
          <StatusBar status="hold">Ожидает оплаты</StatusBar>
        ) : (
          <StatusBar status={status}>{statusOptions[status]}</StatusBar>
        )}
        <span>Номер заказа: {id}</span>
        <span>
          Адрес {takeaway ? 'самовывоза' : 'доставки'}: {orderAddress}
        </span>
        {deferredTo && (
          <span>
            Доставка к{' '}
            <time dateTime={deferredTo}>
              {format(new Date(deferredTo), 'HH:mm (dd.MM.yyyy)')}
            </time>
          </span>
        )}
      </Description>
      <div>
        <DropdownContent expand={expanded && listNode.current.offsetHeight}>
          <div ref={listNode}>
            <List>
              {basket.map(
                ({ title, qty, price, finalPrice, options }, index) => {
                  const fullPrice = options
                    ? options.reduce(
                        (acc, option) => acc + Number(option.price),
                        Number(price),
                      )
                    : Number(price)
                  return (
                    <li key={`basket-${order.id}-${index}`}>
                      <OrderItem>
                        <header>
                          <span>{title}</span>
                          <span>
                            {qty} &times; {finalPrice || fullPrice}&nbsp;₽
                          </span>
                        </header>
                        {options && options.length > 0 && (
                          <ul>
                            {options.map(({ id, title }) => (
                              <li key={`option-${id}`}>{title}</li>
                            ))}
                          </ul>
                        )}
                      </OrderItem>
                    </li>
                  )
                },
              )}
            </List>
            {gift && gift.length > 0 && (
              <List as="div">
                <span
                  style={{
                    display: 'block',
                    color: 'var(--main-accent-color)',
                    fontWeight: 'bold',
                  }}
                >
                  В подарок
                </span>
                <List noStyle>
                  {gift.map(({ id, title }) => (
                    <li key={`gift-${id}`}>{title}</li>
                  ))}
                </List>
              </List>
            )}
            <List>
              <li>
                <span>Стоимость доставки</span>
                <span>{deliveryCost}&nbsp;₽</span>
              </li>
            </List>
          </div>
        </DropdownContent>
      </div>
      <Footer>
        {payment_type === 1 && !paid_at && status === 'new' && (
          <Button
            style={{ backgroundColor: 'darkorange' }}
            onClick={() => payOrderCalled(order)}
          >
            Оплатить {totalPrice}&nbsp;&#8381;
          </Button>
        )}
        {minutesLived <= TIME_TO_CALL && (
          <Button as="a" href={`tel:${contact}`} onClick={onRestCall}>
            Позвонить
          </Button>
        )}
        {status !== 'canceled' &&
          ((!paid_at && payment_type === 1) ||
            minutesLived <= TIME_TO_CANCEL) && (
            <Button type="button" onClick={() => setCancelModal(true)} danger>
              Отменить заказ
            </Button>
          )}
        {minutesLived >= TIME_TO_FEEDBACK_AVAILABLE &&
          minutesLived <= MINUTES_IN_WEEK &&
          status !== 'new' &&
          cancelledByRole !== 'customer' &&
          (!rating || (rating && rating.auto)) && (
            <Button type="button" onClick={onFeedbackClick}>
              Оставить отзыв
            </Button>
          )}
      </Footer>
      {status !== 'canceled' &&
        ((!paid_at && payment_type === 1) ||
          minutesLived <= TIME_TO_CANCEL) && (
          <DialogModal
            dialog={`Вы действительно хотите отменить заказ ${title} на ${totalPrice} ₽`}
            okText="Да"
            cancelText="Нет"
            danger={true}
            isShown={cancelModal}
            onOk={() => cancelOrderFx(id)}
            onCancel={() => setCancelModal(false)}
          />
        )}
    </Card>
  )
}

export default OrderCard

import { createStore, createEvent } from 'effector'

const createState = () => {
  const $timeIntervals = createStore([])
  const $selectedTime = createStore({ value: -1 })

  const timeSelected = createEvent('time selected')
  const initTimeIntervals = createEvent('time selector focused')

  return {
    $timeIntervals,
    $selectedTime,
    timeSelected,
    initTimeIntervals,
  }
}

export default createState

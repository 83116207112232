import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-color: ${({ isFocused }) => isFocused && 'var(--main-accent-color)'};
  border-radius: 0.5rem;
`

const TextAreaField = styled.textarea`
  width: 100%;
  height: auto;
  margin: 0;
  box-sizing: content-box;
  color: var(--text-color);
  font: inherit;
  font-size: 1rem;
  border: 0;
  border-radius: 0;
  outline: none;
  resize: ${({ resizable }) => !resizable && 'none'};
  transition: border 0.2s;

  &::placeholder {
    color: ${({ isFocused }) =>
      isFocused ? 'transparent' : 'var(--secondary-text-color)'};
    transition: color 0.2s;
    opacity: 1;
  }
`

const Label = styled.label`
  color: var(--secondary-text-color);
  font-size: 1rem;
`

function TextArea({
  id,
  name,
  label,
  inputRef,
  value,
  resizable = false,
  placeholder,
  onChange = () => null,
  rows = 2,
}) {
  const [isFocused, setIsFocused] = useState(false)

  function handleChange(e) {
    onChange(e.target.value)
  }

  function onFocus() {
    setIsFocused(true)
  }

  function onBlur() {
    setIsFocused(false)
  }

  return (
    <Container isFocused={isFocused}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <TextAreaField
        id={id}
        name={name}
        value={value}
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
        resizable={resizable}
        rows={rows}
      />
    </Container>
  )
}

export default TextArea

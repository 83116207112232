import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0.5rem 1rem;
  color: ${({ skeletal }) =>
    skeletal ? 'var(--main-accent-color)' : 'var(--inverted-text-color)'};
  font-family: inherit;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  background-color: ${({ danger }) =>
    danger ? 'var(--danger-color)' : 'var(--main-accent-color)'};
  background-color: ${({ skeletal }) =>
    skeletal && 'var(--main-background-color)'};
  border: ${({ skeletal }) =>
    skeletal ? '1px solid var(--main-accent-color)' : 'none'};
  border-radius: 10px;
  cursor: pointer;
  appearance: none;

  &:disabled {
    color: var(--secondary-text-color);
    background-color: var(--alt-background-color);
    cursor: not-allowed;
  }

  @media (max-width: 374px) {
    padding: 0.5rem;
  }
`

export default Button

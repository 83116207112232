import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'

import RestaurantCard from './List/RestaurantCard'
import Button from '../shared/Button'

import { takeawayModeResetted } from '../../store'

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  align-items: start;
  max-width: 100%;
  padding: 1rem 1rem 2rem;
`

const Note = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  color: var(--secondary-text-color);
`

function List({ restaurants, isFiltered }) {
  return (
    <div>
      <Grid>
        {restaurants.map(restaurant => (
          <RestaurantCard
            key={`restaurant-card-${restaurant.id}`}
            restaurant={restaurant}
          />
        ))}
      </Grid>
      <Note>
        {isFiltered && (
          <>
            {restaurants.length === 0 && <p>Ничего не найдено</p>}
            <Button
              type="button"
              onClick={() => {
                takeawayModeResetted()
                navigate('/')
              }}
            >
              Сбросить фильтры
            </Button>
          </>
        )}
      </Note>
    </div>
  )
}

export default List

import React from 'react'
import styled, { css } from 'styled-components'

import Portal2 from './Portal2'
import ModalOverlay from './ModalOverlay'
import Button from './Button'

const Window = styled.div`
  display: grid;
  width: 90%;
  max-width: 400px;
  height: auto;
  margin: auto;
  background: var(--main-background-color);
  border-radius: 10px;
  box-shadow: 0 0 20px var(--shadow-color);
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transition: opacity 0.2s;
`

const Dialog = styled.div`
  padding: 1rem;

  p {
    margin: 0;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    line-height: 130%;
  }
`

const Footer = styled.footer`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1rem;
  padding: 1rem;
  padding-top: 0.5rem;
`

const cancelStyle = css`
  color: var(--text-color);
  background-color: var(--alt-background-color);
  border: 1px solid var(--border-color);
`

const CancelButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ danger }) => !danger && cancelStyle}
`

const dagerOk = css`
  color: var(--danger-color);
  background-color: var(--main-background-color);
  border: 1px solid var(--danger-color);
`

const OkButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ danger }) => danger && dagerOk}
`

function DialogModal({
  isShown,
  danger = false,
  bold = true,
  dialog = 'Диалоговое окно',
  okText = 'OK',
  cancelText = 'Отмена',
  onOk,
  onCancel,
}) {
  function onOkClick() {
    onOk()
    onCancel()
  }

  return (
    <Portal2>
      <ModalOverlay isShown={isShown} onClick={onCancel}>
        <Window isShown={isShown}>
          <Dialog bold={bold}>
            <p>{dialog}</p>
          </Dialog>
          <Footer>
            <OkButton type="button" onClick={onOkClick} danger={danger}>
              {okText}
            </OkButton>
            <CancelButton type="button" onClick={onCancel}>
              {cancelText}
            </CancelButton>
          </Footer>
        </Window>
      </ModalOverlay>
    </Portal2>
  )
}

export default DialogModal

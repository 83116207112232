import {
  combine,
  createStore,
  createEvent,
  createStoreObject,
  sample,
} from 'effector'

import {
  $selectedItem,
  $selectedOptions,
  $numberToCart,
  $cart,
  $restaurants,
  appInited,
  $restaurantItems,
  $takeawayMode,
  $takeawayPriceReduction,
} from './index'
import { $currentRestaurant } from './restaurants'
import {
  $currentAddress,
  $currentDistance,
  $isDeliveryAvailable,
  setDeliveryAvailabilityModal,
} from './addresses'
import { $selectedTime } from './timeIntervals'
import { navigate } from '@reach/router'

const cartKey = 'cart'

const setCartFromLocalstorage = createEvent('cart from localStorage')
export const selectOption = createEvent('select option in item modal')
export const addToCart = createEvent('add item to the cart')
export const removeFromCart = createEvent('remove item from the cart')
export const clearCart = createEvent('clear cart')
export const incNumber = createEvent('increase the number of selected item')
export const decNumber = createEvent('decrease the number of selected item')
export const selectItem = createEvent('select item to add to the cart')
export const selectFromOtherShop = createEvent(
  'select item from the other shop',
)
export const openItemModal = createEvent('open item modal')
export const closeItemModal = createEvent('close item modal')
export const itemClick = createEvent('click on restaurant item')
export const cartClick = createEvent('click on cart button')

export const $callback = createStore(() => null)

const $deliveryInfoObject = combine({
  currentAddressLine: $currentAddress.map(({ line }) => line),
  selectedTimeValue: $selectedTime.map(({ value }) => value),
  deliveryToValue: $currentRestaurant.map(
    ({ deliveryToValue }) => deliveryToValue,
  ),
  isDeliveryAvailable: $isDeliveryAvailable,
  takeaway: $takeawayMode,
})

sample({
  source: $deliveryInfoObject,
  clock: itemClick,
  fn: (
    {
      currentAddressLine,
      selectedTimeValue,
      deliveryToValue,
      isDeliveryAvailable,
      takeaway,
    },
    { item, showAddressModal, showTimeModal },
  ) => {
    if (!currentAddressLine && !takeaway) {
      showAddressModal()
      return () => itemClick({ item, showAddressModal, showTimeModal })
    } else if (!isDeliveryAvailable) {
      setDeliveryAvailabilityModal(true)
      return () => itemClick({ item, showAddressModal, showTimeModal })
    } else if (selectedTimeValue !== deliveryToValue) {
      showTimeModal()
      return () => itemClick({ item, showAddressModal, showTimeModal })
    } else {
      selectItem(item)
    }
  },
  target: $callback,
})

sample({
  source: $deliveryInfoObject,
  clock: cartClick,
  fn: (
    {
      currentAddressLine,
      selectedTimeValue,
      deliveryToValue,
      isDeliveryAvailable,
      takeaway,
    },
    { showAddressModal, showTimeModal },
  ) => {
    if (!currentAddressLine && !takeaway) {
      showAddressModal()
      return () => cartClick({ showAddressModal, showTimeModal })
    } else if (!isDeliveryAvailable) {
      setDeliveryAvailabilityModal(true)
      return () => cartClick({ showAddressModal, showTimeModal })
    } else if (selectedTimeValue !== deliveryToValue) {
      showTimeModal()
      return () => cartClick({ showAddressModal, showTimeModal })
    } else {
      navigate('/cart')
    }
  },
  target: $callback,
})

$selectedItem
  .on(selectItem, (_, item) => {
    if (item) {
      const customId = item && item.inCart ? item.customId : `${item.id}`
      const groups = item.groups.map(group => {
        if (group.options) {
          const options = group.options.sort((a, b) => a.position - b.position)
          return { ...group, options }
        }
        return group
      })
      return { ...item, customId, groups }
    }
    return null
  })
  .reset([$cart, $currentRestaurant])

$numberToCart
  .on(incNumber, n => n + 1)
  .on(decNumber, n => n - 1)
  .on(selectItem, (_, item) => {
    if (item && item.inCart) {
      return item.count
    }
    return (item && item.groups.length === 0 && item.count) || 1
  })

sample({
  source: $selectedItem,
  clock: selectOption,
  fn: (selectedItem, values) => {
    const allOptions = selectedItem.groups.reduce(
      (acc, item) => acc.concat(item.options),
      [],
    )
    const selectedOptionsIds = Object.keys(values).reduce((acc, item) => {
      if (item.split('_').includes('group') && values[item] !== '') {
        acc.push(Number(values[item]))
        return acc
      }
      if (values[item]) {
        acc.push(Number(item.split('_')[1]))
      }
      return acc
    }, [])
    return allOptions.filter(({ id }) => selectedOptionsIds.includes(id))
  },
  target: $selectedOptions,
})

$selectedOptions.reset($selectedItem)

export const $itemPrice = createStoreObject({
  item: $selectedItem,
  options: $selectedOptions,
}).map(({ item, options }) => {
  if (item) {
    const optionsPrice = options.reduce(
      (acc, { finalPrice }) => acc + Number(finalPrice),
      0,
    )
    return Number(item.finalPrice) + optionsPrice
  }
  return 0
})

const $itemForm = createStoreObject({
  item: $selectedItem,
  options: $selectedOptions,
  fullPrice: $itemPrice,
  number: $numberToCart,
})

const itemFormSubmit = sample({
  source: $itemForm,
  clock: addToCart,
})

$cart
  .on(itemFormSubmit, (cart, { item, options, fullPrice, number }) => {
    const customId = item.inCart
      ? item.customId
      : `${item.id}${options.map(({ id }) => id).join('')}`
    const newItem = {
      ...item,
      customId,
      options,
      fullPrice,
      count: number,
    }
    const el = cart.find(({ customId }) => newItem.customId === customId)
    if (el) {
      return cart.map(cartItem => {
        if (cartItem.customId === newItem.customId) {
          const newCount =
            cartItem.groups.length > 0 && !item.inCart
              ? cartItem.count + number
              : number
          return {
            ...cartItem,
            count: cartItem.maxCount < newCount ? cartItem.maxCount : newCount,
          }
        }
        return cartItem
      })
    } else if (cart.length > 0 && item.shop_id !== cart[0].shop_id) {
      return [].concat(newItem)
    } else {
      return cart.concat(newItem)
    }
  })
  .on(removeFromCart, (cart, customId) =>
    cart.filter(item => item.customId !== customId),
  )
  .on(setCartFromLocalstorage, (_, cart) => cart)
  .reset(clearCart)

export const $decreaseDisabled = $itemForm.map(({ item, number }) => {
  if (item && !item.count) {
    return number === 1
  }
  return number === 0
})
export const $increaseDisabled = $itemForm.map(
  ({ item, number }) => item && item.maxCount === number,
)

$selectedItem.reset($cart)
$numberToCart.reset($cart)

$cart.updates.watch(cart => {
  localStorage.setItem(cartKey, JSON.stringify(cart))
})

export const $cartShop = createStoreObject({
  shops: $restaurants,
  cartShopId: $cart.map(cart => cart.length > 0 && cart[0].shop_id),
}).map(
  ({ shops, cartShopId }) =>
    shops.find(shop => shop.id === +cartShopId) || null,
)

export const $cartTotalPrice = createStore(0)

sample({
  source: $cart,
  fn: cart =>
    cart.reduce((acc, item) => Number(item.fullPrice) * item.count + acc, 0),
  target: $cartTotalPrice,
})

sample({
  source: combine({
    total: $cartTotalPrice,
    restaurant: $currentRestaurant,
    enabled: $takeawayMode,
  }),
  fn: ({ total, restaurant, enabled }) => {
    const { takeawayDiscount } = restaurant

    if (enabled && takeawayDiscount > 0 && restaurant.takeaway) {
      return Math.round(total * (takeawayDiscount / 100))
    }

    return 0
  },
  target: $takeawayPriceReduction,
})

export const $specialGift = createStore([])

sample({
  source: combine({
    total: $cartTotalPrice,
    shop: $cartShop,
    items: $restaurantItems,
  }),
  fn: ({ total, shop, items }) => {
    let currentKey = 0

    if (shop) {
      const keys = shop.specialGift && Object.keys(shop.specialGift)

      for (let i = 0; i < keys.length; i++) {
        if (total > Number(keys[i])) {
          currentKey = Number(keys[i])
        }
      }
    }

    if (shop && currentKey > 0) {
      const itemsFlat = items.reduce((acc, group) => {
        acc = [...acc, ...group.items]
        return acc
      }, [])

      const gifts = itemsFlat
        .filter(item => shop.specialGift[currentKey].includes(item.id))
        .sort((a, b) => Number(a.price) - Number(b.price))

      return gifts
    }

    return []
  },
  target: $specialGift,
})

export const $shopDeliveryDistanceFeesObjectStore = combine({
  shop: $currentRestaurant,
  distance: $currentDistance,
}).map(({ shop, distance }) => {
  if (shop.id && distance >= 0) {
    const shopDeliveryFeesObject = shop.deliveryPrices
    const shopDeliveryKeys = Object.keys(shopDeliveryFeesObject).map(el => +el)
    const deliveryDistanceKey = shopDeliveryKeys.find(
      distanceKey => +distance < distanceKey,
    )
    return shopDeliveryFeesObject[deliveryDistanceKey]
  }
  return null
})

export const $deliveryFee = createStoreObject({
  shop: $cartShop,
  distance: $currentDistance,
  total: $cartTotalPrice,
}).map(({ shop, total, distance }) => {
  if (total && shop) {
    const shopDeliveryFeesObject = shop.deliveryPrices
    const shopDeliveryKeys = Object.keys(shopDeliveryFeesObject).map(el => +el)
    const deliveryDistanceKey = shopDeliveryKeys.find(
      distanceKey => +distance < distanceKey,
    )

    if (deliveryDistanceKey === undefined) return 'Нет доставки до адреса' // if too far
    const deliveryDistanceFeesObject =
      shopDeliveryFeesObject[deliveryDistanceKey]

    const deliveryFeesKeys = Object.keys(deliveryDistanceFeesObject)
      .map(el => +el)
      .sort((a, b) => b - a)
    const deliveryOrderPriceKey = deliveryFeesKeys.find(key => +total >= key)

    if (deliveryOrderPriceKey === undefined)
      return 'Не достигнута мин. стоимость заказа'
    const deliveryFee = deliveryDistanceFeesObject[deliveryOrderPriceKey]
    return deliveryFee
  }
  return null
})

export const $visiblePopup = $selectedItem.map(x => !!x)

$visiblePopup.on(closeItemModal, () => false)
$visiblePopup.on(openItemModal, () => true)

appInited.watch(() => {
  try {
    const cart = JSON.parse(localStorage.getItem(cartKey))
    if (!cart) {
      throw new Error('no cart')
    }
    setCartFromLocalstorage(cart)
  } catch (e) {
    console.error(e)
  }
})

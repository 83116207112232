import React from 'react'

function TerminalIcon({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={width}
      height={height}
    >
      <path d="M84.2 46.893H43.8a1.749 1.749 0 00-1.75 1.75v27.409A1.749 1.749 0 0043.8 77.8h40.4a1.749 1.749 0 001.75-1.75V48.643a1.749 1.749 0 00-1.75-1.75zM82.448 74.3h-36.9V50.393h36.9zM52.351 86.07H43.8a1.75 1.75 0 100 3.5h8.549a1.75 1.75 0 000-3.5zM68.274 86.07h-8.548a1.75 1.75 0 000 3.5h8.548a1.75 1.75 0 000-3.5zM84.2 86.07h-8.551a1.75 1.75 0 000 3.5H84.2a1.75 1.75 0 000-3.5zM52.351 97.256H43.8a1.75 1.75 0 100 3.5h8.549a1.75 1.75 0 000-3.5zM68.274 97.256h-8.548a1.75 1.75 0 000 3.5h8.548a1.75 1.75 0 000-3.5zM84.2 97.256h-8.551a1.75 1.75 0 000 3.5H84.2a1.75 1.75 0 100-3.5zM52.351 108.44H43.8a1.75 1.75 0 100 3.5h8.549a1.75 1.75 0 000-3.5zM68.274 108.44h-8.548a1.75 1.75 0 000 3.5h8.548a1.75 1.75 0 000-3.5zM84.2 108.44h-8.551a1.75 1.75 0 000 3.5H84.2a1.75 1.75 0 000-3.5z" />
      <path d="M103.322 52.442l-7.467-4.759V36.517a8.751 8.751 0 00-8.741-8.742h-1.721V6.307a1.75 1.75 0 00-1.75-1.75H44.357a1.75 1.75 0 00-1.75 1.75v21.468h-1.721a8.751 8.751 0 00-8.741 8.742v11.166l-7.467 4.759a1.75 1.75 0 00-.81 1.476v28.628a1.747 1.747 0 00.81 1.475l7.467 4.759v25.92a8.75 8.75 0 008.741 8.74h46.228a8.75 8.75 0 008.741-8.74V88.78l7.467-4.759a1.747 1.747 0 00.81-1.475V53.918a1.75 1.75 0 00-.81-1.476zM46.107 8.057h35.786V37H46.107zM27.368 81.586V54.878l4.777-3.045v32.8zM92.355 114.7a5.246 5.246 0 01-5.241 5.24H40.886a5.246 5.246 0 01-5.241-5.24V36.517a5.248 5.248 0 015.241-5.242h1.721V37h-1.794a1.75 1.75 0 000 3.5h46.374a1.75 1.75 0 000-3.5h-1.794v-5.725h1.721a5.248 5.248 0 015.241 5.242zm8.277-33.117l-4.777 3.047v-32.8l4.777 3.045z" />
    </svg>
  )
}

export default TerminalIcon

import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import Layout from './shared/Layout'
import Header from './Contacts/Header'
import { $isMobileClient } from '../store/auth'

const Content = styled.div`
  padding: 1rem;
  padding-bottom: 4rem;
  font-size: 0.9rem;

  h2 {
    font-size: 1.5rem;
  }
`

function Contacts() {
  const isMobileClient = useStore($isMobileClient)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Header />
      <Content>
        <h2>Клиентская поддержка</h2>
        <p>
          Если у&nbsp;вас есть вопрос по&nbsp;вашему заказу позвоните
          по&nbsp;телефону&nbsp; <a href="tel:89374646007">8 (937) 464-6007</a>.
          Обратите внимание, что в&nbsp;данный момент мы&nbsp;не&nbsp;принимаем
          заказы по&nbsp;телефону. Если вы&nbsp;хотите сделать заказ,
          пожалуйста, сделайте его на&nbsp;
          <a href={`https://${isMobileClient ? 'mobile.' : ''}edaelista.ru`}>
            сайте
          </a>
          .
        </p>
        <h2>Рестораны</h2>
        <p>
          Мы&nbsp;будем рады обсудить совместное сотрудничество.{' '}
          <a
            href="https://forms.gle/iFDEAxSo2NGs97kb9"
            rel="noopener noreferrer"
            target="_blank"
          >
            Заполните форму
          </a>
          , и&nbsp;мы&nbsp;свяжемся с&nbsp;вами, чтобы обсудить детали. Для
          текущих вопросов по&nbsp;сотрудничеству звоните нам
          по&nbsp;номеру&nbsp;<a href="tel:89374646007">8 (937) 464-6007</a>.
        </p>
        <h2>Курьеры</h2>
        <p>
          Если вы&nbsp;хотите работать курьером по&nbsp;доставке еды,{' '}
          <a
            href="https://forms.gle/ZWgdA5oFiW37WK7u5"
            rel="noopener noreferrer"
            target="_blank"
          >
            заполните форму
          </a>
          . Или звоните по&nbsp;будням с&nbsp;10:00 до&nbsp;18:00
          по&nbsp;телефону&nbsp;<a href="tel:89374646007">8 (937) 464-6007</a>.
          {/* комментарий */}
        </p>
      </Content>
    </Layout>
  )
}

export default Contacts

import axios from 'axios'
import {
  createStore,
  createEvent,
  createEffect,
  sample,
  combine,
} from 'effector'
import { navigate } from '@reach/router'
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'
import PaytureWidget from '../third-party/payture-widget.min.js'

import { DEV } from '../utils/constants'
import {
  $orderDeferredTo,
  $orderPaymentType,
  $orderChange,
  $orderPersons,
  $basketData,
  $token,
  $customerName,
  $isOrderPending,
  $isOrderFailed,
  $takeawayMode,
  $takeawayAddress,
  $takeawayPriceReduction,
  $bonuses,
} from './index'
import { $cartTotalPrice, $deliveryFee } from './cart'
import { loadOrdersEffect } from './orders'
import { $selectedTime } from './timeIntervals'
import { $customerPhone, $isMobileClient } from './auth'
import {
  $currentAddress,
  $currentDistance,
  loadAddressesFx,
  setCurrentAddressFromOrder,
} from './addresses'
import { $currentRestaurant, openRestaurantEvent } from './restaurants'

const PAYTURE_KEY = DEV ? 'MerchantEdaElista' : 'EdaElistaOpen3DS'
const PAYTURE_DOMAIN = DEV ? '2' : '1'

export const changeOrderDeferredTo = createEvent('change order deferred to')
export const changeOrderPaymentType = createEvent('change order payment type')
export const changeOrderChange = createEvent('change order change')
export const changeCustomerName = createEvent('change customer name')
export const changeOrderPersons = createEvent('change order persons')
export const failReset = createEvent('set order fail status')
const orderConfirmed = createEvent('order confirmed')

const customerNameKey = 'customer_name'
const orderDeferredToKey = 'order_deferred_to'
const paymentTypeKey = 'payment_type'
const orderPersonsKey = 'order_persons'

$customerName.on(changeCustomerName, (_, name) => name)
$orderDeferredTo.on(changeOrderDeferredTo, (_, dateTime) => dateTime)
$orderPaymentType.on(changeOrderPaymentType, (_, paymentType) =>
  Number(paymentType),
)
$orderChange.on(changeOrderChange, (_, change) => change)
$orderPersons.on(changeOrderPersons, (_, persons) => persons)

$customerName.updates.watch(name => {
  localStorage.setItem(customerNameKey, name)
})
$orderDeferredTo.updates.watch(defer => {
  localStorage.setItem(orderDeferredToKey, defer)
})
$orderPaymentType.updates.watch(paymentType => {
  localStorage.setItem(paymentTypeKey, paymentType)
})
$orderPersons.updates.watch(orderPersons =>
  localStorage.setItem(orderPersonsKey, orderPersons),
)

export const orderConfirm = createEvent('confirm order')
export const payOrderCalled = createEvent('pay order called')

payOrderCalled.watch(order => {
  if (order.payment_type === 1) {
    try {
      new PaytureWidget({
        Key: PAYTURE_KEY,
        Domain: PAYTURE_DOMAIN,
        Amount: order.meta.totalSum,
        Product: 'EdaElista.ru',
        CustomParams: {
          TemplateTag: 'Widget',
          OrderNumber: order.id,
        },
        OnTransactionCompleted: function (success) {
          if (success) {
            loadOrdersEffect()
          }
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
})

async function getAddressWithCoordinates(address) {
  if (!address.latitude || !address.longitude) {
    let point = {}

    await window.ymaps.geocode(`Элиста, ${address.line}`).then(res => {
      const obj = res.geoObjects.get(0)
      const [latitude, longitude] = obj.geometry.getCoordinates()
      point.latitude = latitude
      point.longitude = longitude
    })

    return {...address, ...point}
  } else {
    return address
  }
}


export const orderConfirmEffect = createEffect({
  handler: async order => {
    const address = await getAddressWithCoordinates(order.address)
    const res = await axios.post(
      '/api/customer/order_confirm/',
      { ...order, address },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${$token.getState()}`,
        },
      },
    )
    return res
  },
})

$isOrderPending.on(orderConfirmEffect.pending, (_, status) => status)
$isOrderFailed
  .on(orderConfirmEffect.fail, (_, { error }) => error.response.data)
  .reset(failReset)

orderConfirmEffect.done.watch(({ result }) => {
  const order = result.data.data
  try {
    if (!DEV) {
      ym('reachGoal', 'new_order')
      ReactGA.event({
        category: 'order',
        action: 'click',
        value: 70,
        label: 'label',
      })
    }
  } catch (e) {
    console.error(e)
  }
  loadAddressesFx()
  loadOrdersEffect()
  setPromoCodeValue('')
  navigate('/orders?clear_cart=true')

  if (order.address) {
    setCurrentAddressFromOrder(order.address)
  }

  if (order.payment_type === 1) {
    try {
      new PaytureWidget({
        Key: PAYTURE_KEY,
        Domain: PAYTURE_DOMAIN,
        Amount: order.meta.totalSum,
        Product: 'EdaElista.ru',
        CustomParams: {
          TemplateTag: 'Widget',
          OrderNumber: order.id,
        },
        OnTransactionCompleted: function (success) {
          if (success) {
            orderConfirmed()
            loadOrdersEffect()
          }
        },
      })
    } catch (e) {
      console.error(e)
    }
  } else {
    orderConfirmed()
  }
})

export const checkPromoFx = createEffect({
  handler: async ({ code, shopId }) => {
    const loweredCode = code.toLowerCase()
    const res = await axios.post(
      `/api/customer/check_promocode/${shopId}`,
      { code: loweredCode },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${$token.getState()}`,
        },
      },
    )

    return res.data
  },
})

export const setPromoCodeValue = createEvent('set promo code value')
export const setPromoModalShown = createEvent('set promo modal shown')
export const resetPromoCode = createEvent('reset promo code')
const setPromoError = createEvent('set handle error')

export const $isPromoModalShown = createStore(false)
export const $promoErrorText = createStore('')
export const $promoCodeValue = createStore('')
export const $confirmedPromoCode = createStore({})

export const bonusModeToggled = createEvent('bonus mode toggled')
export const $bonusMode = createStore(false)
  .on(bonusModeToggled, value => !value)
  .reset(changeOrderPaymentType)

$promoCodeValue
  .on(setPromoCodeValue, (_, value) => value)
  .reset(setPromoModalShown)

$isPromoModalShown
  .on(setPromoModalShown, (_, bool) => bool)
  .on($confirmedPromoCode, (_, promo) => {
    if (promo.code) {
      return false
    }
  })

$promoErrorText
  .on(checkPromoFx.fail, (_, result) => {
    if (result.error.response.data) {
      return result.error.response.data
    }
  })
  .on(setPromoError, (_, message) => message)
  .reset([$isPromoModalShown, $promoCodeValue])

$confirmedPromoCode
  .on(checkPromoFx.done, (_, { result }) => {
    const minOrderSum = result.minOrderSum
    const cartTotalPrice = $cartTotalPrice.getState()
    const isMobileClient = $isMobileClient.getState()
    if (cartTotalPrice <= minOrderSum) {
      setPromoError(`Промокод применим только к заказу от ${minOrderSum} ₽`)
      return {}
    }
    if (!isMobileClient && result.mobile) {
      setPromoError('Промокод можно применить только в мобильном приложении')
      return {}
    }

    return result
  })
  .on($cartTotalPrice, (promo, cartTotal) => {
    if (promo.minOrderSum) {
      if (cartTotal < promo.minOrderSum) {
        setPromoCodeValue('')
        return {}
      }
    }
    return promo
  })
  .reset([openRestaurantEvent, resetPromoCode])

export const $order = combine({
  basket: $basketData,
  contact: $customerPhone,
  name: $customerName,
  address: $currentAddress,
  distance: $currentDistance,
  persons: $orderPersons,
  change: $orderChange,
  takeaway: $takeawayMode,
  takeawayAddress: $takeawayAddress,
  decreaseBonuses: $bonusMode,
  promo: $confirmedPromoCode.map(({ code }) => (code ? code : '')),
  payment_type: $orderPaymentType,
  deferredTo: $selectedTime.map(({ time, value }) =>
    value === -1 ? null : time,
  ),
})
  .on(orderConfirm, order => {
    orderConfirmEffect(order)
  })
  .reset(orderConfirmed)

function setFromLocalStorage(key, setterEvent) {
  try {
    const value = localStorage.getItem(key)
    if (!value) {
      throw new Error(`no ${key}`)
    }
    setterEvent(value)
  } catch (e) {
    console.error(e)
  }
}

export const onOrderConfirmClick = createEvent('on order confirm click')
export const closeCheckoutTimeWarning = createEvent(
  'close checkout time warning',
)
export const $checkoutTimeWarning = createStore(false)
$checkoutTimeWarning.reset(closeCheckoutTimeWarning)

sample({
  source: combine({
    selectedTimeValue: $selectedTime.map(({ value }) => value),
    currentRestaurant: $currentRestaurant,
  }),
  clock: onOrderConfirmClick,
  fn: ({ selectedTimeValue, currentRestaurant }) => {
    if (selectedTimeValue !== currentRestaurant.deliveryToValue) {
      return true
    }
    orderConfirm()
    return false
  },
  target: $checkoutTimeWarning,
})

export const $finalCheckPrice = createStore(0)

sample({
  source: combine({
    cartTotalPrice: $cartTotalPrice,
    deliveryFee: $deliveryFee,
    promocode: $confirmedPromoCode,
    paymentType: $orderPaymentType,
    takeawayMode: $takeawayMode,
    takeawayPriceReduction: $takeawayPriceReduction,
    bonusMode: $bonusMode,
    bonuses: $bonuses,
  }),
  fn: ({
    cartTotalPrice,
    deliveryFee,
    promocode,
    paymentType,
    takeawayPriceReduction,
    takeawayMode,
    bonusMode,
    bonuses,
  }) => {
    const total = cartTotalPrice || 0
    const fee = takeawayMode ? 0 : deliveryFee || 0
    const promo =
      promocode.discount && paymentType === 1 ? promocode.discount : 0
    const bonusDiscount =
      paymentType === 1 && bonusMode && bonuses > 0 ? bonuses : 0
    const result = total + fee - promo - takeawayPriceReduction - bonusDiscount

    if (result < 1) {
      return 1
    }

    return result
  },
  target: $finalCheckPrice,
})

const orderFormFileds = [
  {
    key: customerNameKey,
    setterEvent: changeCustomerName,
  },
  {
    key: paymentTypeKey,
    setterEvent: changeOrderPaymentType,
  },
  {
    key: orderPersonsKey,
    setterEvent: changeOrderPersons,
  },
]

export function loadLocalStorage() {
  orderFormFileds.forEach(({ key, setterEvent }) =>
    setFromLocalStorage(key, setterEvent),
  )
}

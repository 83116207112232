import React from 'react'
import styled, { css } from 'styled-components'

import checkmark from '../../../img/checkmark.svg'

const checkboxStyle = css`
  background-color: var(--main-accent-color);
  background-image: url("${checkmark}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
`

const radioStyle = css`
  border-width: 6px;
`

const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    opacity: 0;
  }

  span {
    position: relative;
    display: block;
    padding-left: calc(20px + 0.5rem);
    font-size: 16px;
    line-height: 20px;
  }

  span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--main-background-color);
    border: 1px solid var(--border-color);
    border-radius: ${({ optionType }) =>
      optionType === 'radio' ? '50%' : '4px'};
    border-color: ${({ danger }) => danger && 'var(--danger-color)'};
    transition: background-color 0.2s, border-color 0.2s;
  }

  input:checked + span::before {
    border-color: var(--main-accent-color);
    ${({ optionType }) => (optionType === 'radio' ? radioStyle : checkboxStyle)}
  }

  small {
    margin-left: 1rem;
    color: var(--secondary-text-color);
    font-size: 1rem;
  }
`

function Option({
  label,
  type = 'checkbox',
  name,
  value,
  onChange,
  required,
  price,
  forwardRef,
  customValidity,
}) {
  return (
    <Label optionType={type} danger={customValidity}>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        price={price}
        ref={forwardRef}
      />
      <span>
        {label}
        <small>+{price}&nbsp;₽</small>
      </span>
    </Label>
  )
}

export default Option

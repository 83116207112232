import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import InputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'

import Button from '../Button'

import {
  $codeExpirationTime,
  $codeCheckError,
  loginWithPhoneFx,
  loginCheckFx,
} from '../../../store/auth'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`

const ErrorMessage = styled.span`
  display: block;
  width: 100%;
  height: 1.25rem;
  margin-bottom: 10px;
  color: var(--danger-color);
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
`

const PhoneMask = styled(InputMask)`
  height: 50px;
  margin-bottom: 10px;
  padding: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  background-color: var(--main-background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: none;
  appearance: none;

  &:focus {
    border-color: var(--main-accent-color);
  }

  &::placeholder {
    color: var(--secondary-text-color);
    transition: color 0.2s;
  }

  &:focus::placeholder {
    color: transparent;
  }
`

const InstructionText = styled.span`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
`

const StyledButton = styled(Button)`
  height: 50px;
  margin-bottom: 30px;
  font-size: 1.125rem;
  font-weight: bold;
`

const Warning = styled.p`
  margin: 0;
  margin-bottom: 20px;
  padding: 0 1rem;
  color: var(--secondary-text-color);
  font-size: 0.75rem;
  text-align: center;
`

const Repeat = styled.span`
  display: block;
  color: var(--secondary-text-color);
  font-size: 1rem;
  text-align: center;
`

const RepeatButton = styled.button`
  display: inline;
  padding: 0;
  appearance: none;
  color: var(--main-accent-color);
  font: inherit;
  background: transparent;
  border: 0;
`

const CODE_MASK = '9999'

function CheckCodeForm({ phone }) {
  const codeExpirationTime = useStore($codeExpirationTime)
  const codeCheckError = useStore($codeCheckError)

  const { register, handleSubmit, errors } = useForm()
  return (
    <form
      onSubmit={handleSubmit(({ code }) => {
        try {
          loginCheckFx({ phone, code })
        } catch (e) {
          console.error(e)
        }
      })}
    >
      <FormContainer>
        <InstructionText>Введите код подтверждения</InstructionText>
        <ErrorMessage>
          {codeCheckError || (errors.code && errors.code.message)}
        </ErrorMessage>
        <PhoneMask
          ref={register({
            validate: value =>
              value.replace(/\D+/g, '').length === CODE_MASK.length ||
              'Код введён не полностью',
          })}
          name="code"
          type="tel"
          mask={CODE_MASK}
          placeholder="Введите код из СМС"
        />
        <StyledButton type="submit" disabled={false}>
          Далее
        </StyledButton>
        <Warning>
          Код подтверждения был отправлен на&nbsp;номер +{phone}
        </Warning>
        <Repeat>
          {codeExpirationTime > 0 ? (
            `Повторный запрос через ${codeExpirationTime} сек.`
          ) : (
            <RepeatButton type="button" onClick={() => loginWithPhoneFx({ phone, code: window.recaptchaValue })}>
              Отправить код повторно
            </RepeatButton>
          )}
        </Repeat>
      </FormContainer>
    </form>
  )
}

export default CheckCodeForm

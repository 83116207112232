import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-body-width);
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: ${({ isShown }) =>
    isShown ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
  pointer-events: ${({ isShown }) => !isShown && 'none'};
  transition: background 0.4s;
`

function ModalOverlay({ isShown, onClick, children }) {
  function onOverlayClick(e) {
    if (e.target === e.currentTarget) {
      onClick()
    }
  }

  return (
    <Overlay isShown={isShown} onClick={onOverlayClick}>
      {children}
    </Overlay>
  )
}

export default ModalOverlay

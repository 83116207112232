import styled from 'styled-components'

const NavList = styled.div`
  display: grid;
  border-top: 1px solid var(--border-color);

  @media (max-width: 374px) {
    font-size: 0.9rem;
  }
`

export default NavList

import init from './init'
import createState from './state'

const state = createState()
init(state)
export const {
  $timeIntervals,
  $selectedTime,
  timeSelected,
  initTimeIntervals,
} = state

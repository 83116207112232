import React from 'react'

function StarIcon({ width = 12, height = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 0l1.587 3.816 4.12.33-3.14 2.688.96 4.02L6 8.7l-3.527 2.154.96-4.02-3.14-2.688 4.12-.33L6 0z" />
    </svg>
  )
}

export default StarIcon

import React, { useEffect, useState } from 'react'
import { Link, Redirect } from '@reach/router'
import styled from 'styled-components'
import { useList, useStore } from 'effector-react'

import Layout from './shared/Layout'
import Header from './Cart/Header'
import DeliveryFee from './Cart/DeliveryFee'
import CartItem from './Cart/CartItem'
import FixedFooter from './shared/FixedFooter'
import FlowButton from './shared/FlowButton'
import Total from './Cart/Total'
import Counter from './shared/Counter'
import ItemModal from './shared/ItemModal'
import DialogModal from './shared/DialogModal'
import AuthModal from './shared/AuthModal'

import {
  $token,
  $cart,
  $currentRestaurantId,
  $orderPersons,
  $takeawayMode,
  $takeawayPriceReduction,
} from '../store'
import { changeOrderPersons } from '../store/order'
import {
  $cartTotalPrice,
  $deliveryFee,
  itemClick,
  clearCart,
  $shopDeliveryDistanceFeesObjectStore,
  $specialGift,
} from '../store/cart'
import { $loginStep, openLogin } from '../store/auth'
import TakeawayAddressSelect from './shared/TakeawayAddressSelect'
import { isWithinInterval } from 'date-fns'
import { Fragment } from 'react'

const Special = styled.div`
  margin: 0 1rem 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background-color: lightyellow;
  border: 1px solid orange;
  border-radius: 0.5rem;
`

const Tableware = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
`

const GiftsList = styled.ul`
  margin: 0.5rem 0 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 1rem;
    color: var(--secondary-text-color);

    &::before {
      content: '•';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  li:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

const isWithinLottery = isWithinInterval(new Date(), {
  start: new Date(2021, 4, 1),
  end: new Date(2021, 6, 30),
})

function CartLayout() {
  const token = useStore($token)
  const loginStep = useStore($loginStep)
  const totalPrice = useStore($cartTotalPrice)
  const deliveryFeeStore = useStore($deliveryFee)
  const orderPersons = useStore($orderPersons)
  const specialGift = useStore($specialGift)
  const takeawayMode = useStore($takeawayMode)
  const takeawayPriceReduction = useStore($takeawayPriceReduction)
  const shopDeliveryDistanceFeesObjectStore = useStore(
    $shopDeliveryDistanceFeesObjectStore,
  )
  const [clearDialog, setClearDialog] = useState(false)
  const minOrderCost =
    shopDeliveryDistanceFeesObjectStore &&
    Math.min(...Object.keys(shopDeliveryDistanceFeesObjectStore))
  const isNotMinOrderCost = totalPrice < minOrderCost
  const deliveryFee = isNotMinOrderCost
    ? shopDeliveryDistanceFeesObjectStore[minOrderCost]
    : deliveryFeeStore
  const deliveryFeesKeys =
    shopDeliveryDistanceFeesObjectStore &&
    Object.keys(shopDeliveryDistanceFeesObjectStore)
      .map(el => +el)
      .sort((a, b) => b - a)
  const nextDeliveryStep =
    deliveryFeesKeys &&
    Math.min(...deliveryFeesKeys.filter(key => totalPrice < key))
  const nextDeliveryFee = {}

  if (shopDeliveryDistanceFeesObjectStore && nextDeliveryStep !== Infinity) {
    nextDeliveryFee.value = nextDeliveryStep
    nextDeliveryFee.fee = shopDeliveryDistanceFeesObjectStore[nextDeliveryStep]
  }

  function checkoutButtonHandler(e) {
    if (!token && loginStep === 0) {
      e.preventDefault()
      openLogin()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Header onClear={() => setClearDialog(true)} />
      <div>
        <div>
          {useList($cart, item => (
            <CartItem
              key={`cart-item-${item.id}`}
              item={item}
              onClick={() => itemClick({ item: { ...item, inCart: true } })}
            />
          ))}
        </div>
        {specialGift.length > 0 && (
          <div
            style={{
              padding: '1rem',
              borderBottom: '1px solid var(--border-color)',
            }}
          >
            <strong style={{ color: 'var(--main-accent-color)' }}>
              В подарок
            </strong>
            <GiftsList>
              {specialGift.map(item => (
                <li key={`cart-gift-${item.id}`}>{item.title}</li>
              ))}
            </GiftsList>
          </div>
        )}
        {takeawayMode ? (
          <TakeawayAddressSelect
            showCaption
            showDiscount
            style={{ padding: '1rem' }}
          />
        ) : (
          <DeliveryFee
            totalPrice={totalPrice}
            deliveryFee={deliveryFee}
            nextDeliveryFee={nextDeliveryFee}
          />
        )}
        <Total
          number={
            takeawayMode
              ? totalPrice - takeawayPriceReduction
              : totalPrice + deliveryFee
          }
        />
        {isWithinLottery && totalPrice ? (
          <Special>
            {totalPrice > 800 ? (
              <Fragment>
                Ваш заказ будет участвовать в розыгрыше{' '}
                <strong>iPhone 12</strong>
              </Fragment>
            ) : (
              <Fragment>
                Закажите ещё на <strong>{800 - totalPrice}</strong> рублей,
                чтобы участвовать в розыгрыше <strong>iPhone 12</strong>.
              </Fragment>
            )}{' '}
            <Link to="/">Подробнее в сториз</Link>
          </Special>
        ) : null}
      </div>
      <FixedFooter isShown={true}>
        <Tableware>
          <span>{'Приборы'}</span>
          <Counter
            number={orderPersons}
            onIncrease={() => changeOrderPersons(Number(orderPersons) + 1)}
            onDecrease={() => changeOrderPersons(Number(orderPersons) - 1)}
            decDisabled={orderPersons < 1}
            incDisabled={orderPersons > 19}
          />
        </Tableware>
        <FlowButton
          as={Link}
          to="/checkout"
          text={
            isNotMinOrderCost
              ? `До минимального заказа ${minOrderCost - totalPrice} ₽`
              : 'Далее'
          }
          right={
            !isNotMinOrderCost &&
            `${totalPrice - takeawayPriceReduction}${
              deliveryFee !== 0 && !takeawayMode ? ` + ${deliveryFee}` : ''
            } ₽`
          }
          disabled={isNotMinOrderCost}
          onClick={e => {
            checkoutButtonHandler(e)
          }}
        />
      </FixedFooter>
      <ItemModal inCart />
      <DialogModal
        isShown={clearDialog}
        danger={true}
        dialog="Удалить все товары из корзины?"
        okText="Удалить"
        cancelText="Отмена"
        onOk={clearCart}
        onCancel={() => setClearDialog(false)}
      />
      <AuthModal isShown={!token && loginStep > 0} />
    </Layout>
  )
}

function Cart() {
  const cart = useStore($cart)
  const currentRestaurantId = useStore($currentRestaurantId)

  const redirectPath = currentRestaurantId
    ? `/restaurant/${currentRestaurantId}`
    : '/'

  return cart.length > 0 ? (
    <CartLayout />
  ) : (
    <Redirect to={redirectPath} noThrow />
  )
}

export default Cart

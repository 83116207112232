import axios from 'axios'
import { $stories, loadStoriesFx } from '.'

loadStoriesFx.use(async () => {
  const { data } = await axios.get('/api/stories')

  if (data.code === 'ok') {
    return data.data
  } else {
    throw new Error(data?.message)
  }
})

$stories.on(loadStoriesFx.done, (_, { result }) =>
  result.map(item => ({ ...item, duration: 0 })),
)

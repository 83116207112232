import React from 'react'
import styled from 'styled-components'

import VkIcon from '../../shared/VkIcon'
import InstagramIcon from '../../shared/InstagramIcon'

const StyledFooter = styled.footer`
  padding: 1rem;
`

const Socials = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  a {
    display: block;
    width: 24px;
    height: 24px;
    color: var(--tertiary-text-color);
  }

  svg {
    fill: currentColor;
  }
`

const Copyright = styled.span`
  display: block;
  color: var(--tertiary-text-color);
  font-size: 0.9rem;
  text-align: center;
`

function Footer() {
  return (
    <StyledFooter>
      <Socials>
        <a
          href="https://vk.com/edaelistaru"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VkIcon />
        </a>
        <a
          href="https://www.instagram.com/edaelista.ru"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
      </Socials>
      <Copyright>&copy; {new Date().getFullYear()} Компания Oxitech</Copyright>
    </StyledFooter>
  )
}

export default Footer

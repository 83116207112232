import React from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import StickyHeader from '../shared/StickyHeader'
import GoBackButton from '../shared/GoBackButton'
import EraseButton from './Header/EraseButton'
import { textOverflow } from '../shared/styleMixins'

import { $cartShop } from '../../store/cart'

const Title = styled.div`
  ${textOverflow}
`

function Header({ onClear }) {
  const cartShop = useStore($cartShop)

  return (
    <StickyHeader>
      <GoBackButton backPath={cartShop ? `/restaurant/${cartShop.id}` : '/'} />
      <Title>
        <strong>{cartShop ? cartShop.title : 'Загрузка…'}</strong>
      </Title>
      <EraseButton onClick={onClear} />
    </StickyHeader>
  )
}

export default Header

import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

import { badgeElement } from '../../shared/styleMixins'

const Button = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  font-size: 1rem;
  text-decoration: none;
  background-color: var(--main-background-color);
  border: 0;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  ${({ badge }) => (badge ? badgeElement : undefined)}
`

const IconContainer = styled.span`
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 1rem;
`

function MenuItem({ badge, type = 'button', href, icon, text, path, onClick }) {
  const elType = type === 'link' ? Link : type

  return (
    <Button as={elType} to={path} href={href} onClick={onClick} badge={badge}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <span>{text}</span>
    </Button>
  )
}

export default MenuItem

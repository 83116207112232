import styled from 'styled-components'

const Layout = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  min-height: 100vh;
  background: var(--main-background-color);
`

export default Layout

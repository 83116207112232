import React from 'react'
import styled from 'styled-components'

// import MoreInfoButton from './MoreInfoButton'

const Container = styled.div`
  padding: 1rem;
`

const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;

  h2 {
    margin: 0;
    font-size: 1.25rem;
    line-height: 125%;
  }
`

const TagsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  font-size: 0.875rem;

  & > * {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

function Info({ title, children, workTimeWeek, forwardRef }) {
  return (
    <Container ref={forwardRef}>
      <Title>
        <h2>{title}</h2>
        {/* <MoreInfoButton /> */}
      </Title>
      <TagsGrid>{children}</TagsGrid>
    </Container>
  )
}

export default Info

import React, { useEffect, useRef, useState } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Portal from './Portal'
import ModalOverlay from './ModalOverlay'
import Divider from './Divider'
import RatingPicker from './RatingPicker'
import Button from './Button'
import TextArea from './TextArea'
import Checkbox from './Checkbox'

import {
  $selectedOrder,
  $feedbackFormScore,
  $feedbackFormComment,
  $feedbackFormAnon,
} from '../../store'
import {
  feedbackConfirm,
  changeFeedbackScore,
  changeFeedbackComment,
  changeFeedbackAnon,
} from '../../store/feedback'

const Modal = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  width: 90%;
  max-width: 400px;
  height: auto;
  margin: auto;
  padding: 1rem;
  background: var(--main-background-color);
  border-radius: 10px;
  box-shadow: 0 0 20px var(--shadow-color);
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transition: opacity 0.2s;
`

function RatingModal({ isShown, onCloseClick }) {
  const selectedOrder = useStore($selectedOrder)
  const feedbackFormScore = useStore($feedbackFormScore)
  const feedbackFormComment = useStore($feedbackFormComment)
  const feedbackFormAnon = useStore($feedbackFormAnon)

  const targetRef = useRef()
  let [targetElement, setTargetElement] = useState(null)

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isShown) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isShown, targetElement])

  function onSubmit() {
    feedbackConfirm()
    onCloseClick()
  }

  return (
    <Portal>
      <ModalOverlay isShown={isShown} onClick={onCloseClick}>
        <Modal isShown={isShown}>
          <strong>{selectedOrder && selectedOrder.restaurant.title}</strong>
          <Divider />
          <RatingPicker
            currentValue={feedbackFormScore}
            onChange={changeFeedbackScore}
          />
          <TextArea
            id="feedback-comment"
            name="feedback_comment"
            label="Ваш комментарий"
            value={feedbackFormComment}
            onChange={changeFeedbackComment}
            maxHeight={90}
          />
          <Checkbox
            label="Анонимный отзыв"
            inputName="feedback_anon"
            checked={feedbackFormAnon}
            onChange={() => changeFeedbackAnon(!feedbackFormAnon)}
          />
          <Button type="button" onClick={onSubmit}>
            Подтвердить
          </Button>
        </Modal>
      </ModalOverlay>
    </Portal>
  )
}

export default RatingModal

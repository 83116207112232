import axios from 'axios'
import { navigate } from '@reach/router'

import { $token, createCountdown, $bonuses, $bonusLevel } from './index'
import { createEffect, createEvent, createStore } from 'effector'
import { withdrawalConfirmed } from './withdrawal'

export const tokenKey = 'customer_token'

export const openLogin = createEvent('open login modal')
export const closeLogin = createEvent('close login modal')
export const startCodeTimer = createEvent('start code timer')
export const abortCodeTimer = createEvent('start code timer')
export const codeTimerCountdown = createEvent('countdown code timer')
export const setUrlParams = createEvent('setting url params')

export const $loginStep = createStore(0)
export const $codeExpirationTime = createStore(0)
export const $phoneConfirmError = createStore(null)
export const $codeCheckError = createStore(null)
export const $customerPhone = createStore(null)
export const $urlParams = createStore({})
export const $isMobileClient = createStore(false)
export const mobileClientChecked = createEvent('mobile client checked')

const ErrorToMessage = {
  406: 'Неверный код подтверждения',
  500: 'Ошибка сервера',
}

const countdown = createCountdown('code timer', {
  start: startCodeTimer,
  abort: abortCodeTimer,
})

countdown.tick.watch(() => {
  codeTimerCountdown()
})

export const loginEffect = createEffect({
  handler: async urlParams => {
    const res = await axios.post(
      '/api/customer/generate_customer_token',
      urlParams,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res.data.data.token
  },
})

export const checkSession = createEffect({
  handler: async token => {
    const res = await axios.get('/api/customer/check-session/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    setTokenFromLocalstorage(token)
    saveLastUtmEffect($urlParams.getState())
    return res.data.data
  },
})

export const updateBonusesFx = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/bonus', {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data
  },
})

$customerPhone.on(checkSession.done, (_, { result }) => result.phone)
$bonuses
  .on(checkSession.done, (_, { result }) => result.bonuses)
  .on(updateBonusesFx.done, (_, { result }) => result)
  .on(withdrawalConfirmed, () => 0)
$bonusLevel.on(checkSession.done, (_, { result }) => result.bonusLevel)

$urlParams.on(setUrlParams, (_, urlParams) => urlParams)

$urlParams.watch(({ utm_source }) => {
  const isMobileDomain = document.location.host.includes('mobile')
  if (utm_source === 'mobile' && !isMobileDomain) {
    navigate('/app-update?utm_source=mobile')
  }
})

export const loginWithPhoneFx = createEffect({
  handler: async ({phone, code}) => {
    // When no not mobile && code === undefined
    // Check code on the backend
    // if (!code) {
    //   alert("Нужен код капчи")
    // }

    const res = await axios.post(
      '/api/customer/phone_confirm',
      { phone, code },
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    )
    return res.data.data
  },
})

export const loginCheckFx = createEffect({
  handler: async checkParams => {
    const res = await axios.post(
      '/api/customer/phone_check',
      { ...checkParams, utm: $urlParams.getState() },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res.data.data
  },
})

export const logoutFx = createEffect({
  handler: async () => {
    await axios.get('/api/customer/logout/', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
  },
})

export const saveLastUtmEffect = createEffect({
  handler: async urlParams => {
    const res = await axios.put('/api/customer/last-utm', urlParams, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const loadCustomerInfoFx = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/info', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    return res.data.data
  },
})

export const addCustomerAddressFx = createEffect({
  handler: async ({ address, distance, shopId }) => {
    const res = await axios.post(
      `/api/customer/address/shops/${shopId}`,
      { address, distance },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${$token.getState()}`,
        },
      },
    )
    return res.data.data
  },
})

export const setTokenFromLocalstorage = createEvent('token from localStorage')

$loginStep
  .on(closeLogin, () => 0)
  .on(openLogin, () => 1)
  .on(loginWithPhoneFx.done, () => 2)
  .on(loginCheckFx.done, () => {
    if (document.location.pathname.includes('/cart')) {
      navigate('/checkout')
    }
    return 0
  })

$phoneConfirmError
  .on(loginWithPhoneFx.fail, (_, { error }) => error.response.data.data)
  .reset([$loginStep, loginWithPhoneFx.done])

$codeCheckError
  .on(loginCheckFx.fail, (_, { error }) => ErrorToMessage[error.request.status])
  .reset([$loginStep, loginCheckFx.done])

$codeExpirationTime
  .on(loginWithPhoneFx.done, (_, { result }) => {
    startCodeTimer(result)
    return result
  })
  .on(codeTimerCountdown, prev => {
    if (prev > 0) {
      return prev - 1
    } else {
      return 0
    }
  })

$token
  .on(loginCheckFx.done, (_, { result }) => {
    checkSession(result)
    return result
  })
  .on(setTokenFromLocalstorage, (_, token) => token)
  .reset(logoutFx.done)

$token.updates.watch(token => {
  if (token !== null) {
    localStorage.setItem(tokenKey, JSON.stringify(token))
  } else {
    localStorage.removeItem(tokenKey)
  }
})

$isMobileClient.on(mobileClientChecked, (_, bool) => bool)

import React from 'react'
import Autosuggest from 'react-autosuggest'

import './Autocomplete/theme.css'

function getSuggestionValue(suggestion) {
  return suggestion.value
}

function renderSuggestion(suggestion) {
  return <div>{suggestion.line}</div>
}

function Autocomplete({
  inputRef,
  inputName,
  suggestions = [],
  getSuggestions,
  value,
  setValue,
  selectionHandler,
}) {
  function onSuggestionsFetchRequested({ value }) {
    getSuggestions(value)
  }

  function onChange(_, { newValue }) {
    return setValue(newValue)
  }

  const inputProps = {
    value,
    onChange,
    placeholder: 'Начните вводить адрес',
    ref: inputRef,
    name: inputName,
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={selectionHandler || getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      alwaysRenderSuggestions={true}
    />
  )
}

export default Autocomplete

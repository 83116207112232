import axios from 'axios'
import { createEffect } from 'effector'

import { $restaurantCategories } from './index'

export const loadRestaurantCategories = createEffect({
  handler: async () => {
    const res = await axios.get('/api/customer/restaurant-categories')
    return res.data.data
  },
})

$restaurantCategories.on(
  loadRestaurantCategories.done,
  (_, { result }) => result,
)

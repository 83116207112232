import React from 'react'
import styled from 'styled-components'

import RadioButton from '../shared/RadioButton'

const Label = styled.label`
  display: grid;
  grid-template-columns: 32px 1fr auto;
  grid-column-gap: 1rem;
  align-items: start;
  padding: 1rem;
  background: var(--main-background-color);
  border-radius: 10px;
  cursor: pointer;
`

const IconContainer = styled.div`
  line-height: 0;
`

const Title = styled.span`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`

const Nested = styled.div`
  display: ${({ isShown }) => !isShown && 'none'};
  padding: 0 calc(32px + 1rem);
  padding-right: 1rem;
`

function PaymentMethodSelect({
  onChange,
  inputRef,
  inputName,
  title,
  icon,
  value,
  isSelected,
  children,
}) {
  return (
    <div>
      <Label>
        <IconContainer>{icon}</IconContainer>
        <div>
          <Title>{title}</Title>
        </div>
        <ButtonContainer>
          <RadioButton
            ref={inputRef}
            type="radio"
            name={inputName}
            checked={isSelected}
            value={value}
            onChange={e => onChange(Number(e.target.value))}
          />
        </ButtonContainer>
      </Label>
      <Nested isShown={isSelected}>{children}</Nested>
    </div>
  )
}

export default PaymentMethodSelect

import {
  eachHourOfInterval,
  addMinutes,
  format,
  endOfToday,
  startOfTomorrow,
  endOfTomorrow,
  isAfter,
} from 'date-fns'
import { sample } from 'effector'

const init = ({
  $timeIntervals,
  $selectedTime,
  timeSelected,
  initTimeIntervals,
}) => {
  $timeIntervals.on(initTimeIntervals, () => {
    const startOfInterval = new Date()
    return [
      { title: 'Сейчас', value: -1, time: new Date() },
      ...[
        ...eachHourOfInterval({
          start: startOfInterval,
          end: endOfToday(),
        })
          .reduce((acc, date) => {
            acc.push(date)
            acc.push(addMinutes(date, 30))
            return acc
          }, [])
          .map(date => ({
            title: format(date, 'HH:mm сегодня'),
            time: date,
          })),
        ...eachHourOfInterval({
          start: startOfTomorrow(),
          end: endOfTomorrow(),
        })
          .reduce((acc, date) => {
            acc.push(date)
            acc.push(addMinutes(date, 30))
            return acc
          }, [])
          .map(date => ({
            title: format(date, 'HH:mm завтра'),
            time: date,
          })),
      ]
        .filter(({ time }) => isAfter(time, startOfInterval))
        .map(item => ({ ...item, value: item.time.getTime() })),
    ]
  })

  sample({
    source: $timeIntervals,
    clock: timeSelected,
    fn: (timeIntervals, value) => {
      return timeIntervals.find(item => {
        return item.value === value
      })
    },
    target: $selectedTime,
  })
}

export default init

import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import Portal2 from './Portal2'
import ModalOverlay from './ModalOverlay'
import StickyHeader, { Title } from './StickyHeader'
import Button from './Button'
import GoBackButton from './GoBackButton'
import Input from './Input'
import TextArea from './TextArea'
import {
  addAddressFx,
  updateAddressFx,
  deleteAddressFx,
} from '../../store/addresses'

const Modal = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  background: var(--main-background-color);
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transform: ${({ isShown }) => (isShown ? 'scale(1)' : 'scale(0.9)')};
  transform-origin: top center;
  transition: transform 0.2s, opacity 0.4s;
  pointer-events: ${({ isShown }) => !isShown && 'none'};
`

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 1rem;

  & > .full-width {
    grid-column-start: 1;
    grid-column-end: -1;
  }
`

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: -1rem;

  & > * {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  & > *:only-child {
    width: 100%;
  }

  & > *:not(:only-child) {
    width: calc(50% - 1rem);
  }
`

function AddressEditModal({ isShown, cancelHandler, selectedAddress }) {
  const { register, handleSubmit } = useForm({
    defaultValues: selectedAddress,
  })

  function onSubmit(value) {
    const { id } = selectedAddress
    if (id) {
      updateAddressFx({ ...value, id })
    } else {
      addAddressFx({ ...value, ...selectedAddress })
    }
    cancelHandler()
  }

  return (
    <Portal2>
      <ModalOverlay isShown={isShown}>
        <Modal isShown={isShown}>
          <StickyHeader>
            <GoBackButton as="button" type="button" onClick={cancelHandler} />
            <Title>
              <strong>{selectedAddress.line}</strong>
            </Title>
          </StickyHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <div className="full-width">
                <Input id="line" name="line" inputRef={register()} disabled />
              </div>
              <Input
                id="office"
                name="office"
                type="text"
                label="Кв./офис"
                inputRef={register()}
                maxLength={5}
              />
              <Input
                id="doorcode"
                name="doorcode"
                type="text"
                label="Домофон"
                inputRef={register()}
                maxLength={20}
              />
              <Input
                id="entrance"
                name="entrance"
                type="text"
                label="Подъезд"
                inputRef={register()}
                maxLength={20}
              />
              <Input
                id="floor"
                name="floor"
                type="text"
                label="Этаж"
                inputRef={register()}
                maxLength={5}
              />
              <div className="full-width">
                <TextArea
                  id="comment"
                  name="comment"
                  placeholder="Комментарий к заказу"
                  inputRef={register()}
                />
              </div>
              <Footer className="full-width">
                {selectedAddress.id && (
                  <Button
                    danger
                    type="button"
                    onClick={() => deleteAddressFx(selectedAddress.id)}
                  >
                    Удалить
                  </Button>
                )}
                <Button type="submit">Сохранить</Button>
              </Footer>
            </FormContainer>
          </form>
        </Modal>
      </ModalOverlay>
    </Portal2>
  )
}

export default AddressEditModal

import styled from 'styled-components'

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  padding: 0.5rem 1rem;
  line-height: 0;
  background: var(--main-background-color);
  border: none;
  cursor: pointer;

  & > * {
    flex-shrink: 0;
  }
`

export default HeaderButton

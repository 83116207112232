import React, { useRef, useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useStore } from 'effector-react'
import debounce from 'lodash.debounce'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Portal from './Portal'
import Portal2 from './Portal2'
import ModalOverlay from './ModalOverlay'
import Autocomplete from './Autocomplete'
import AddressEditModal from './AddressEditModal'

import {
  $selectedAddress,
  selectAddress,
  $customerAddresses,
  $addressEditModal,
  setAddressEditModal,
  $addressSuggestions,
  setAddressSuggestions as setSuggestions,
} from '../../store/addresses'
import { setCurrentAddress } from '../../store/addresses'

const IosFix = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`

const Modal = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  background: var(--main-background-color);
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transform: ${({ isShown }) => (isShown ? 'scale(1)' : 'scale(0.9)')};
  transform-origin: top center;
  transition: transform 0.2s, opacity 0.4s;
  pointer-events: ${({ isShown }) => !isShown && 'none'};
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 72px;
  height: 56px;
  background: none;
  background-color: var(--main-background-color);
  border: 1px solid var(--border-color);
  border-top: 0;
  border-right: 0;
  border-radius: 0;
  cursor: pointer;
  appearance: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 24px;
    height: 2px;
    background-color: var(--text-color);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

function AddressModal({
  isShown,
  closeHandler,
  edit,
  level,
  onAddressSelect = () => null,
}) {
  const targetRef = useRef()
  const [targetElement, setTargetElement] = useState(null)
  const [value, setValue] = useState('')
  const customerAddresses = useStore($customerAddresses)
  const selectedAddress = useStore($selectedAddress)
  const addressEditModal = useStore($addressEditModal)
  const suggestions = useStore($addressSuggestions)

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isShown) {
      disableBodyScroll(targetElement)
      setValue('')
      if (targetElement) {
        targetElement.querySelector('input').focus()
      }
    } else {
      enableBodyScroll(targetElement)
      if (targetElement) {
        targetElement.querySelector('input').blur()
      }
    }
  }, [isShown, targetElement])

  const getSuggestions = debounce(value => {
    if (value.length > 0) {
      window.ymaps
        .suggest(value, {
          boundedBy: [
            [46.23, 44.13],
            [46.36, 44.43],
          ],
        })
        .then(res =>
          setSuggestions(
            res
              .map(({ value }) => {
                const reducedValue = value.split('Калмыкия, ')[1]
                return { line: reducedValue }
              })
              .filter(
                ({ line }) =>
                  line.length > 0 &&
                  ['Элиста', 'Троицкое'].some(place => line.includes(place)),
              ),
          ),
        )
    } else {
      setSuggestions(customerAddresses)
    }
  }, 400)

  const selectionHandler = suggestion => {
    let point = {}
    function onEdit() {
      selectAddress({ ...suggestion, ...point })
      setAddressEditModal(true)
    }
    function onSet() {
      setCurrentAddress({ ...suggestion, ...point })
      onAddressSelect()
      closeHandler()
    }
    if (!suggestion.id) {
      window.ymaps.geocode(`Элиста, ${suggestion.line}`).then(res => {
        const obj = res.geoObjects.get(0)
        const precision = obj.properties.get(
          'metaDataProperty.GeocoderMetaData.precision',
        )
        const [latitude, longitude] = obj.geometry.getCoordinates()
        point = {latitude, longitude}
        if (
          precision === 'exact' ||
          suggestion.line.includes('Элиста, Южный район, 7-й проезд, ')
        ) {
          if (edit) {
            onEdit()
          } else {
            onSet()
          }
        }
      })
      return suggestion.line
    }
    if (edit) {
      onEdit()
      return ''
    }
    onSet()
    return ''
  }

  const PortalContainer = level === 2 ? Portal2 : Portal

  return (
    <PortalContainer>
      {isShown && <IosFix />}
      <ModalOverlay isShown={isShown} onClick={closeHandler}>
        <Modal isShown={isShown} ref={targetRef}>
          <CloseButton
            type="button"
            onClick={closeHandler}
            aria-label="Закрыть меню выбора адреса"
          />
          <Autocomplete
            inputName="addressLine"
            value={value}
            setValue={setValue}
            suggestions={suggestions}
            getSuggestions={getSuggestions}
            selectionHandler={selectionHandler}
          />
        </Modal>
      </ModalOverlay>
      {edit && addressEditModal && (
        <AddressEditModal
          isShown={true}
          cancelHandler={() => setAddressEditModal(false)}
          selectedAddress={selectedAddress}
        />
      )}
    </PortalContainer>
  )
}

export default AddressModal

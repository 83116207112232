import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'

import Layout from './shared/Layout'
import Header from './Oferta/Header'
import { $isMobileClient } from '../store/auth'

const Content = styled.div`
  padding: 1rem;
  padding-bottom: 4rem;
  font-size: 0.9rem;

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  ul {
    padding-left: 1.5rem;
  }
`

function Oferta() {
  const isMobileClient = useStore($isMobileClient)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Header />
      <Content>
        <h2>Пользовательское соглашение</h2>
        <p>
          Настоящее Пользовательское Соглашение регулирует отношения между
          ИП&nbsp;Лазарев Очир Владимирович ИНН 081701809804, ОГРНИП
          316081600053799, Республика Калмыкия, г.Элиста, Северо-западная 8-ая,
          д.11 и&nbsp;пользователем сети Интернет (далее&nbsp;&mdash;
          Пользователь) по&nbsp;использованию сервиса EdaElista.ru
          (далее&nbsp;&mdash; Сервис).
        </p>
        <p>
          Перед использованием Сервиса Пользователь обязан ознакомиться
          с&nbsp;настоящим Пользовательским Соглашением и&nbsp;в&nbsp;случае
          согласия с&nbsp;положениями Пользовательского соглашения
          присоединиться к&nbsp;нему путем совершения одного из&nbsp;следующих
          конклюдентных действий:
        </p>
        <ul>
          <li>
            Нажатие кнопки &laquo;Зарегистрироваться&raquo; при регистрации
            на&nbsp;Сервисе (а&nbsp;именно: сайте{' '}
            <a href={`https://${isMobileClient ? 'mobile.' : ''}edaelista.ru`}>
              https://edaelista.ru
            </a>
            , приложении EdaElista, в&nbsp;т.&nbsp;ч., мобильном либо приложении
            в&nbsp;социальных сетях);
          </li>
          <li>
            Нажатие кнопки &laquo;Заказать&raquo; или &laquo;Оформить
            заказ&raquo; при оформлении заказа Пользователем без
            регистрации/авторизации на&nbsp;Сервисе.
          </li>
        </ul>
        <p>
          Присоединяясь к&nbsp;настоящему Пользовательскому Соглашению,
          Пользователь выражает полное и&nbsp;безоговорочное согласие
          со&nbsp;всеми его условиями, в&nbsp;том числе, в&nbsp;части
          предоставления согласия на&nbsp;обработку персональных данных
          Пользователя на&nbsp;условиях, указанных в&nbsp;разделе
          8&nbsp;настоящего Пользовательского соглашения,
          и&nbsp;на&nbsp;получение рассылок информационного и&nbsp;рекламного
          содержания.
        </p>
        <h3>Предмет Пользовательского Соглашения</h3>
        <p>
          1.1 Сервис предлагает Пользователю доступ к&nbsp;сервису быстрого
          поиска, заказа и&nbsp;доставки готовой еды на&nbsp;условиях,
          предусмотренных настоящим Пользовательским Соглашением. Указанные
          услуги носят информационный характер и&nbsp;оказываются Пользователю
          безвозмездно, за&nbsp;исключением услуг доставки (стоимость таких
          услуг указывается на&nbsp;Сервисе при оформлении заказа).
        </p>
        <p>
          1.2 Настоящее Соглашение может быть изменено в&nbsp;одностороннем
          порядке без предварительного уведомления. Новая редакция Соглашения
          вступает в&nbsp;силу с&nbsp;момента ее&nbsp;опубликования
          на&nbsp;странице сайта{' '}
          <a
            href={`https://${
              isMobileClient ? 'mobile.' : ''
            }edaelista.ru/oferta`}
          >
            https://edaelista.ru/oferta
          </a>{' '}
          если иное не&nbsp;предусмотрено новой редакцией Соглашения.
        </p>
        <p>
          1.3. Пользователь вправе отказаться от&nbsp;принятия изменений
          Соглашения, осуществленных Сервисом, что означает отказ Пользователя
          от&nbsp;использования Сервиса.
        </p>
        <p>
          1.4. Объем доступа Пользователя к&nbsp;Сервису зависит от&nbsp;региона
          и&nbsp;адреса доставки, зоны доставки Партнера, наличия конкретных
          блюд питания, нагрузки и&nbsp;режима работы Партнера и&nbsp;других
          обстоятельств, которые могут повлиять на&nbsp;функциональность Сервиса
          для конкретного Пользователя.
        </p>
        <p>
          1.5. Информация о&nbsp;меню, наличии конкретных блюд/напитков,
          их&nbsp;составе, а&nbsp;также доставке (в&nbsp;случае, если она
          осуществляется Партнером Сервиса) предоставляется Партнером. Сервис
          не&nbsp;несет ответственность за&nbsp;актуальность
          и&nbsp;достоверность такой информации.
        </p>
        <h3>Описание услуг</h3>
        <p>
          2.1 Сервис является единой информационной системой заказов, которая
          предлагает пользователям широкие возможности для поиска и&nbsp;заказа
          готовой еды, реализуемой Партнерами Сервиса, а&nbsp;также принимает
          от&nbsp;Пользователей денежные средства в&nbsp;счет оплаты заказов
          и&nbsp;осуществляет доставку заказов Пользователям силами третьих лиц
          на&nbsp;основании договора с&nbsp;Сервиса или Партнеров Сервиса.
        </p>
        <p>
          Правоотношения по&nbsp;купле-продаже готовых блюд питания
          и&nbsp;продуктов (и&nbsp;их&nbsp;доставки в&nbsp;случае, если доставку
          осуществляет Партнер) возникают непосредственно между Партнером
          Сервиса и&nbsp;Пользователем. Сервис не&nbsp;является стороной сделки
          по&nbsp;реализации товаров Партнера Пользователю, при этом отдельно
          оговаривается, что по&nbsp;поручению Партнера Сервиса вправе
          осуществлять прием платежей в&nbsp;соответствии с&nbsp;положениями
          настоящего раздела.
        </p>
        <p>
          2.2 Пользователь соглашается с&nbsp;тем, что все услуги
          предоставляются &laquo;как есть&raquo; и&nbsp;что Сервис не&nbsp;несет
          ответственность за&nbsp;качество поставляемых продуктов,
          за&nbsp;задержки, сбои, неверную или несвоевременную доставку,
          а&nbsp;также за&nbsp;сбои в&nbsp;работе платежных систем. Информация
          о&nbsp;доставке, предоставляемая Пользователю, носит информационный
          характер и&nbsp;может не&nbsp;учитывать обстоятельства,
          не&nbsp;зависящие от&nbsp;воли Сервиса, в&nbsp;том числе: задержки
          в&nbsp;связи с&nbsp;неблагоприятными метеоусловиями, дорожными
          заторами и&nbsp;пр. Также Пользователь подтверждает свое согласие
          с&nbsp;тем, что в&nbsp;период повышенного спроса (сезонного или
          вызванного распространением массовых промокодов) сроки подтверждения
          заказа и&nbsp;его доставки могут быть увеличены.
        </p>
        <p>
          2.3. Оформление заказа и&nbsp;его доставки осуществляется
          Пользователем на&nbsp;условиях, указанных на&nbsp;Сервисе,
          и&nbsp;является подтверждением согласия Пользователя
          с&nbsp;существенными условиями продажи готовых блюд питания
          и&nbsp;продуктов и&nbsp;их&nbsp;доставки.
        </p>
        <p>
          2.4 При соблюдении Пользователем условий, предусмотренных
          на&nbsp;Сервисе, стоимость доставки может не&nbsp;взиматься
          с&nbsp;Пользователя в&nbsp;связи с&nbsp;предоставлением Сервиса скидки
          в&nbsp;размере стоимости такой доставки.
        </p>
        <p>2.5 Пользователь самостоятельно оформляет Заказ на&nbsp;Сервисе.</p>
        <p>
          2.6 Пользователь оплачивает заказ одним из&nbsp;следующих доступных
          способов:
        </p>
        <p>
          2.6.1 непосредственно при получении заказа от&nbsp;службы доставки
          (Партнера Сервиса) наличными денежными средствами, банковской картой
          или иными способами, предоставленными Партнером Сервиса.
        </p>
        <p>
          2.6.2 on-line оплата на&nbsp;Сервисе банковской картой
          МИР/VISA/MasterCard через платежную систему, также Пользователям
          мобильного приложения на&nbsp;IOS-платформе доступна оплата
          с&nbsp;помощью Apple Pay. Все дополнительные расходы
          по&nbsp;перечислению денежных средств за&nbsp;заказ несет
          Пользователь.
        </p>
        <p>
          При выборе on-line оплаты после оформления Заказа Пользователь будет
          перенаправлен на&nbsp;защищенную платежную страницу ПАО
          &laquo;СБЕРБАНК&raquo; (sberbank.ru), где ему необходимо ввести данные
          его банковской карты. Для дополнительной аутентификации держателя
          карты используется протокол 3D&nbsp;Secure. Если Банк Пользователя
          поддерживает данную технологию, Пользователь будет перенаправлен
          на&nbsp;его сервер для дополнительной идентификации. Информацию
          о&nbsp;правилах и&nbsp;методах дополнительной идентификации
          Пользователь может уточнить в&nbsp;Банке, выдавшем его банковскую
          карту.
        </p>
        <p>
          Безопасность обработки интернет-платежей через платежный шлюз банка
          гарантирована международным сертификатом безопасности PCI&nbsp;DSS.
          Передача информации происходит с&nbsp;применением технологии
          шифрования SSL. Эта информация недоступна посторонним лицам.
        </p>
        <p>
          Представленная к&nbsp;оплате сумма является только стоимостью Заказа.
          Стоимость услуг (таких как доставка, сборка) взимается отдельно
          в&nbsp;момент получения Заказа.
        </p>
        <p>
          Все операции с&nbsp;платежными картами происходят в&nbsp;соответствии
          с&nbsp;требованиями VISA International, MasterCard и&nbsp;других
          платежных систем. При передаче информации используется специальные
          технологии безопасности карточных онлайн-платежей, обработка данных
          ведется на&nbsp;безопасном высокотехнологичном сервере процессинговой
          компании.
        </p>
        <p>
          Пользователь имеет возможность привязать банковскую карту
          к&nbsp;своему Личному кабинету (также по&nbsp;тексту Профиль).
          В&nbsp;дальнейшем сумма любого последующего Заказа Пользователя будет
          списываться автоматически с&nbsp;прикрепленной к&nbsp;Личному кабинету
          банковской карты без указания реквизитов, при подтверждении платежа
          с&nbsp;его стороны путем нажатия кнопки &laquo;Оплатить&raquo;
          в&nbsp;корзине Личного кабинета и&nbsp;ввода одноразового пароля,
          в&nbsp;случае, если банк-эмитент банковской карты клиента
          предусматривает использование данной защиты.
        </p>
        <p>
          2.7&nbsp;В случае отказа Пользователя от&nbsp;Заказа либо
          в&nbsp;случае нарушения Партнерами Сервиса условий реализации
          продукции, в&nbsp;том числе, но&nbsp;не&nbsp;ограничиваясь:
          в&nbsp;случае одностороннего отказа от&nbsp;реализации продукции,
          нарушения срока доставки или несоответствия качества продукции
          и&nbsp;т.п., Пользователь обязан в&nbsp;течение 24&nbsp;часов
          с&nbsp;момента наступления времени доставки/получения Заказа сообщить
          Сервису по&nbsp;e-mail адресу{' '}
          <a href="mailto:info@edaelista.ru">info@edaelista.ru</a> о&nbsp;своём
          отказе от&nbsp;Заказа, предоставить документы, подтверждающие оплату
          Заказа, и&nbsp;потребовать вернуть уплаченные денежные средства.
        </p>
        <p>
          Возврат денежных средств в&nbsp;течение&nbsp;2 (двух) дней
          с&nbsp;момента оплаты Заказа производится Сервисом.
        </p>
        <p>
          По&nbsp;истечении&nbsp;2 (двух) дней с&nbsp;момента оплаты Заказа при
          неполучении Сервисом уведомления от&nbsp;Пользователя об&nbsp;отказе
          от&nbsp;Заказа и&nbsp;возврате денежных средств, Заказ считается
          выполненным, денежные средства за&nbsp;него возврату не&nbsp;подлежат.
        </p>
        <p>
          Возврат денежных средств, оплаченных Пользователем через платежную
          систему на&nbsp;Сервисе банковской картой, осуществляется только
          на&nbsp;ту&nbsp;банковскую карту, с&nbsp;которой была произведена
          оплата, в&nbsp;соответствии с&nbsp;правилами международных платежных
          систем и&nbsp;действующим законодательством&nbsp;РФ
          о&nbsp;национальной платежной системе.
        </p>
        <p>
          2.8 Сервис вправе отказать Пользователю в&nbsp;оформлении Заказа
          с&nbsp;формой оплаты &laquo;наличными или картой курьеру&raquo;, если
          ранее оформленные Заказы Пользователя не&nbsp;были доставлены
          по&nbsp;его вине (например, если Пользователь не&nbsp;открыл дверь или
          не&nbsp;ответил по&nbsp;телефону курьеру при доставке Заказа, или
          Пользователь указал несуществующий адрес доставки и&nbsp;т.п.).
        </p>
        <p>
          2.9. Авторизация операций по&nbsp;банковским картам осуществляется
          банком. Если у&nbsp;банка есть основания полагать, что операция носит
          мошеннический характер, то&nbsp;банк вправе отказать
          в&nbsp;осуществлении данной операции. Мошеннические операции
          с&nbsp;банковскими картами являются уголовным преступлением.
        </p>
        <p>
          2.10. Во&nbsp;избежание случаев мошенничества при оплате банковскими
          картами платежи, оплаченные банковской картой, могут проверяться
          Сервисом в&nbsp;рамках имеющихся возможностей. Пользователь,
          оформивший такой платеж, обязан по&nbsp;запросу, поступившему
          от&nbsp;Сервиса, предоставить копию необходимых документов для
          подтверждения правомерного использования банковской карты.
        </p>
        <p>
          2.11. Участник Акции вправе иметь только один профиль в&nbsp;системе
          Сервиса (&laquo;запрет создания множества профилей&raquo;). Создание
          нескольких профилей для получения выгоды путем введения
          в&nbsp;заблуждение является нарушением настоящих Правил и&nbsp;может
          привести к&nbsp;немедленной блокировке всех профилей Участника,
          а&nbsp;также к&nbsp;невозможности применения всех
          указанных/использованных номеров телефонов/электронных
          адресов/банковских карт такого Участника в&nbsp;системе сервиса
          Сервиса в&nbsp;дальнейшем.
        </p>
        <h3>Обязательства по использованию Сервиса</h3>
        <p>
          3.1. Пользователь соглашается предоставить достоверную, полную
          и&nbsp;актуальную информацию по&nbsp;вопросам, предлагаемым
          в&nbsp;форме заказа и/или форме регистрации.
        </p>
        <p>
          3.2. Если Пользователь предоставляет неверную/некорректную информацию
          или у&nbsp;Сервиса есть серьезные основания полагать, что
          предоставленная им&nbsp;информация неверна, неполна или неточна,
          Сервис имеет право приостановить либо отменить регистрацию
          пользователя и&nbsp;отказать ему в&nbsp;использовании сервиса.
        </p>
        <p>
          3.3. Сервис оставляет за&nbsp;собой право отказать Пользователю
          в&nbsp;применении Сервиса без уведомления Пользователя и/или без
          объяснения причин.
        </p>
        <p>
          3.4. Пользователь вправе оформить не&nbsp;более&nbsp;3 (трех) заказов
          с&nbsp;применением любых промокодов в&nbsp;сутки.
        </p>
        <p>
          3.5. Пользователю может быть предоставлен промокод
          на&nbsp;определенный номинал для последующего его использования при
          приобретении услуг и/или товаров Сервиса. Таким промокодом можно
          воспользоваться только при on-line оплате заказа и&nbsp;только один
          раз.
        </p>
        <p>
          3.5.1&nbsp;В случае, если номинал промокода больше или равен стоимости
          заказа, то&nbsp;скидка составляет 100&nbsp;% стоимости заказа,
          уменьшенная на&nbsp;1 (один) рубль, при этом разница между стоимостью
          заказа и&nbsp;номиналом промокода Пользователю не&nbsp;выплачивается.
        </p>
        <p>
          3.5.2&nbsp;В случае, если номинал промокода меньше стоимости заказа,
          Пользователь в&nbsp;момент совершения заказа должен будет оплатить
          разницу между общей суммой заказа, совершенного с&nbsp;помощью
          Сервиса, и&nbsp;суммой номинала промокода.
        </p>
        <p>
          3.5.3 При совершении одного заказа может быть использован
          только&nbsp;1 (один) промокод. Выплата Пользователю суммы, равной
          номиналу промокода, в&nbsp;денежном эквиваленте не&nbsp;допускается.
        </p>
        <p>
          3.5.4 Промокод имеет ограниченный срок действия и&nbsp;может быть
          использован только при online оплате заказа в&nbsp;течение такого
          срока действия соответствующего промокода. При этом срок действия
          конкретного промокода/партии промокодов может быть изменен Сервисом
          в&nbsp;одностороннем порядке. Пользователь самостоятельно должен
          отслеживать условия и&nbsp;сроки действия промокодов путем обращения
          в&nbsp;контактный центр Сервиса.
        </p>
        <p>
          3.5.5 Промокод можно использовать один и&nbsp;более раз
          в&nbsp;зависимости от&nbsp;условий действия конкретного промокода,
          которые определяются Сервисом самостоятельно и&nbsp;указываются
          в&nbsp;соответствующих рекламно-информационных материалах, включая,
          но&nbsp;не&nbsp;ограничиваясь, баннеры на&nbsp;Сервисе/сайтах
          Партнеров, листовки, флаеры.
        </p>
        <p>
          3.5.6 Промокоды могут быть предоставлены Пользователю путем размещения
          их&nbsp;в&nbsp;разделе &laquo;Мои промокоды&raquo; в&nbsp;Личном
          кабинете Пользователя (если применимо), а&nbsp;также путем направления
          Пользователю смс-сообщения на&nbsp;номер мобильного телефона и/или
          электронного письма на&nbsp;адрес электронной почты, указанных
          Пользователем при Регистрации, и&nbsp;иными способами,
          предусмотренными настоящим Пользовательским соглашением, при наличии
          согласия Пользователя на&nbsp;получение от&nbsp;Сервиса рассылок
          согласно условиям настоящего Пользовательского соглашения.
        </p>
        <p>3.5.7. Количество промокодов ограничено.</p>
        <p>
          3.5.8. В&nbsp;случае выявления мошеннических действий
          Пользователя/третьих лиц, в&nbsp;том числе направленных
          на&nbsp;неосновательное обогащение с&nbsp;помощью промокодов, Сервис
          вправе в&nbsp;одностороннем порядке прекратить действие таких
          промокодов и&nbsp;заблокировать доступ таким Пользователю/третьим
          лицам на&nbsp;Сервис.
        </p>
        <h3>Регистрация Пользователя</h3>
        <p>
          4.1 Пользователь соглашается пройти процедуру регистрации
          на&nbsp;Сервисе, заполнив форму регистрации и&nbsp;выразив согласие
          с&nbsp;условиями Соглашения путем подтверждения пункта
          &laquo;Я&nbsp;принимаю условия Соглашения&raquo;.
        </p>
        <p>
          4.2. По&nbsp;завершении процесса регистрации Пользователь получает
          логин и&nbsp;пароль для доступа в&nbsp;Личный кабинет.
        </p>
        <p>
          4.3. Пользователь несет ответственность за&nbsp;безопасность своего
          логина и&nbsp;пароля, а&nbsp;также за&nbsp;все, что будет сделано
          на&nbsp;Сервисе под его логином и&nbsp;паролем.
        </p>
        <p>
          4.4. Пользователь соглашается с&nbsp;тем, что он&nbsp;обязан
          немедленно уведомить Сервис о&nbsp;любом случае неавторизованного
          (не&nbsp;разрешенного) доступа со&nbsp;своим логином и&nbsp;паролем
          и/или о&nbsp;любом нарушении безопасности.
        </p>
        <h3>Условия использования материалов</h3>
        <p>
          5.1 Сервис содержит материалы, охраняемые авторским правом, товарные
          знаки и&nbsp;иные охраняемые законом материалы, включая,
          но&nbsp;не&nbsp;ограничиваясь: тексты, фотографии, графические
          изображения.
        </p>
        <p>
          5.2 При этом все содержание Сервиса охраняется авторским правом как
          произведение, созданное коллективным творческим трудом
          в&nbsp;соответствии с&nbsp;законодательством Российской Федерации
          об&nbsp;авторском праве и&nbsp;смежных правах.
        </p>
        <p>
          5.3 Сервису принадлежит авторское право на&nbsp;использование
          содержания Сервиса (в&nbsp;том числе, право на&nbsp;подбор,
          расположение, систематизацию и&nbsp;преобразование данных,
          содержащихся на&nbsp;Сервисе, а&nbsp;также на&nbsp;сами исходные
          данные), кроме случаев, отдельно отмеченных в&nbsp;содержании
          опубликованных материалов.
        </p>
        <p>
          5.4 Пользователь не&nbsp;имеет права вносить изменения, публиковать,
          передавать третьим лицам, участвовать в&nbsp;продаже или уступке,
          создавать производные продукты или иным образом использовать, частично
          или полностью, содержание Сервиса.
        </p>
        <p>
          5.5 Пользователь Сети обязуется использовать Сервис только
          в&nbsp;законных целях.
        </p>
        <p>
          5.6 Пользователь Сети обязуется не&nbsp;размещать на&nbsp;Сервисе
          и&nbsp;не&nbsp;направлять куда-либо через/посредством Сервиса любые
          материалы следующего характера:
        </p>
        <ul>
          <li>
            нарушающие законодательство, содержащие угрозы и&nbsp;оскорбления,
            дискредитирующие других лиц, нарушающие права граждан
            на&nbsp;частную жизнь или публичный порядок, носящие характер
            непристойности;
          </li>
          <li>
            нарушающие в&nbsp;той или иной степени честь и&nbsp;достоинство,
            права и&nbsp;охраняемые законом интересы других лиц;
          </li>
          <li>
            способствующие или содержащие призывы к&nbsp;разжиганию религиозной,
            расовой или межнациональной розни, содержащие попытки разжигания
            вражды или призывы к&nbsp;насилию;
          </li>
          <li>
            а&nbsp;также иные материалы, которые побуждают других лиц
            на&nbsp;противоправное поведение, влекущее уголовную,
            гражданско-правовую и&nbsp;иную ответственность или каким-либо
            образом нарушающее положения законодательства.
          </li>
        </ul>
        <p>
          5.7 Пользователь обязуется не&nbsp;размещать на&nbsp;Сервисе
          и&nbsp;не&nbsp;направлять через/посредством Сервиса материалы,
          являющиеся рекламой каких-либо товаров или услуг, без получения
          предварительного явно выраженного согласия Сервиса.
        </p>
        <p>
          5.8 Пользователь обязуется не&nbsp;использовать Сервис для рекламы или
          иного стимулирования сбыта любых товаров и&nbsp;услуг в&nbsp;любой
          форме, включая, но&nbsp;не&nbsp;ограничиваясь, стимулирование
          пользователей к&nbsp;подписке на&nbsp;другую систему интерактивного
          обслуживания, являющуюся конкурентом Сервиса.
        </p>
        <p>
          5.9 Пользователь обязуется не&nbsp;загружать, размещать или иным
          образом использовать на&nbsp;Сервисе какие-либо материалы, охраняемые
          законодательством об&nbsp;интеллектуальной собственности (в&nbsp;том
          числе, авторским правом, законодательством о&nbsp;товарных знаках),
          и&nbsp;иные охраняемые законодательством материалы без получения
          выраженного разрешения обладателя прав на&nbsp;охраняемый материал.
          При этом бремя доказывания того, что размещение на&nbsp;Сервисе
          пользователем материалов не&nbsp;нарушает авторские, смежные
          и&nbsp;иные права третьих лиц на&nbsp;размещаемые материалы,
          а&nbsp;также ответственность незаконное размещение лежит
          на&nbsp;Пользователе.
        </p>
        <p>
          5.10 Сервис не&nbsp;несет ответственность за&nbsp;отзывы,
          опубликованные Пользователем.
        </p>
        <p>
          5.11 Сервис оставляет за&nbsp;собой право размещать комментарии
          к&nbsp;отзыву Пользователя. Сервис оставляет за&nbsp;собой право
          не&nbsp;размещать и&nbsp;удалять отзывы Пользователя, нарушающие
          российское законодательство и&nbsp;наносящие ущерб законным интересам
          третьих лиц (в&nbsp;том числе, носящие оскорбительный характер либо
          порочащие честь, достоинство и&nbsp;репутацию таких лиц).
        </p>
        <h3>Ответственность</h3>
        <p>
          6.1 Сервис не&nbsp;несет ответственности
          за&nbsp;соблюдение/несоблюдение службами доставки своих обязательств
          перед Пользователем, а&nbsp;также за&nbsp;достоверность информации,
          предоставленной такими службами.
        </p>
        <p>
          6.2. Сервис является информационным связующим звеном между
          Пользователем и&nbsp;службами доставки, при этом Сервис вправе
          принимать вопросы/претензии от&nbsp;Пользователей относительно
          доставки в&nbsp;течение&nbsp;24 (двадцать четыре) часов с&nbsp;момента
          доставки/ожидаемой доставки, при этом во&nbsp;избежание мошенничества
          Пользователь обязуется предоставить материалы
          с&nbsp;фото-/видеофиксацией повреждений заказа.
        </p>
        <h3>Права собственности Сервиса</h3>
        <p>
          7.1 Пользователь признает и&nbsp;соглашается с&nbsp;тем, что Сервис
          и&nbsp;все необходимые программы, связанные с&nbsp;ним, содержат
          конфиденциальную информацию, которая защищена законами
          об&nbsp;интеллектуальной собственности и&nbsp;прочими российскими
          и&nbsp;международными законами. Пользователь соглашается
          не&nbsp;модифицировать, не&nbsp;продавать, не&nbsp;распространять этот
          контент и&nbsp;программы, целиком либо по&nbsp;частям.
        </p>
        <h3>Конфиденциальность</h3>
        <p>
          8.1 Персональные данные Пользователя обрабатываются
          в&nbsp;соответствии с&nbsp;ФЗ&nbsp;&laquo;О&nbsp;персональных
          данных&raquo; &#8470;&nbsp;152-ФЗ и&nbsp;Положением о&nbsp;защите
          персональных данных.
        </p>
        <p>
          8.2 При регистрации/оформлении заказа на&nbsp;Сервисе Пользователь
          предоставляет следующие данные: имя, адрес электронной почты и/или
          номер контактного телефона, адрес доставки заказа.
        </p>
        <p>
          8.3&nbsp;В целях исполнения настоящего Пользовательского соглашения
          Сервис развивает, оптимизирует и&nbsp;внедряет новый функционал
          Сервиса (включая продукты информационного, рекламного,
          развлекательного и&nbsp;иного характера),
          в&nbsp;т.ч.&nbsp;с&nbsp;участием аффилированных лиц и/или партнеров.
          Для обеспечения реализации указанных целей, а&nbsp;также в&nbsp;целях
          информирования Пользователей о&nbsp;своих услугах, продвижения товаров
          и&nbsp;услуг, проведения электронных и&nbsp;sms опросов, Получения
          Пользователем персонализированной (таргетированной) рекламы, контроля
          маркетинговых акций, клиентской поддержки, организации доставки товара
          Пользователям, проведения розыгрышей призов среди Пользователей,
          контроля удовлетворенности Пользователя и&nbsp;качества услуг,
          оказываемых службами доставки, проверки, исследования и&nbsp;анализа
          таких данных Пользователь при регистрации/оформлении заказа
          соглашается и&nbsp;поручает осуществлять с&nbsp;соблюдением
          применимого законодательства обработку данных, в&nbsp;т.ч. результатов
          автоматизированной обработки таких данных в&nbsp;виде целочисленных
          и/или текстовых значений и&nbsp;идентификаторов, их&nbsp;передачу
          аффилированным лицам и/или партнерам во&nbsp;исполнение такого
          поручения на&nbsp;обработку, а&nbsp;также осуществлять сбор
          (получение) данных Пользователя и&nbsp;иных связанных
          с&nbsp;Пользователем данных от&nbsp;аффилированных лиц и/или
          партнеров.
        </p>
        <p>
          Сервис имеет право распоряжаться статистической информацией, связанной
          с&nbsp;функционированием Сервиса.
        </p>
        <p>
          8.4. Под данными, связанными с&nbsp;Пользователем, понимается
          информация о&nbsp;технических средствах (устройствах) и&nbsp;способах
          технологического взаимодействия с&nbsp;Сервисом и/или сервисами
          аффилированных лиц и/или партнеров (в&nbsp;т.&nbsp;ч. IP-адрес хоста,
          вид операционной системы, тип браузера, географическое положение,
          данные о&nbsp;провайдере и&nbsp;иное), об&nbsp;активности
          Пользователя, а&nbsp;также иные данные, получаемые указанными
          способами.
        </p>
        <p>
          8.5. Под обработкой данных понимается любое действие (операция) или
          совокупность действий (операций), совершаемых с&nbsp;использованием
          средств автоматизации или без использования таких средств
          с&nbsp;персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение),
          сопоставление, извлечение, использование, передачу аффилированным
          лицам и/или партнерам Сервиса, обезличивание, блокирование, удаление,
          уничтожение.
        </p>
        <p>
          8.6. Сервис имеет право отправлять Пользователю от&nbsp;своего имени
          самостоятельно или с&nbsp;привлечением технических партнеров
          информационные, в&nbsp;том числе сервисные и&nbsp;рекламные сообщения,
          на&nbsp;электронную почту Пользователя, мобильный телефон (смс,
          телефонные звонки) или через используемые им&nbsp;сервисы партнеров
          (социальные сети, мессенджеры и&nbsp;иные). Сервисные сообщения,
          информирующие Пользователя о&nbsp;заказе и&nbsp;этапах его обработки,
          отправляются автоматически и&nbsp;не&nbsp;могут быть отклонены
          Пользователем.
        </p>
        <p>
          Пользователь вправе отказаться от&nbsp;получения рекламных сообщений
          после создания аккаунта Пользователя на&nbsp;Сервисе путем обращения
          Пользователя в&nbsp;контакт-центр или в&nbsp;отдел поддержки клиентов
          <a href="mailto:info@edaelista.ru">info@edaelista.ru</a>
        </p>
        <p>
          Пользователь вправе осуществить отзыв согласия на&nbsp;обработку
          персональных данных путем направления соответствующего письменного
          заявления по&nbsp;адресу, указанному в&nbsp;п.&nbsp;9.5 Соглашения.
        </p>
        <p>
          8.7 Сервис вправе использовать технологию &laquo;cookies&raquo;.
          &laquo;Cookies&raquo; не&nbsp;содержат конфиденциальную информацию,
          и&nbsp;Сервис вправе передавать информацию
          о&nbsp;&laquo;cookies&raquo; Партнерам, агентам и&nbsp;третьим лицам,
          имеющим заключенные с&nbsp;Сервисом договоры, для исполнения
          обязательств перед Пользователем и&nbsp;для целей статистики
          и&nbsp;оптимизации рекламных сообщений.
        </p>
        <p>
          8.8 Сервис получает информацию об&nbsp;ip-адресе посетителя Сервиса.
          Данная информация не&nbsp;используется для установления личности
          посетителя.
        </p>
        <p>
          8.9 Сервис не&nbsp;несет ответственности за&nbsp;сведения,
          предоставленные Пользователем на&nbsp;Сервисе в&nbsp;общедоступной
          форме.
        </p>
        <p>
          8.10 Сервис вправе осуществлять записи телефонных разговоров
          с&nbsp;Пользователем с&nbsp;целью повышения качества предоставляемых
          услуг. При этом Сервис обязуется предотвращать попытки
          несанкционированного доступа к&nbsp;информации, полученной в&nbsp;ходе
          телефонных переговоров, и/или передачу ее&nbsp;третьим лицам,
          не&nbsp;имеющим непосредственного отношения к&nbsp;исполнению заказов
          в&nbsp;соответствие
          с&nbsp;п.&nbsp;4&nbsp;ст.&nbsp;16&nbsp;Федерального закона
          &laquo;Об&nbsp;информации, информационных технологиях и&nbsp;защите
          информации&raquo;.
        </p>
        <h3>Общие положения</h3>
        <p>
          9.1 Настоящее Соглашение регулируется нормами действующего
          законодательства&nbsp;РФ.
        </p>
        <p>
          9.2 Все возможные споры по&nbsp;поводу Соглашения разрешаются согласно
          нормам действующего законодательства&nbsp;РФ.
        </p>
        <p>
          9.3 Ввиду безвозмездности условий настоящего соглашения нормы
          о&nbsp;защите прав потребителей не&nbsp;могут быть применимы
          к&nbsp;отношениям между Пользователем и&nbsp;Сервисом.
        </p>
        <p>
          9.4 Ничто в&nbsp;Соглашении не&nbsp;может пониматься как установление
          между Пользователем и&nbsp;Сервисом агентских отношений, отношений
          товарищества, отношений по&nbsp;совместной деятельности, отношений
          личного найма, либо каких-то иных отношений, прямо
          не&nbsp;предусмотренных настоящим Соглашением.
        </p>
        <p>
          9.5. По&nbsp;вопросам, связанным с&nbsp;исполнением Соглашения,
          необходимо обращаться по&nbsp;адресу местонахождения Сервиса:
          Российская Федерация, Республика Калмыкия, г.Элиста, Северо-западная
          8-ая, д.11
        </p>
      </Content>
    </Layout>
  )
}

export default Oferta

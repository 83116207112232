import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { Redirect } from '@reach/router'

import Layout from './shared/Layout'
import Header from './Profile/Header'
import Input from './shared/Input'

// import {
//   $customerName,
//   $bonuses,
//   $bonusLevel,
// } from '../store'
// import { changeCustomerName } from '../store/order'
import { $customerPhone, updateBonusesFx } from '../store/auth'
// import Button from './shared/Button'
// import ModalOverlay from './shared/ModalOverlay'
// import InfoModal from './shared/InfoModal'
// import TextArea from './shared/TextArea'
// import {
//   $withdrawalModal,
//   $withdrawalText,
//   $withdrawalError,
//   withdrawalModalSet,
//   withdrawalSubmitted,
//   withdrawalTextSet,
//   applyWithdrawalFx,
//   $withdrawalConfirmedModal,
//   withdrawalConfirmed,
//   wthdrwlConfirmClosed,
// } from '../store/withdrawal'
// import DialogModal from './shared/DialogModal'

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

// const Space = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-gap: 1rem;
//   margin-top: 1rem;
// `

// const Indicator = styled.span`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: space-between;
//   padding: 1rem;
//   background-color: var(--main-background-color);
//   border: 1px solid var(--border-color);
//   border-radius: 0.5rem;
//   box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.15);

//   strong {
//     color: var(--main-accent-color);
//   }
// `

// const Text = styled.p`
//   margin: 0;
//   color: var(--secondary-text-color);
//   font-size: ${({ small }) => (small ? '0.8em' : '')};
//   line-height: 1.5em;
// `

// const List = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
//   color: var(--secondary-text-color);

//   &:not(:last-child) {
//     margin-bottom: 1rem;
//   }
// `

function Profile() {
  const customerPhone = useStore($customerPhone)
  // const bonuses = useStore($bonuses)
  // const bonusLevel = useStore($bonusLevel)

  // const modal = useStore($withdrawalModal)
  // const text = useStore($withdrawalText)
  // const pending = useStore(applyWithdrawalFx.pending)
  // const error = useStore($withdrawalError)
  // const confirmed = useStore($withdrawalConfirmedModal)

  useEffect(() => {
    updateBonusesFx()
  }, [])

  return customerPhone ? (
    <Layout>
      <Header />
      <Grid>
        <Input
          label="Номер телефона"
          initialValue={customerPhone}
          type="text"
          disabled
        />
        {/* <Space>
          <Indicator>
            <span>Бонусы:</span> <strong>{bonuses}</strong>
          </Indicator>
          <Indicator>
            <span>Кэшбек:</span> <strong>{bonusLevel.percent}%</strong>
          </Indicator>
        </Space>

        <Text>
          Бонусная система временно отключена. Минимальная сумма вывода кешбека
          от 100&nbsp;рублей.
        </Text>

        <Button
          disabled={bonuses < 100}
          onClick={() => withdrawalModalSet(true)}
        >
          Вывести бонусы
        </Button>

        <InfoModal
          title="Вывод бонусов"
          isShown={modal}
          onCloseClick={() => withdrawalModalSet(false)}
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              withdrawalSubmitted()
            }}
          >
            <div style={{ display: 'grid', rowGap: '1em', padding: '1em' }}>
              <Text small>
                Укажите номер телефона или банковской карты для перевода. Вы
                также можете оставить комментарий, например, указать ФИО
                получателя.
              </Text>
              <TextArea
                placeholder="Введите данные здесь"
                value={text}
                onChange={withdrawalTextSet}
                disabled={pending}
              />
              {error && (
                <Text small style={{ color: 'red' }}>
                  {error}
                </Text>
              )}
              <Button type="submit" disabled={pending}>
                Подтвердить
              </Button>
            </div>
          </form>
        </InfoModal>

        <InfoModal
          isShown={confirmed}
          title="Заявка принята"
          onCloseClick={() => wthdrwlConfirmClosed()}
        >
          <div style={{ display: 'grid', rowGap: '1em', padding: '1em' }}>
            <Text small>
              Запрос будет обработан в течение 15 дней. По всем вопросам
              обращайтесь по телефону +7&nbsp;(937)&nbsp;464&nbsp;60&nbsp;07
            </Text>
            <Button>Закрыть</Button>
          </div>
        </InfoModal> */}

        {/* <Text>1 Балл = 1 Рубль</Text>
        <Text>
          С&nbsp;каждого заказа вам будет начислены баллы в&nbsp;зависимости
          от&nbsp;уровня кешбека и&nbsp;дополнительного кешбека
          от&nbsp;ресторана. Баллы начисляются автоматически каждый день
          в&nbsp;12:00
        </Text>
        <Text>
          Кешбек зависит от&nbsp;количества заказов за&nbsp;последний месяц.
        </Text>
        <List>
          <li>2% - 0 заказов</li>
          <li>3% - 1 заказ</li>
          <li>4% - 2 заказа</li>
          <li>5% - 3 и более заказов</li>
        </List>
        <Text>
          Воспользоваться кешбеком можно в&nbsp;любом ресторане только при
          оплате картой онлайн.
        </Text> */}
      </Grid>
    </Layout>
  ) : (
    <Redirect to="/" noThrow />
  )
}

export default Profile

import React, { useEffect, useState } from 'react'
import ReactStories from 'react-insta-stories'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import { $stories, loadStoriesFx } from '../../store/stories'
import ModalOverlay from '../shared/ModalOverlay'
import Portal from '../shared/Portal'

const Container = styled.div`
  max-width: 100%;
  margin: 5px 0 -5px;
  overflow: hidden;
`

const Wrapper = styled.div`
  position: relative;
  top: 15px;
  margin-top: -15px;
  padding-bottom: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`

const List = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  column-gap: 12px;
  width: max-content;
  padding: 16px;
  padding-bottom: 10px;
`

const StoryButton = styled.div`
  display: flex;
  align-items: flex-end;
  width: 96px;
  height: 96px;
  padding: 10px;
  color: #ffffff;
  font-size: 12px;
  background-image: url(${({ preview }) => preview});
  background-size: contain;
  background-color: black;
  border-radius: 12px;
  box-shadow: ${({ seen }) =>
    !seen ? '0 0 0 1px #ffffff, 0 0 0 3px var(--main-accent-color)' : ''};
  cursor: pointer;
`

const Modal = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  background: #000000;
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transform: ${({ isShown }) => (isShown ? 'scale(1)' : 'scale(0.9)')};
  transform-origin: top center;
  transition: transform 0.2s, opacity 0.4s;
  pointer-events: ${({ isShown }) => !isShown && 'none'};
`

const Stories = () => {
  const [modal, setModal] = useState(false)
  const [currentStoryId, setCurrentStoryId] = useState(0)
  const stories = useStore($stories)
  const [currentStories, setCurrentStories] = useState(null)

  useEffect(() => {
    if (!stories) {
      loadStoriesFx()
    }
  }, [stories])

  useEffect(() => {
    const html = document.querySelector('html')
    if (modal) {
      html.style.marginTop = 0
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      html.style.marginTop = ''
      document.querySelector('body').style.overflow = ''
    }
  }, [modal])

  function handleStart({ stories, id }) {
    setCurrentStoryId(id)
    setCurrentStories(stories)
    setModal(true)
  }

  function handleClose() {
    setModal(false)
    setCurrentStories(null)
  }

  return (
    <Container>
      <Wrapper>
        <List>
          {stories &&
            stories
              .filter(({ status }) => status === 'on')
              .sort((a, b) => a.order - b.order)
              .map(group => {
                const ls = localStorage.getItem(`story-${group.id}-seen`)
                if (ls) {
                  return { ...group, seen: ls }
                }
                return group
              })
              .map(({ id, preview_url, img_urls, seen }) => {
                return (
                  <StoryButton
                    key={`story-${id}`}
                    preview={preview_url}
                    seen={seen}
                    onClick={() => handleStart({ stories: img_urls, id })}
                  />
                )
              })}
        </List>
      </Wrapper>
      <Portal>
        <ModalOverlay isShown={modal} onClick={handleClose}>
          <Modal
            isShown={modal}
            style={{ maxHeight: '100vh', overflow: 'hidden' }}
          >
            {currentStories && (
              <ReactStories
                stories={currentStories}
                defaultInterval={3000}
                width="100%"
                height="100vh"
                currentIndex={0}
                storyStyles={{ display: 'flex' }}
                onAllStoriesEnd={() => {
                  localStorage.setItem(`story-${currentStoryId}-seen`, true)
                  handleClose()
                }}
              />
            )}
          </Modal>
        </ModalOverlay>
      </Portal>
    </Container>
  )
}

export default Stories

import {
  createEffect,
  createEvent,
  createStore,
  guard,
  forward,
} from 'effector'

export const appInited = createEvent('app inited')
export const $cart = createStore([])
export const $orderDeferredTo = createStore('')
export const $orderPaymentType = createStore('')
export const $orderChange = createStore('')
export const $orderPersons = createStore(0)

export const $basketData = $cart.map(cart =>
  cart.map(({ id, count, options }) => ({
    id,
    qty: count,
    options: options.map(({ id }) => id),
  })),
)

export const $token = createStore(null)
export const $customerPhone = createStore('')
export const $customerName = createStore('')

export const $currentRestaurantId = createStore(null)
export const $restaurantItems = createStore([])
export const $selectedItem = createStore(null)
export const $numberToCart = createStore(1)
export const $selectedOptions = createStore([])

export const $restaurantCategories = createStore([])

export const $selectedRestaurantId = createStore(null)
export const $restaurants = createStore([])
export const $allPaymentTypes = createStore([])

export const $selectedOrder = createStore(null)
export const $feedbackFormScore = createStore(null)
export const $feedbackFormComment = createStore('')
export const $feedbackFormAnon = createStore(false)

export const $isOrderPending = createStore(false)
export const $isOrderFailed = createStore('')

export const takeawayModeToggled = createEvent('takeaway mode toggled')
export const takeawayModeResetted = createEvent('takeaway mode resetted')
export const takeawayModeSettedFromLocalStorage = createEvent(
  'takeaway mode setted from local storage',
)
export const takeawayModeKey = 'takeaway_mode'
export const $takeawayMode = createStore(false).reset(takeawayModeResetted)

export const takeawayAddressSetted = createEvent('takeaway address setted')
export const $takeawayAddress = createStore('')
export const $takeawayPriceReduction = createStore(0)

export const $bonuses = createStore(0)
export const $bonusLevel = createStore({})

export function createCountdown(
  name,
  { start, abort = createEvent(`${name}Reset`), timeout = 1000 },
) {
  const $working = createStore(true, { name: `${name}Working` })
  const tick = createEvent(`${name}Tick`)
  const timerFx = createEffect(`${name}Timer`).use(() => wait(timeout))

  $working.on(abort, () => false).on(start, () => true)

  guard({
    source: start,
    filter: timerFx.pending.map(is => !is),
    target: tick,
  })

  forward({
    from: tick,
    to: timerFx,
  })

  const willTick = guard({
    source: timerFx.done.map(({ params }) => params - 1),
    filter: seconds => seconds >= 0,
  })

  guard({
    source: willTick,
    filter: $working,
    target: tick,
  })

  return { tick }
}

function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

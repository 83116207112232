import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import { useForm } from 'react-hook-form'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Portal from './Portal'
import ModalOverlay from './ModalOverlay'
import Counter from './Counter'
import Button from './Button'
import CrossIcon from './CrossIcon'
import DialogModal from './DialogModal'
import OptGroup from './ItemModal/OptGroup'
import Option from './ItemModal/Option'

import { $cart, $selectedItem, $numberToCart } from '../../store'
import {
  $visiblePopup,
  selectItem,
  addToCart,
  incNumber,
  decNumber,
  removeFromCart,
  $decreaseDisabled,
  $increaseDisabled,
  closeItemModal,
  $cartShop,
  $itemPrice,
  selectOption,
} from '../../store/cart'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 56px);
  margin-top: auto;
  background: var(--alt-background-color);
  border-radius: 20px 20px 0 0;
  box-shadow: 0 0 20px var(--shadow-color);
  transform: translateY(${({ isShown }) => (isShown ? '0' : '120%')});
  opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  transition: transform 0.3s, opacity 0.2s;
`

const CloseButton = styled.button`
  position: absolute;
  top: ${({ isCovered }) => (isCovered ? '-48px' : '10px')};
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--main-background-color);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 5px var(--shadow-color);
  cursor: pointer;
  appearance: none;

  svg {
    fill: currentColor;
  }
`

const ModalWrapper = styled.div`
  height: 100%;
  overflow: auto;
  border-radius: 20px 20px 0 0;
`

const Cover = styled.div`
  height: 280px;
  background-image: url("${({ image }) => image}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px 20px 0 0;
`

const Content = styled.div`
  height: auto;
  background: var(--alt-background-color);
`

const Description = styled.p`
  margin: 0;
  padding: 1rem;
`

const Footer = styled.footer`
  flex-shrink: 0;
  background-color: var(--main-background-color);
  border-top: 1px solid var(--border-color);
`

const Title = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1rem;

  span:last-child {
    margin-left: 1rem;
    font-weight: 700;
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem 1.5rem;
`

const SelectedOptions = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 0 1rem 1.5rem;
  color: var(--secondary-text-color);
  font-size: 0.9rem;
`

function ItemModal({ inCart = false }) {
  const decreaseDisabled = useStore($decreaseDisabled)
  const increaseDisabled = useStore($increaseDisabled)
  const numberToCart = useStore($numberToCart)
  const visiblePopup = useStore($visiblePopup)
  const selectedItem = useStore($selectedItem)
  const itemPrice = useStore($itemPrice)
  const cart = useStore($cart)
  const cartShop = useStore($cartShop)
  const targetRef = useRef()
  let [targetElement, setTargetElement] = useState(null)
  let [warningDialog, setWarningDialog] = useState(false)

  const { handleSubmit, register, errors, watch, reset } = useForm()

  const onSubmit = () => {
    onAddClick()
  }

  const hasOptions =
    selectedItem && selectedItem.groups && selectedItem.groups.length > 0

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (visiblePopup) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [visiblePopup, selectedItem, targetElement])

  function onCloseClick() {
    closeItemModal()
    setTimeout(() => {
      reset()
      selectItem(null)
    }, 300)
  }

  function addItem() {
    closeItemModal()
    setTimeout(() => addToCart(), 200)
  }

  function onAddClick() {
    if (selectedItem && numberToCart === 0) {
      removeFromCart(selectedItem.customId)
    } else if (
      selectedItem &&
      cart.length > 0 &&
      selectedItem.shop_id !== cart[0].shop_id
    ) {
      setWarningDialog(true)
    } else {
      addItem()
    }
  }

  return (
    <>
      <Portal>
        <ModalOverlay isShown={visiblePopup} onClick={onCloseClick}>
          <Container isShown={visiblePopup}>
            <CloseButton
              type="button"
              onClick={onCloseClick}
              isCovered={selectedItem && !selectedItem.image}
            >
              <CrossIcon />
            </CloseButton>
            <ModalWrapper ref={targetRef}>
              {selectedItem && selectedItem.image && (
                <Cover image={selectedItem.image} />
              )}
              <Content>
                {selectedItem && selectedItem.description && (
                  <Description>{selectedItem.description}</Description>
                )}
                <form id="item-form" onSubmit={handleSubmit(onSubmit)}>
                  {!inCart &&
                    hasOptions &&
                    selectedItem.groups.map(group => {
                      const {
                        id: groupId,
                        title,
                        type: groupType,
                        options,
                      } = group
                      return (
                        <OptGroup
                          title={`${title}`}
                          key={`opt-group-${groupId}`}
                        >
                          {options.map(option => {
                            const { id, title, price } = option
                            const inputName =
                              groupType === 'radio'
                                ? `group_${groupId}`
                                : `option_${id}`
                            return (
                              <Option
                                key={`option-${id}`}
                                type={groupType}
                                name={inputName}
                                label={`${title}`}
                                price={Math.round(
                                  price - (price / 100) * selectedItem.discount,
                                )}
                                customValidity={errors[inputName]}
                                value={groupType !== 'checkbox' ? id : ''}
                                onChange={() => selectOption(watch())}
                                forwardRef={register({
                                  required: groupType === 'radio',
                                })}
                              />
                            )
                          })}
                        </OptGroup>
                      )
                    })}
                  {inCart &&
                    selectedItem &&
                    selectedItem.options &&
                    selectedItem.options.length > 0 && (
                      <SelectedOptions>
                        {selectedItem.options.map(({ title }) => (
                          <span>{title}</span>
                        ))}
                      </SelectedOptions>
                    )}
                </form>
              </Content>
            </ModalWrapper>
            <Footer>
              <Title>
                <span>{selectedItem && selectedItem.title}</span>
                <span>
                  {selectedItem && selectedItem.fullPrice
                    ? selectedItem.fullPrice
                    : itemPrice}
                  &nbsp;₽
                </span>
              </Title>
              <Controls>
                <Counter
                  onIncrease={incNumber}
                  onDecrease={decNumber}
                  decDisabled={
                    !inCart && hasOptions
                      ? numberToCart === 1
                      : decreaseDisabled
                  }
                  incDisabled={increaseDisabled}
                  number={numberToCart}
                />
                <Button
                  form="item-form"
                  type="submit"
                  danger={numberToCart === 0}
                >
                  {!inCart && hasOptions
                    ? 'Добавить'
                    : selectedItem && !selectedItem.count
                    ? 'Добавить'
                    : numberToCart === 0 || !selectedItem
                    ? 'Удалить'
                    : 'Изменить'}
                </Button>
              </Controls>
            </Footer>
          </Container>
        </ModalOverlay>
      </Portal>

      <DialogModal
        isShown={warningDialog}
        okText="Продолжить"
        onOk={addItem}
        onCancel={() => setWarningDialog(false)}
        dialog={`Все ранее добавленные блюда из ресторана ${
          cartShop && cartShop.title
        } будут удалены из корзины`}
      />
    </>
  )
}

export default ItemModal

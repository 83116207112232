import React from 'react'

function TimeIcon({ width = 12, height = 12 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.25A5.256 5.256 0 01.75 6 5.256 5.256 0 016 .75 5.256 5.256 0 0111.25 6 5.256 5.256 0 016 11.25z" />
      <path d="M6.375 2.25h-.75v3.905l2.36 2.36.53-.53-2.14-2.14V2.25z" />
    </svg>
  )
}

export default TimeIcon

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: '. fee' 'note note';
  grid-column-gap: 1rem;
  padding: 1.5rem 1rem;
  background-color: var(--main-background-color);
  border-bottom: 1px solid var(--border-color);

  span {
    display: block;
  }
`

const Note = styled.span`
  grid-area: note;
  font-size: 0.75rem;
`

const Fee = styled.span`
  grid-area: fee;
  color: var(--secondary-text-color);
`

function DeliveryFee({
  totalPrice,
  deliveryFee,
  nextDeliveryFee: { value, fee },
}) {
  return (
    <Container>
      <span>Доставка ресторана</span>
      <Fee>{`${deliveryFee}`}&nbsp;₽</Fee>
      {value && (
        <Note>
          Закажите ещё на {value - totalPrice}&nbsp;₽ для доставки за {fee}
          &nbsp;₽
        </Note>
      )}
    </Container>
  )
}

export default DeliveryFee

import React from 'react'

function HistoryIcon({ width = 30, height = 30 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M24.8 6.342a13.756 13.756 0 00-8.823-4.025L17.662.632 17.03 0l-2.82 2.82 2.82 2.82.632-.633-1.8-1.8c6.748.445 12.102 6.076 12.102 12.935 0 7.148-5.816 12.964-12.964 12.964-7.149 0-12.964-5.816-12.964-12.964 0-.288.01-.579.028-.864l-.892-.06c-.02.306-.03.617-.03.924 0 3.701 1.441 7.181 4.059 9.799A13.768 13.768 0 0015 30c3.702 0 7.182-1.442 9.8-4.06a13.768 13.768 0 004.058-9.798c0-3.702-1.441-7.182-4.059-9.8zM3.561 8.316c-.173.253-.34.515-.496.779l.77.455c.145-.247.301-.492.464-.729l-.738-.505zM4.704 6.865a13.98 13.98 0 00-.596.706l.703.554c.177-.226.365-.448.557-.661l-.664-.599z" />
      <path d="M1.415 13.388c-.06.3-.112.607-.152.912l.886.117c.038-.284.086-.571.143-.852l-.877-.177zM6.028 5.58c-.234.198-.463.407-.684.62l.624.642c.205-.2.42-.396.639-.582l-.58-.68zM2.621 9.905c-.138.274-.27.556-.389.838l.824.349c.111-.264.234-.528.363-.784l-.798-.403zM1.9 11.606c-.1.29-.191.587-.272.883l.863.235c.075-.277.161-.555.255-.826l-.845-.292zM15.062 6.306c-5.405 0-9.803 4.398-9.803 9.804 0 5.406 4.398 9.804 9.803 9.804 5.406 0 9.804-4.398 9.804-9.804 0-5.405-4.398-9.804-9.804-9.804zm0 18.714c-4.912 0-8.91-3.997-8.91-8.91 0-4.912 3.998-8.91 8.91-8.91 4.913 0 8.91 3.997 8.91 8.91s-3.997 8.91-8.91 8.91z" />
      <path d="M15.447 8.244h-.894v.894h.894v-.894zM15.447 23.026h-.894v.894h.894v-.894zM8.056 15.635h-.894v.894h.894v-.894zM22.838 15.635h-.894v.894h.894v-.894zM15.447 15.748v-4.941h-.894v5.311l2.783 2.784.633-.633-2.522-2.521z" />
    </svg>
  )
}

export default HistoryIcon

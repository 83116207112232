import Axios from 'axios'
import { sample } from 'effector'
import {
  $withdrawalModal,
  $withdrawalText,
  $withdrawalError,
  applyWithdrawalFx,
  withdrawalErrorSet,
  withdrawalModalSet,
  withdrawalSubmitted,
  withdrawalTextSet,
  $withdrawalConfirmedModal,
  withdrawalConfirmed,
  wthdrwlConfirmClosed,
} from '.'
import { $token } from '..'

applyWithdrawalFx.use(async text => {
  const { data } = await Axios.post(
    '/api/customer/withdraw',
    { text },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )

  return data
})

$withdrawalModal.on(withdrawalModalSet, (_, value) => value)
$withdrawalText.on(withdrawalTextSet, (_, value) => value)
$withdrawalError.on(withdrawalErrorSet, (_, value) => value)
$withdrawalConfirmedModal
  .on(withdrawalConfirmed, () => true)
  .on(wthdrwlConfirmClosed, () => false)

sample({
  source: $withdrawalText,
  clock: withdrawalSubmitted,
  fn: text => {
    if (text.length > 0) {
      applyWithdrawalFx(text)
    } else {
      withdrawalErrorSet('Поле ввода данных не может быть пустым!')
    }
  },
})

applyWithdrawalFx.done.watch(({ result }) => {
  if (result.code === 'ok') {
    withdrawalModalSet(false)
    withdrawalTextSet('')
    withdrawalErrorSet('')
    withdrawalConfirmed()
  }

  if (result.code === 'error') {
    withdrawalErrorSet(result.data)
  }
})

import { createEffect } from 'effector'
import axios from 'axios'

import { $token } from './index'

export const restCallFx = createEffect({
  handler: async source => {
    try {
      const res = await axios.post(
        '/api/customer/restcall',
        { ...source },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${$token.getState()}`,
          },
        },
      )
      return res
    } catch (e) {
      console.error(e)
    }
  },
})

import React from 'react'
import styled from 'styled-components'

import { textOverflow } from '../../shared/styleMixins'

const StyledButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  max-width: 100%;
  min-height: 2.125rem;
  padding: 0.625rem 1.25rem;
  color: var(--inverted-text-color);
  font: inherit;
  font-size: 0.875rem;
  line-height: 100%;
  background: var(--main-accent-color);
  border: none;
  border-radius: 2rem;
  box-shadow: 0 0 4px rgba(32, 190, 67, 0.5);
  cursor: pointer;

  ${textOverflow}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1.25rem;
    height: 100%;
    background: linear-gradient(
      to right,
      var(--main-accent-color) 40%,
      rgba(32, 190, 67, 0) 100%
    );
  }
`

const TextBox = styled.div`
  margin: 0 auto;

  & > span {
    min-width: 100%;
    text-align: center;
  }
`

function AddressButton({ text = 'Укажите адрес доставки', onClick }) {
  return (
    <StyledButton type="button" onClick={onClick}>
      <TextBox>
        <span>{text}</span>
      </TextBox>
    </StyledButton>
  )
}

export default AddressButton

import React from 'react'
import styled from 'styled-components'

import RatingRadioButton from './RatingPicker/RatingRadioButton'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 32px);
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;
`

function RatingPicker({ onChange, currentValue }) {
  return (
    <Container>
      <RatingRadioButton
        onChange={onChange}
        currentValue={currentValue}
        value={1}
      />
      <RatingRadioButton
        onChange={onChange}
        currentValue={currentValue}
        value={2}
      />
      <RatingRadioButton
        onChange={onChange}
        currentValue={currentValue}
        value={3}
      />
      <RatingRadioButton
        onChange={onChange}
        currentValue={currentValue}
        value={4}
      />
      <RatingRadioButton
        onChange={onChange}
        currentValue={currentValue}
        value={5}
      />
    </Container>
  )
}

export default RatingPicker

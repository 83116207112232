import styled from 'styled-components'

const Divider = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
`

export default Divider

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'amount title price' '. options .';
  grid-column-gap: 1rem;
  padding: 1.5rem 1rem;
  background-color: var(--main-background-color);
  border-bottom: 1px solid var(--border-color);
  cursor: ${({ onClick }) => onClick && 'pointer'};
`

const Amount = styled.span`
  grid-area: amount;
`
const Title = styled.span`
  grid-area: title;
`
const Price = styled.span`
  grid-area: price;
  color: var(--secondary-text-color);
`
const Options = styled.p`
  grid-area: options;
  display: grid;
  grid-row-gap: 0.25rem;
  margin: 0;
  margin-top: 0.5rem;

  span {
    color: var(--secondary-text-color);
    font-size: 0.8rem;
  }
`

function CartItem({ item, onClick }) {
  const { title, count, fullPrice, options } = item
  return (
    <Container onClick={onClick}>
      <Amount>{count}&nbsp;&times;</Amount>
      <Title>{title}</Title>
      {options && options.length > 0 && (
        <Options>
          {options.map(({ id, title }) => (
            <span key={`${item.customId}-${id}`}>{title}</span>
          ))}
        </Options>
      )}
      <Price>{fullPrice * count}&nbsp;₽</Price>
    </Container>
  )
}

export default CartItem

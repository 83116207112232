import React from 'react'
import styled from 'styled-components'

import HeaderButton from '../../shared/HeaderButton'
import BurgerIcon from './MenuButton/BurgerIcon'
import { badgeElement } from '../../shared/styleMixins'

const StyledHeaderButton = styled(HeaderButton)`
  position: relative;
  ${({ badge }) => badge && badgeElement}
`

function MenuButton({ badge, onClick }) {
  return (
    <StyledHeaderButton badge={badge} type="button" onClick={onClick}>
      <BurgerIcon />
    </StyledHeaderButton>
  )
}

export default MenuButton

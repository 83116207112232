import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'

import { $takeawayMode, takeawayModeToggled } from '../../store'

const Container = styled.div`
  max-width: 100%;
  margin: 5px 0 -5px;
  overflow: hidden;
  text-align: center;
`

const Nav = styled.nav`
  position: relative;
  top: 15px;
  margin-top: -15px;
  padding-bottom: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
`

const List = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  justify-content: start;
  width: max-content;
  margin: 0;
  padding: 0.5rem 1rem;
  list-style: none;
`

const FilterLink = styled.button`
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0.5rem 1rem;
  color: ${({ isCurrent }) =>
    isCurrent ? 'var(--inverted-text-color)' : 'var(--text-color)'};
  font-size: 1rem;
  text-decoration: none;
  background-color: ${({ isCurrent }) =>
    isCurrent ? 'var(--main-accent-color)' : 'var(--main-background-color)'};
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 0 10px var(--shadow-color);
  transition: background 0.2s, color 0.2s;
  cursor: pointer;
`

function Filters({
  restaurantCategories,
  category,
  onChange = category => category,
}) {
  const takeawayMode = useStore($takeawayMode)
  const [value, setValue] = useState(category)

  useEffect(() => {
    onChange(value)
  }, [value, onChange])

  function handleChange(newValue) {
    if (category === newValue) {
      setValue('')
    } else {
      setValue(newValue)
    }
  }

  return (
    <Container>
      <Nav>
        <List>
          <li>
            <FilterLink
              type="button"
              isCurrent={takeawayMode}
              onClick={takeawayModeToggled}
            >
              Самовывоз
            </FilterLink>
          </li>
          {restaurantCategories.map(({ id, title, translit }) => (
            <li key={`filter-item-${id}`}>
              <FilterLink
                isCurrent={category === translit}
                onClick={() => handleChange(translit)}
              >
                {title}
              </FilterLink>
            </li>
          ))}
        </List>
      </Nav>
    </Container>
  )
}

export default Filters

import React from 'react'

function AddressIcon({ width = 30, height = 30 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M20.59 5.58A9.489 9.489 0 0015 3.777c-5.27 0-9.558 4.288-9.558 9.559 0 5.27 4.287 9.558 9.558 9.558 5.27 0 9.558-4.288 9.558-9.558a9.534 9.534 0 00-2.492-6.438.442.442 0 00-.654.596 8.652 8.652 0 012.262 5.842c0 4.783-3.89 8.674-8.674 8.674-4.783 0-8.674-3.891-8.674-8.674 0-4.783 3.891-8.675 8.674-8.675a8.61 8.61 0 015.072 1.637.442.442 0 10.518-.716z" />
      <path d="M24.429 3.906A13.247 13.247 0 0015 0a13.26 13.26 0 00-9.394 3.855 13.254 13.254 0 00-3.94 9.358 13.272 13.272 0 002.191 7.448.442.442 0 10.739-.487 12.391 12.391 0 01-2.046-6.953C2.61 6.42 8.196.884 15 .884c6.865 0 12.45 5.585 12.45 12.45 0 5.599-3.767 10.538-9.161 12.011a.441.441 0 00-.22.14l-3.04 3.58-3.02-3.559a.441.441 0 00-.224-.14 12.413 12.413 0 01-6.07-3.738.442.442 0 00-.659.59 13.296 13.296 0 006.367 3.965l3.002 3.537a.791.791 0 001.209 0l3.02-3.56c5.707-1.622 9.68-6.876 9.68-12.825 0-3.562-1.386-6.91-3.905-9.43z" />
      <path d="M19.41 14.379a.442.442 0 00.442-.442v-.683a.973.973 0 00.355.028.972.972 0 00.659-.357l.437-.54a.98.98 0 00-.143-1.376L15.835 6.69a1.332 1.332 0 00-1.67 0L8.84 11.01a.98.98 0 00-.144 1.376l.438.54a.98.98 0 001.013.328v3.583h-.133a.442.442 0 100 .884h9.972a.442.442 0 000-.884h-.135v-1.015a.442.442 0 10-.884 0v1.015h-2.016v-.361A1.955 1.955 0 0015 14.521a1.955 1.955 0 00-1.952 1.952v.362h-2.016v-4.19L15 9.428l3.968 3.218v1.29c0 .245.198.443.442.443zm-5.478 2.096a1.07 1.07 0 012.136 0v.361h-2.136v-.361zm.79-7.96l-4.413 3.58-.355.287a.094.094 0 01-.133-.014l-.437-.54a.093.093 0 01-.021-.069.093.093 0 01.034-.064l5.325-4.318a.442.442 0 01.557 0l5.324 4.318c.025.02.032.046.034.064a.093.093 0 01-.02.07l-.438.539a.093.093 0 01-.064.034.093.093 0 01-.07-.02l-4.767-3.867a.442.442 0 00-.556 0z" />
      <path d="M13.49 11.935a.442.442 0 100 .884h3.02a.442.442 0 100-.884h-3.02z" />
    </svg>
  )
}

export default AddressIcon

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin: 1rem 0;
  padding: 0 1rem;
`

const Title = styled.span`
  color: var(--secondary-text-color);
  font-size: 0.8rem;
  text-transform: uppercase;
`

const Grid = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
`

function OptGroup({ title, children }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Grid>{children}</Grid>
    </Container>
  )
}

export default OptGroup

import React from 'react'

function LoginIcon({ width = 30, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.8912 15.9782H0.978261C0.438259 15.9782 0 15.54 0 15C0 14.46 0.438259 14.0217 0.978261 14.0217H19.8912C20.4312 14.0217 20.8695 14.46 20.8695 15C20.8695 15.54 20.4312 15.9782 19.8912 15.9782Z" />
      <path d="M14.6739 21.1956C14.4233 21.1956 14.1731 21.1006 13.9825 20.9088C13.6003 20.5266 13.6003 19.9071 13.9825 19.5247L18.5086 14.9988L13.9825 10.4727C13.6003 10.0906 13.6003 9.4708 13.9825 9.08866C14.3648 8.70653 14.9844 8.70653 15.3665 9.08866L20.5838 14.3062C20.9659 14.6884 20.9659 15.3079 20.5838 15.69L15.3665 20.9073C15.1747 21.1006 14.9244 21.1956 14.6739 21.1956V21.1956Z" />
      <path d="M15.6521 29.3479C9.7159 29.3479 4.47518 25.7766 2.29822 20.2486C2.09999 19.7478 2.34766 19.1791 2.84992 18.9809C3.35076 18.7853 3.92085 19.0291 4.11908 19.534C5.9987 24.3066 10.526 27.3914 15.6521 27.3914C22.4841 27.3914 28.0435 21.8321 28.0435 15C28.0435 8.16794 22.4841 2.60862 15.6521 2.60862C10.526 2.60862 5.9987 5.69339 4.11908 10.466C3.91942 10.9709 3.35076 11.2147 2.84992 11.0191C2.34766 10.8209 2.09999 10.2522 2.29822 9.75141C4.47518 4.22337 9.7159 0.6521 15.6521 0.6521C23.563 0.6521 30 7.08913 30 15C30 22.9109 23.563 29.3479 15.6521 29.3479V29.3479Z" />
    </svg>
  )
}

export default LoginIcon

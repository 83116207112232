import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useList, useStore } from 'effector-react'
import { parse } from 'qs'
import '../third-party/widgetStyles.css'

import Layout from './shared/Layout'
import Header from './OrdersHistory/Header'
import OrderCard from './OrdersHistory/OrderCard'
import RatingModal from './shared/RatingModal'
import { loadOrdersEffect, $enreachedOrders } from '../store/orders'
import { selectOrder } from '../store/feedback'
import { clearCart } from '../store/cart'

const Message = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 3rem 1rem;
  color: var(--secondary-text-color);
`

function OrdersHistory() {
  const enreachedOrders = useStore($enreachedOrders)

  const [ratingModal, setRatingModal] = useState(false)

  const INTERVAL_TIME = 10000 // 10s

  useEffect(() => {
    const urlParams = window.location.href.split('?')[1]
    const { clear_cart } = parse(urlParams)

    if (clear_cart) {
      clearCart()
    }

    loadOrdersEffect()
    let interval

    interval = setInterval(loadOrdersEffect, INTERVAL_TIME)
    //let isSubscribed = true

    window.scrollTo(0, 0)

    return () => clearInterval(interval)
    //isSubscribed && loadOrdersEffect()
    //return () => {
    //clearInterval(interval)
    //isSubscribed = false
    //}
  }, [])

  function onRatingOpen(order) {
    selectOrder(order)
    setRatingModal(true)
  }

  function onRatingClose() {
    setRatingModal(false)
    setTimeout(() => selectOrder(null), 300)
  }

  return (
    <Layout>
      <Header />
      <div>
        {enreachedOrders.length === 0 && (
          <Message>История заказов пуста</Message>
        )}
        {useList($enreachedOrders, order => {
          if (order.restaurant) {
            return (
              <OrderCard
                key={`order-${order.id}`}
                order={order}
                onFeedbackClick={() => onRatingOpen(order)}
              />
            )
          }
        })}
      </div>
      <RatingModal isShown={ratingModal} onCloseClick={onRatingClose} />
    </Layout>
  )
}

export default OrdersHistory

import React, { useEffect, useState, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useStore } from 'effector-react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Portal2 from './Portal2'
import ModalOverlay from './ModalOverlay'
import PhoneForm from './AuthModal/PhoneForm'
import CheckCodeForm from './AuthModal/CheckCodeForm'
import GoBackButton from './GoBackButton'

import { $loginStep, openLogin, closeLogin } from '../../store/auth'

const IosFix = createGlobalStyle`
  html, body {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
`

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  background: var(--main-background-color);
  opacity: ${({ isShown }) => (isShown ? '1' : '0')};
  transform: ${({ isShown }) => (isShown ? 'scale(1)' : 'scale(0.9)')};
  transform-origin: top center;
  transition: transform 0.2s, opacity 0.4s;
  pointer-events: ${({ isShown }) => !isShown && 'none'};

  header {
    display: grid;
    grid-template-columns: 72px 1fr 72px;
    align-items: center;
    height: 56px;
  }
`

const Title = styled.strong`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;

  span {
    color: var(--secondary-text-color);
    font-size: 0.9rem;
    font-weight: 400;
  }
`

const CloseButton = styled.button`
  position: relative;
  width: 72px;
  height: 56px;
  background: none;
  background-color: var(--main-background-color);
  border: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 24px;
    height: 2px;
    background-color: var(--text-color);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const Main = styled.div`
  padding-top: 60px;
`

function AuthModal({ isShown }) {
  const targetRef = useRef()
  const [targetElement, setTargetElement] = useState(null)
  const [currentPhone, setCurrentPhone] = useState('')
  const loginStep = useStore($loginStep)

  useEffect(() => {
    setTargetElement(targetRef.current)
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isShown) {
      disableBodyScroll(targetElement)
      if (targetElement) {
        // targetElement.querySelector('input').focus()
      }
    } else {
      enableBodyScroll(targetElement)
      if (targetElement) {
        // targetElement.querySelector('input').blur()
      }
    }
  }, [isShown, targetElement])

  return (
    <Portal2>
      {isShown && <IosFix />}
      <ModalOverlay isShown={isShown}>
        <Container isShown={isShown} ref={targetRef}>
          <header>
            {loginStep <= 1 ? (
              <CloseButton
                type="button"
                aria-label="Закрыть окно авторизации"
                onClick={closeLogin}
              />
            ) : (
              <GoBackButton as="button" type="button" onClick={openLogin} />
            )}
            <Title>
              Авторизация<span>EdaElista.ru</span>
            </Title>
          </header>
          <Main>
            {loginStep <= 1 ? (
              <PhoneForm setPhone={setCurrentPhone} phone={currentPhone} />
            ) : (
              <CheckCodeForm phone={currentPhone} />
            )}
          </Main>
        </Container>
      </ModalOverlay>
    </Portal2>
  )
}

export default AuthModal

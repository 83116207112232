import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import { DEV } from './constants'

const history = createBrowserHistory({
  basename: '/',
})

!DEV &&
  history.listen(location => {
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname)
  })

export default history
